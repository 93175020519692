import { useState, useEffect } from 'react';
import { Grid, Box, Paper, Stack } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import TermsOfUseDialog from '../Dialog/TermsOfUseDialog';
import PersonalDataDialog from '../Dialog/PersonalDataDialog';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<number>(0);
  const [termsOfUseOpen, setTermsOfUseOpen] = useState<boolean>(false);
  const [personalDataOpen, setPersonalDataOpen] = useState<boolean>(false);

  const userAgent = navigator.userAgent

  useEffect(() => {
    location.pathname.includes('/product')
      ? setValue(1)
      : location.pathname.includes('/diagnosis')
      ? setValue(2)
      : location.pathname.includes('/growth') || location.pathname.includes('/diary')
      ? setValue(3)
      : location.pathname.includes('/care')
      ? setValue(4)
      : setValue(0);
  }, [location.pathname]);

  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '56px',
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          textAlign: 'center',
          margin: 0,
          maxWidth: '420px',
          zIndex: 2,
        }}
      >
        <Paper elevation={16} sx={{ boxShadow: '0px -7px 24px 0px rgb(0 0 0 / 10%)' }}>
          <Grid container>
            <Grid xs={2.4} sx={{ padding: '12px 0 8px 0' }}>
              <Box
                sx={{
                  cursor: 'pointer',
                  backgroundImage: value === 0 ? 'url(/image/home_on.png)' : 'url(/image/home_off.png)',
                  backgroundSize: '18px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top',
                  paddingTop: '23px',
                  fontSize: '10px',
                  color: value === 0 ? '#2AC1BC' : '#B3B4B9',
                }}
                onClick={() => navigate('/')}
              >
                홈
              </Box>
            </Grid>
            <Grid xs={2.4} sx={{ padding: '12px 0 8px 0' }}>
              <Box
                sx={{
                  cursor: 'pointer',
                  backgroundImage: value === 1 ? 'url(/image/product_on.png)' : 'url(/image/product_off.png)',
                  backgroundSize: '18px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top',
                  paddingTop: '23px',
                  fontSize: '10px',
                  color: value === 1 ? '#2AC1BC' : '#B3B4B9',
                }}
                onClick={() => navigate('/product')}
              >
                제품소개
              </Box>
            </Grid>
            <Grid xs={2.4} sx={{ padding: '12px 0 8px 0' }}>
              <Box
                sx={{
                  cursor: 'pointer',
                  backgroundImage: value === 2 ? 'url(/image/care_on.png)' : 'url(/image/dignosis_off.png)',
                  backgroundSize: '18px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top',
                  paddingTop: '23px',
                  fontSize: '10px',
                  color: value === 2 ? '#2AC1BC' : '#B3B4B9',
                }}
                onClick={() => navigate('/diagnosis')}
              >
                진단하기
              </Box>
            </Grid>
            <Grid xs={2.4} sx={{ padding: '12px 0 8px 0' }}>
              <Box
                sx={{
                  cursor: 'pointer',
                  backgroundImage:
                    value === 3
                      ? 'url(/image/ic_growth_journal.svg)'
                      : 'url(/image/ic_growth_journal.svg_disabled.svg)',
                  backgroundSize: '18px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top',
                  paddingTop: '23px',
                  fontSize: '10px',
                  color: value === 3 ? '#2AC1BC' : '#B3B4B9',
                }}
                onClick={() => navigate('/growth')}
              >
                성장일지
              </Box>
            </Grid>
            <Grid xs={2.4} sx={{ padding: '12px 0 8px 0' }}>
              <Box
                sx={{
                  cursor: 'pointer',
                  backgroundImage: value === 4 ? 'url(/image/care_on-1.png)' : 'url(/image/care_off.png)',
                  backgroundSize: '18px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top',
                  paddingTop: '23px',
                  fontSize: '10px',
                  color: value === 4 ? '#2AC1BC' : '#B3B4B9',
                }}
                onClick={() => navigate('/care')}
              >
                관리서비스
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
        {
            (userAgent.includes("ecogram_AOS") || userAgent.includes("ecogram_IOS")) ? <></> :
                <Box sx={{ backgroundColor: '#40464E', padding: '32px 24px 24px 24px' }}>
                    <img src="/image/logo_footer.png" alt="logo footer" style={{ width: '96px' }}></img>
                    <Box
                        sx={{
                            marginTop: '13px',
                            color: '#B3B4B8',
                            fontSize: '13px',
                            lineHeight: '22px',
                        }}
                    >
                        <Box>(주) 클랫폼</Box>
                        <Box>서울시 마포구 매봉산로18, 마포비즈니스센터 605호</Box>
                        <Box>사업자등록번호 : 142-81-85883</Box>
                        <Box>대표/개인정보관리책임자 : 정대광</Box>
                        <Box>1855-3421 (10:00~17:00 점심시간 12:30~13:30)</Box>
                        <Box>Copyright©️ Clatform Co., Ltd. All rights reserved.</Box>
                        <Stack direction="row" sx={{ marginTop: '20px' }}>
                            <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/company')}>
                                회사소개
                            </Box>
                            <Box sx={{ marginX: '3px' }}>|</Box>
                            <Box sx={{ cursor: 'pointer' }} onClick={() => setTermsOfUseOpen(true)}>
                                이용 약관
                            </Box>
                            <Box sx={{ marginX: '3px' }}>|</Box>
                            <Box sx={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => setPersonalDataOpen(true)}>
                                개인정보처리방침
                            </Box>
                        </Stack>
                    </Box>
                </Box>
        }
      <TermsOfUseDialog termsOfUseOpen={termsOfUseOpen} setTermsOfUseOpen={setTermsOfUseOpen} />
      <PersonalDataDialog personalDataOpen={personalDataOpen} setPersonalDataOpen={setPersonalDataOpen} />
    </>
  );
};

export default Footer;
