import { Box, Button } from '@mui/material';
import { kakaoChatStart } from '../../../Components/KakaoChat/KakaoChat';

const DesktopLeftLayout = () => {

    return <>
        <Box>
            <img src="/image/logo_header.png" style={{ width: '151px', padding: 4 }} alt="logo"></img>
            <Box sx={{ fontSize: '29px', fontWeight: 'bold', marginY: 4 }}>
                <Box>가장 가까운 나만의 텃밭</Box>
                <Box><span style={{ color: '#2AC1BC' }}>에코그램 식물재배기</span>를 만나보세요.</Box>
            </Box>
            <Box sx={{ fontSize: '18px', color: '#B3B4B9' }}>
                <Box>라이프스타일과 필요한 채소 수확양에 따른 다양한<br />크기의 식물재배기 선택.</Box>
                <br />
                <Box>번거로운 관리는 월2회 에코매니저 케어서비스로<br />모종교체까지 완벽하게 제공하는 에코그램.</Box>
                <br />
                <Box sx={{ color: '#2AC1BC' }}>지금 만나볼 수 있습니다.</Box>
            </Box>

            <Box sx={{ marginY: 4 }}>
                <Button onClick={kakaoChatStart} variant="contained" component="label" fullWidth sx={{ padding: '8px 16px 6px 16px', borderRadius: 3, height: '62px', fontSize: '24px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}>
                    빠르게 상담하기
                </Button>
            </Box>
            <Box sx={{ fontSize: '18px', color: '#B3B4B9' }}>에코그램 웹사이트는 모바일에 최적화 되어있습니다.</Box>
        </Box>
    </>
}

export default DesktopLeftLayout;