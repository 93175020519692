import React, { useState, Dispatch, useEffect } from 'react';
import { Box, Button, Stack, styled } from '@mui/material';
import { ItemText, VisuallyHiddenInput } from '../styled';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  formData: IPostSaveGrowth;
  setFormData: Dispatch<React.SetStateAction<IPostSaveGrowth>>;
  setIsImageFile: Dispatch<React.SetStateAction<File | null>>;
  handleChange: (e: any) => void;
}

const ThumbnailImgItem = ({ formData, setFormData, setIsImageFile }: Props) => {
  const [imgUrl, setImgUrl] = useState<string>();
  const [delIcon, setDelIcon] = useState<boolean>(false);

  useEffect(() => {
    if (formData.postThumbnailUrl !== '') {
      setImgUrl(formData.postThumbnailUrl);
      setDelIcon(true);
    }
  }, [formData.postThumbnailUrl]);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    if (file) {
      reader.onloadend = () => {
        setImgUrl(reader.result as string);
        setFormData({
          ...formData,
          postThumbnailUrl: reader.result as string,
        });
        setIsImageFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleThumbnuilDel = () => {
    setImgUrl('');
    setDelIcon(false);
  };

  return (
    <Box>
      <ItemText>썸네일 이미지</ItemText>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        <div style={{ position: 'relative', height: '100%' }}>
          {imgUrl ? <img src={imgUrl} alt="" style={{ width: 100 }} /> : <></>}
          {delIcon && (
            <div
              onClick={handleThumbnuilDel}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '5px',
                right: 0,
              }}
            >
              <DeleteIcon />
            </div>
          )}
        </div>
        <Button
          component="label"
          variant="contained"
          sx={{
            backgroundColor: '#2ac1bc',
          }}
        >
          파일 업로드
          <VisuallyHiddenInput type="file" name="postThumbnailUrl" onChange={handleImageChange} />
        </Button>
      </Stack>
    </Box>
  );
};

export default ThumbnailImgItem;
