import api from './Api'

export const createReviewPost = (createReviewPost: IReviewPost) => {
    return api.post("/review-post", createReviewPost)
};

export const getReviewPost = () => {
    return api.get("/review-post").then((res) => {
        return res.data;
    }).catch((error) => {
        console.error('error', error);
    });
}

export const getReviewPostById = (id: string) => {
    return api.get(`/review-post/${id}`).then((res) => {
        return res.data;
    }).catch((error) => {
        console.error('error', error);
    });
}
