import React, { Dispatch, SetStateAction } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { ItemText } from '../styled';

interface Props {
  isEdit?: boolean;
  moveUrl: Array<plantGrowthVideos>;
  editMoveUrl?: string;
  setMovieUrl: Dispatch<SetStateAction<string>>;
  setFileStorageId: Dispatch<SetStateAction<number>>;
  handleChange: (e: any) => void;
}

const RadioImageSelectItem = ({ isEdit, moveUrl, editMoveUrl, setFileStorageId, handleChange }: Props) => {
  return (
    <Box>
      <Stack direction="column">
        <ItemText>식물 성장 영상 선택</ItemText>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="movieUrl" row onChange={handleChange}>
          {isEdit ? (
            <Stack direction="column">
              {editMoveUrl && (
                <>
                  <FormControlLabel
                    value={editMoveUrl}
                    checked
                    control={<Radio sx={{ position: 'absolute', top: 0, right: 0 }} />}
                    label={
                      <video style={{ width: 110 }}>
                        <source src={editMoveUrl} type="video/mp4" />
                      </video>
                    }
                    sx={{ position: 'relative', marginLeft: 0, marginRight: 1 }}
                  />
                </>
              )}
            </Stack>
          ) : moveUrl ? (
            moveUrl.map((item: plantGrowthVideos) => (
              <>
                <Stack direction="column">
                  <span style={{ fontSize: 10 }}>{item.createDate ?? ''}</span>
                  <FormControlLabel
                    onClick={() => setFileStorageId(item.fileStorageId)}
                    value={isEdit ? editMoveUrl : item.filePath}
                    control={<Radio sx={{ position: 'absolute', top: 0, right: 0 }} />}
                    label={
                      <video style={{ width: 110 }}>
                        <source src={isEdit ? editMoveUrl : item.filePath} type="video/mp4" />
                      </video>
                    }
                    sx={{ position: 'relative', marginLeft: 0, marginRight: 1 }}
                  />
                </Stack>
              </>
            ))
          ) : (
            <div
              style={{
                fontSize: 12,
                background: '#eee',
                height: 116,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              타임랩스 영상이 없습니다.
            </div>
          )}
        </RadioGroup>
      </Stack>
    </Box>
  );
};

export default RadioImageSelectItem;
