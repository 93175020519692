import { useNavigate, Link } from 'react-router-dom'
import {
    Box,
    Container,
    Stack
} from '@mui/material';
import { getUser } from '../../Services/Token';
import MetaTag from '../../Components/MetaTag/MetaTag';

const ProductInfo = () => {
    const navigate = useNavigate();

    return <>
        <MetaTag
            title="가장 가까운 텃밭, 에코그램 식물재배기"
            description="수경재배 방식의 에코그램 식물재배기는 실내 어느 곳에서나 설치하여 신선한 유러피안 샐러드 채소를 기를 수 있습니다."
            keywords="식물재배기, 수경재배기, 스마트팜, 수경재배, 수경재배모종, 식물영양제, 수경식물영양제, 모종구독, 식물LED등, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유러피안샐러드, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등, 교육용식물재배기, 샐러드구독"
        />
        <Box sx={{ minHeight: 'calc(100vh - 245px)}' }}>
            <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
                <Box sx={{ width: getUser() ? '48px' : '58px' }}></Box>
                <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                    <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
                </Box>
                <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
                    {getUser() ?
                        <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img> :
                        <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>}
                </Box>
            </Stack>

            <Box sx={{ backgroundImage: 'url(/image/product_banner_top.png)', backgroundSize: 'cover' }}>
                <Box sx={{ color: 'white', fontWeight: 'bold', padding: '50px 0 0 30px' }}>
                    <Box sx={{ color: '#2AC1BC', fontSize: '20px', marginBottom: '11px' }}>Life Style</Box>
                    <Box sx={{ fontSize: '26px', paddingBottom: '42px', lineHeight: '38px' }}>
                        <Box>내 라이프 스타일에</Box>
                        <Box>맞는 식물재배기</Box>
                    </Box>
                </Box>
            </Box>

            <Container sx={{ padding: '24px' }}>
                <Box sx={{ paddingTop: '8px', textAlign: 'center' }}>
                    <Box sx={{ marginBottom: 3 }}><img src="/image/product_365.png" style={{ width: '94px', padding: 4 }} alt="logo"></img></Box>
                    <Box sx={{ color: '#B3B4B9', fontSize: '15px', lineHeight: '24px' }}>365일 가장 가까운 곳에서 싱싱하고 건강한<br />무농약 샐러드를 만나보세요.</Box>
                    <br />
                    <Box sx={{ color: '#2AC1BC', fontSize: '15px', lineHeight: '24px', fontWeight: 'bold' }}>식물재배기. 꼭! 에코그램과 상의하세요.</Box>
                </Box>
                <Box sx={{ marginTop: '40px', marginBottom: '16px' }}>
                    <Stack direction="row">
                        <Box sx={{ width: '120px', height: '120px', marginRight: '16px' }}>
                            <Link to={'/product/EG-5000'}>
                                <Box sx={{ backgroundColor: '#F4F4F5', padding: '15px 0', borderRadius: 4 }}>
                                    <img
                                        src={'/image/EG5000-product.png'}
                                        alt={'식물재배기'}
                                        loading="lazy"
                                        style={{ width: '100%', height: '90px', objectFit: 'cover' }}
                                    />
                                </Box>
                            </Link>
                        </Box>
                        <Box>
                            <Link to={'/product/EG-5000'} style={{ textDecoration: 'none' }}>
                                <Box sx={{ fontWeight: 'bold', fontSize: '17px', marginY: '10px', color: 'black' }}>EG-5000</Box>
                                <Box sx={{ color: '#B3B4B9', fontSize: '13px', lineHeight: '18px' }}>대량으로 수확할 수 있는 양문형<br /> 식물 재배기</Box>
                                <Box sx={{ color: '#B3B4B9', fontSize: '13px', lineHeight: '18px', marginTop: '9px' }}>생태체험 학습용, 카페와 식당, 마트<br />에서 무기농 야채 판매/제공 가능</Box>
                            </Link>
                        </Box>
                    </Stack>

                    <Stack direction="row" sx={{ marginTop: '20px' }}>
                        <Box sx={{ width: '120px', height: '120px', marginRight: '16px' }}>
                            <Link to={'/product/EG-3000'}>
                                <Box sx={{ backgroundColor: '#F4F4F5', padding: '15px 0', borderRadius: 4 }}>
                                    <img
                                        src={'/image/EG3000-product.png'}
                                        alt={'식물재배기'}
                                        loading="lazy"
                                        style={{ width: '100%', height: '90px', objectFit: 'contain' }}
                                    />
                                </Box>
                            </Link>
                        </Box>
                        <Box>
                            <Link to={'/product/EG-3000'} style={{ textDecoration: 'none' }}>
                                <Box sx={{ fontWeight: 'bold', fontSize: '17px', marginY: '10px', color: 'black' }}>EG-3000</Box>
                                <Box sx={{ color: '#B3B4B9', fontSize: '13px', lineHeight: '18px' }}>가정, 학교, 카페, 식당, 마트에서<br /> 넉넉한 수확에 적합</Box>
                            </Link>
                        </Box>
                    </Stack>

                    <Stack direction="row" sx={{ marginTop: '20px' }}>
                        <Box sx={{ width: '120px', height: '120px', marginRight: '16px' }}>
                            <Link to={'/product/EG-2000'}>
                                <Box sx={{ backgroundColor: '#F4F4F5', padding: '25px', borderRadius: 4 }}>
                                    <img
                                        src={'/image/EG2000-product.png'}
                                        alt={'식물재배기'}
                                        loading="lazy"
                                        style={{ width: '100%', height: '70px' }}
                                    />
                                </Box>
                            </Link>
                        </Box>
                        <Box>
                            <Link to={'/product/EG-2000'} style={{ textDecoration: 'none' }}>
                                <Box sx={{ fontWeight: 'bold', fontSize: '17px', marginY: '10px', color: 'black' }}>EG-2000</Box>
                                <Box sx={{ color: '#B3B4B9', fontSize: '13px', lineHeight: '18px' }}>가정에서 무농약 샐러드를 만나는데<br /> 가장 적합</Box>
                            </Link>
                        </Box>
                    </Stack>
                </Box>
            </Container>
        </Box>

    </>
}

export default ProductInfo