import { Outlet } from 'react-router-dom';
import AuthHeader from '../../Header/AuthHeader';
import { Box, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import DesktopLeftLayout from '../DesktopLeftLayout/DesktopLeftLayout';
import MetaTag from '../../MetaTag/MetaTag';

export default function AuthLayout() {
    return <>
        <MetaTag />
        <Grid container sx={{ maxWidth: '1000px', margin: '0 auto' }}>
            <Grid xs={0} md={6}>
                <Box sx={{ width: '450px', margin: '0 auto' }}>
                    <Box sx={{ position: 'fixed', top: '25%', display: { xs: 'none', md: 'block' } }}>
                        <DesktopLeftLayout />
                    </Box>
                </Box>
            </Grid>
            <Grid xs={12} md={6}>
                <Box sx={{ maxWidth: '420px', position: 'relative', margin: { xs: '0 auto', md: '0 auto' } }}>
                    <Paper elevation={16}>
                        <Box sx={{ minHeight: '100vh' }}>
                            <AuthHeader />
                            <Outlet />
                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    </>
}
