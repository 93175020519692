import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    })
    return <>
        404 not found
    </>
}

export default NotFound;