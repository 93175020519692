import api from './Api';

export const getComCodeList = async (): Promise<Array<IComCode>> => {
  const res = await api.get('/comCode/list');
  return res.data;
};

export const getMyDiaryMainList = async (): Promise<Array<IDiaryItem>> => {
  const res = await api.get('/growthJournal/list');
  return res.data;
};
export const getGrowthPostingList = async () => {
  const res = await api.get('/growthJournal/post/list');
  return res.data;
};
export const getMyGrowthPostingList = async () => {
  const res = await api.get('/growthJournal/post/myPostingList');
  return res.data;
};
export const getChartExcelDownload = async (personalPlantGrowthJournalId: string) => {
  const apiUri = '/growthJournal/data/{personalPlantGrowthJournalId}/downloadExcel'.replace(
    '{personalPlantGrowthJournalId}',
    personalPlantGrowthJournalId
  );
  const res = await api.get(apiUri, { responseType: 'blob' });
  return res.data;
};
export const getMyDiaryDetailById = async (personalPlantGrowthJournalId: string): Promise<IDiaryItemDetail> => {
  const apiUri = '/growthJournal/detail/{personalPlantGrowthJournalId}/info'.replace(
    '{personalPlantGrowthJournalId}',
    personalPlantGrowthJournalId
  );
  const res = await api.get(apiUri);
  return res.data;
};
export const deleteMyDiaryDetail = async (personalPlantGrowthJournalId: string): Promise<void> => {
  const apiUri = '/growthJournal/info/{personalPlantGrowthJournalId}/delete'.replace(
    '{personalPlantGrowthJournalId}',
    personalPlantGrowthJournalId
  );
  const res = await api.delete(apiUri);
  return res.data;
};
export const createMyDiary = async (params: FormData): Promise<IDiaryItem> => {
  const res = await api.post('/growthJournal/info/save', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};
export const addMyDiaryDetails = async (params: FormData): Promise<IGrowthJournalDetails> => {
  const res = await api.post('/growthJournal/growthJournalEntry/save', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const modifyMyDiary = async (params: FormData): Promise<IDiaryItem> => {
  const res = await api.put('/growthJournal/info/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const modifyMyDiaryDetails = async (params: FormData): Promise<IGrowthJournalDetails> => {
  const res = await api.put('/growthJournal/growthJournalEntry/update', params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const deleteMyDiaryDetailEntry = async (
  growthJournalDetailId: string,
  personalPlantGrowthJournalId: string
): Promise<void> => {
  const apiUri = '/growthJournal/detail/info/{growthJournalDetailId}/{personalPlantGrowthJournalId}/delete'
    .replace('{growthJournalDetailId}', growthJournalDetailId)
    .replace('{personalPlantGrowthJournalId}', personalPlantGrowthJournalId);
  const res = await api.delete(apiUri);
  return res.data;
};
