export const checkUserId = (targetUserId: string) => {
    const min = 3;
    const max = 25;

    const userId = targetUserId.trim();

    if (!isRequired(userId)) {
        return 'Username cannot be blank.';
    } else if (!isBetween(userId.length, min, max)) {
        return `Username must be between ${min} and ${max} characters.`;
    } else {
        return 'Success';
    }
};

export const checkPassword = (targetPassword: string) => {
    const password = targetPassword.trim();

    if (!isRequired(password)) {
        return 'Password cannot be blank.';
    } else if (!isPasswordSecure(password)) {
        return '비밀번호를 확인해 주세요. \n\n비밀번호는 8자리 이상에 대문자, 소문자, 숫자, 특수문자(!@#$%^&*) 가 각각 1개 이상 포함되어야 합니다.';
    } else {
        return 'Success';
    }
};

const isRequired = (value: string) => value === '' ? false : true;

const isBetween = (length: number, min: number, max: number) => length < min || length > max ? false : true;

const isPasswordSecure = (password: string) => {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    return re.test(password);
};
