import { useContext, useEffect, useState } from 'react';
import { MemberContext, IMemberContext } from '../../../Context/Member';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import UserAgreementsModal from '../../../Components/UserAgreementsModal';
import { requestSignUp, requestLogin } from '../../../Services/Auth';
import {
    Box,
    Container,
    Button
} from '@mui/material';
import TextInput from '../../../Components/TextInput/TextInput';
import PhoneInput from '../../../Components/TextInput/PhoneInput';
import AuthCodeInput from '../../../Components/TextInput/AuthCodeInput';
import { checkAuthCode, createPhoneAuth } from '../../../Services/PhoneAuth';
import AuthCodeErrorModal from '../../../Components/Modal/AuthCodeErrorModal/AuthCodeErrorModal';

interface IState {
    userName: string,
    phoneNumber: string,
    securityNumber: string,
}

const SignUpSecond = () => {
    const navigate = useNavigate();
    const { signUpModel } = useContext<IMemberContext>(MemberContext);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [authCodeErrorOpenModal, setAuthCodeErrorOpenModal] = useState<boolean>(false);
    const [values, setValues] = useState<IState>({
        userName: '',
        phoneNumber: '',
        securityNumber: '',
    });
    const [isPhoneError, setIsPhoneError] = useState<boolean>(false);
    const [startTimerAuthCode, setStartTimerAuthCode] = useState<boolean>(false);
    const [minutes, setMinutes] = useState<number>(3);
    const [seconds, setSeconds] = useState<number>(0);
    const [phoneAuthId, setPhoneAuthId] = useState<string>('');

    const [isDisableSignup, setIsDisableSignup] = useState<boolean>(true);
    const [isShowAuthCodeInput, setIsShowAuthCodeInput] = useState<boolean>(false);
    const [completeAuthCode, setCompleteAuthCode] = useState<boolean>(false);
    const [isDisablePhoneNumberInput, setIsDisablePhoneNumberInput] = useState<boolean>(false);
    const [authCodeResponse, setAuthCodeResponse] = useState<ICheckAuthCodeResponse>({ result: false, message: '' });

    const handleChange = (prop: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    useEffect(() => {
        if (!startTimerAuthCode) {
            setMinutes(3);
            setSeconds(0);
            return;
        }

        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds, startTimerAuthCode]);

    const ClickSignUpButton = () => {
        setErrorMessage("");

        if (!signUpModel) {
            alert('userId, password 가 없습니다.');
            navigate('/signup-first');
            return;
        }

        if (isPhoneError) return alert('휴대전화번호를 확인해주세요.');
        if (authCodeResponse && !authCodeResponse.result) return alert('휴대번호 인증을 해주세요.');

        requestSignUp({ ...signUpModel, userName: values.userName, phoneNumber: values.phoneNumber, phoneAuthId: phoneAuthId }).then(
            () => {
                requestLogin(signUpModel.userId, signUpModel.password).then((res) => {
                    navigate('/signup-success');
                }).catch((error) => {
                    console.error('error', error);
                })
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setErrorMessage(resMessage);
            }
        )
    };

    const closeModal = () => setOpenModal(false)

    const checkPhonenumber = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        var regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/
        setIsPhoneError(!regExp.test(e.target.value));
    }

    const clickSendAuthCode = () => {
        createPhoneAuth({ phoneNumber: values.phoneNumber }).then((res: IPhoneAuthCreateResponse) => setPhoneAuthId(res.phoneAuthId));
        setCompleteAuthCode(false);
        setMinutes(3);
        setSeconds(0);
        setAuthCodeResponse({ result: false, message: '' });
        setStartTimerAuthCode(true);
        setIsShowAuthCodeInput(true);
        setIsDisableSignup(true);
        setIsDisablePhoneNumberInput(true);
    }

    const clickCheckAuthCodeButton = () => {
        checkAuthCode({ id: phoneAuthId, authCode: values.securityNumber }).then((res: ICheckAuthCodeResponse) => {
            if (res.result) {
                setStartTimerAuthCode(false);
                setAuthCodeResponse(res);
                setCompleteAuthCode(true);
                setIsDisableSignup(false);
            } else {
                setAuthCodeErrorOpenModal(true);
                setAuthCodeResponse(res);
            }
        });
    }

    return <>
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>회원가입 <span style={{ color: '#2AC1BC' }}>(2/2)</span></Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>계정정보 관리 및 고객정보 관리를 위해 필요한 정보입니다.</Box>
            </Box>
            <Box sx={{ marginTop: '40px' }}>
                <TextInput
                    id="id-textInput"
                    label="이름"
                    placeholder="고객명을 입력해 주세요"
                    value={values.userName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('userName')(e)}
                />
            </Box>
            <Box sx={{ marginTop: '25px' }}>
                <PhoneInput
                    id="id-textInput"
                    label="휴대전화번호"
                    placeholder="휴대전화번호를 입력해 주세요"
                    value={values.phoneNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('phoneNumber')(e)}
                    checkPhonenumber={(e: React.FocusEvent<HTMLInputElement, Element>) => checkPhonenumber(e)}
                    clickSendAuthCode={clickSendAuthCode}
                    error={isPhoneError}
                    disabledPhoneInput={isDisablePhoneNumberInput}
                />
            </Box>
            {isShowAuthCodeInput && <>
                <Box sx={{ marginTop: '25px' }}>
                    <AuthCodeInput
                        id="security-textInput"
                        label="인증번호"
                        placeholder="휴대전화로 전송된 인증번호"
                        value={values.securityNumber}
                        expiredTime={`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
                        clickCheckAuthCodeButton={clickCheckAuthCodeButton}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('securityNumber')(e)}
                        disabledAuthCode={completeAuthCode || (minutes === 0 && seconds === 0)}
                    />
                    <Box sx={{ color: '#2AC1BC', fontSize: '12px', marginTop: '10px' }}>{authCodeResponse.result && authCodeResponse.message}</Box>
                </Box>

                {!authCodeResponse.result && <>
                    <Box sx={{ color: '#B3B4B9', fontSize: '12px', marginTop: '10px' }}>
                        <Box>인증번호는 카카오톡 또는 문자메시지를 확인해 보세요</Box>
                        <Box>인증번호를 받지 못하셨나요?</Box>
                    </Box>
                    <Button
                        sx={{ textDecoration: 'underline', color: '#2AC1BC', fontSize: '12px', paddingLeft: 0, marginTop: '2px' }}
                        disabled={!(minutes === 0 && seconds === 0)}
                        onClick={() => {
                            setIsShowAuthCodeInput(false);
                            setIsDisablePhoneNumberInput(false);
                            setValues({ ...values, securityNumber: '' });
                        }}
                    >인증번호 재전송 하기</Button>
                </>}
            </>}

            {errorMessage && <Box style={{ color: 'rgb(211, 47, 47)', marginTop: '25px' }}>{errorMessage}</Box>}
        </Container>

        <Button
            variant="contained"
            fullWidth
            onClick={ClickSignUpButton}
            disabled={isDisableSignup}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            회원 가입하기
        </Button>

        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <><UserAgreementsModal closeModal={closeModal} /></>
        </Modal>

        <Modal
            open={authCodeErrorOpenModal}
            onClose={() => setAuthCodeErrorOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <AuthCodeErrorModal closeModal={() => setAuthCodeErrorOpenModal(false)} message={authCodeResponse.message} />
            </>
        </Modal>
    </>
}


export default SignUpSecond;
