import {
    InputLabel,
    FormControl,
    InputAdornment,
    IconButton,
    InputBase,
} from '@mui/material';
import {
    Visibility,
    VisibilityOff
} from '@mui/icons-material';
import { alpha, styled } from '@mui/material/styles';

interface IProps {
    id: string;
    label: string;
    placeholder: string;
    value: string;
    showPassword: boolean;
    onChange: Function;
    handleClickShowPassword: Function;
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#fcfcfb',
        border: '1px solid #ced4da',
        fontSize: '13px',
        width: '100%',
        padding: '14px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha('#2AC1BC', 0.25)} 0 0 0 0.2rem`,
            borderColor: '#2AC1BC'
        },
    },
}));

const PasswordInput = ({ id, label, placeholder, value, showPassword, onChange, handleClickShowPassword }: IProps) => {
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl sx={{ width: '100%' }} variant="outlined">
            <InputLabel shrink htmlFor="bootstrap-input" sx={{ fontSize: '13px', color: 'black', transform: 'none', "&.Mui-focused": { color: "#2AC1BC" } }}>
                {label}
            </InputLabel>
            <BootstrapInput
                id={id}
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={(e) => onChange(e)}
                sx={{ marginTop: '26px', fontSize: '13px' }}
                placeholder={placeholder}
                endAdornment={
                    <InputAdornment position="end" >
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword()}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}

export default PasswordInput;