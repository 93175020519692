import { alpha, styled } from '@mui/material/styles';
import { InputBase, InputLabel, FormControl } from '@mui/material';

const StyledInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: '20px',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#EEEEEE',
    fontSize: '14px',
    width: '100%',
    padding: '14px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    fontFamily: [
      'Noto Sans KR',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha('#2AC1BC', 0.25)} 0 0 0 0.2rem`,
      borderColor: '#2AC1BC',
    },
  },
}));

interface IProps {
  label: string;
  placeholder: string;
  id: string;
  value: string;
  onChange: Function;
  readOnly?: boolean;
}

const DiaryTextInput = ({ label, placeholder, id, value, onChange, readOnly = false }: IProps) => {
  return (
    <FormControl variant="standard" sx={{ width: '100%' }}>
      {label !== '' && (
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{ fontSize: '12px', color: 'black', transform: 'none', '&.Mui-focused': { color: '#2AC1BC' } }}
        >
          {label}
        </InputLabel>
      )}

      <StyledInput
        value={value}
        placeholder={placeholder}
        id={id}
        fullWidth
        onChange={(e) => onChange(e)}
        readOnly={readOnly}
      />
    </FormControl>
  );
};

export default DiaryTextInput;
