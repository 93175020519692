import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Stack, Paper, Grow, Modal, MenuItem, Grid, Card, CardMedia, Popper } from '@mui/material';
import { getUser } from '../../Services/Token';
import { useEffect, useRef, useState } from 'react';
import MetaTag from '../../Components/MetaTag/MetaTag';
import { deleteMyDiaryDetail, getChartExcelDownload, getMyDiaryDetailById } from '../../Services/Diary';
import AdditionalDiaryModal from '../../Containers/AdditionalDiaryModal';
import AreaCharts from '../../Components/AreaCharts/AreaCharts';
import useLoadMore from '../../Hooks/useLoadMore';
import SelectInput from '../../Components/SelectInput/SelectInput';
import PostItem from '../GrowthDiary/components/PostItem';
import { calculateDays, formatDate } from '../../Utils/dates';
import DeleteDiaryModal from '../GrowthDiary/components/DeleteDiaryModal';

const GrowthDiaryDetail = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getUser()) {
      alert('로그인이 필요합니다 ');
      navigate('/login');
    }
  }, []);
  const { personalPlantGrowthJournalId } = useParams();
  const [diaryDetail, setDiaryDetail] = useState<IDiaryItemDetail | undefined>(undefined);

  const fetchDiaryDetail = async () => {
    if (!personalPlantGrowthJournalId) return;
    try {
      const res = await getMyDiaryDetailById(personalPlantGrowthJournalId);
      if (!res) return setDiaryDetail(undefined);
      setDiaryDetail(res);
    } catch (error) {
      setDiaryDetail(undefined);
    }
  };

  useEffect(() => {
    fetchDiaryDetail();
  }, [personalPlantGrowthJournalId]);

  const deleteMyDetail = () => {
    if (!personalPlantGrowthJournalId) return;
    deleteMyDiaryDetail(personalPlantGrowthJournalId)
      .then((res) => {
        alert('삭제되었습니다.');
        navigate('/diary');
      })
      .finally(() => {
        setOpenDeleteModal(false);
      });
  };

  const handleClickExcelDownload = () => {
    if (!personalPlantGrowthJournalId) return;
    getChartExcelDownload(personalPlantGrowthJournalId).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Plant Growth Info.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  };
  const renderDateRange = () => {
    const { startDate, endDate, journalStatusNm }: any = diaryDetail;

    if (startDate) {
      const endDateText = endDate ? ` ~ ${endDate}` : ` ~ ${journalStatusNm}`;
      const days = calculateDays(startDate, endDate);

      return (
        <Box sx={{ color: '#000000', fontWeight: 400, fontSize: '15px' }}>
          기간 : {startDate} {endDateText} ({days}일)
        </Box>
      );
    } else {
      return null;
    }
  };
  const [popperOpen, setPopperOpen] = useState(false);
  const anchorRef: any = useRef(null);
  const handleToggle = () => {
    setPopperOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(popperOpen);
  useEffect(() => {
    if (prevOpen.current === true && popperOpen === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = popperOpen;
  }, [popperOpen]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredImages, setFilteredImages] = useState<Array<plantGrowthImages>>([]);
  const uniqueDates = Array.from(
    new Set(diaryDetail?.plantGrowthImages.map((image) => image?.createDate.split(' ')[0])),
  );
  const handleDateChange = (event: any) => {
    setSelectedDate(event.target.value);
  };
  useEffect(() => {
    if (selectedDate) {
      const filtered: any = diaryDetail?.plantGrowthImages.filter((image) => image.createDate.startsWith(selectedDate));
      setFilteredImages(filtered);
    }
  }, [selectedDate, diaryDetail]);

  const {
    visibleItemLength: visibleVideoLength,
    handleShowAll: handleVideoShowAll,
    handleFold: handleVideoFold,
    isShowAll: isShowAllVideo,
  } = useLoadMore(3, 3, diaryDetail?.plantGrowthVideos.length ?? 0);
  const {
    visibleItemLength: visibleVideoImageLength,
    handleShowAll: handleImageShowAll,
    handleFold: handleImageFold,
    isShowAll: isShowAllImage,
  } = useLoadMore(6, 3, diaryDetail?.plantGrowthImages.length ?? 0);
  const {
    visibleItemLength: postItemLengths,
    handleLoadMore: handleLoadMorePost,
    canLoadMore: canLoadMorePost,
  } = useLoadMore(4, 4, diaryDetail?.relatedPosts.length ?? 0);
  const handleRefresh = () => {
    fetchDiaryDetail();
  };

  if (!diaryDetail) return <></>;
  return (
    <>
      <MetaTag
        title="에코그램 성장일지로 내 반려식물을 관찰, 기록, 공유 하세요."
        description="나의 멋진 반려식물들을 관찰하고 기록하고 공유할 수 있습니다. 성장일지에 업로드된 이미지로 식물 성장 타임랩스 영상을 자동으로 생성해 줍니다. 전용 디바이스인 ‘플랜트그램'을 이용해 자동으로 내 식물의 사진 촬영 및 온도/습도 정보를 기록할 수 있습니다. 성장일지 포스팅으로 다른 사람과 성장일지를 공유할 수 있어요."
        keywords="반려식물, 성장일지, 플랜트그램, 식물 SNS, 타임랩스, 에코그램, 식물재배기, 온도/습도, 재배 환경 정보"
      />
      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
        <Box sx={{ width: getUser() ? '48px' : '58px' }}>
          <Box onClick={() => navigate('/diary')} sx={{ padding: '10px 0 0 10px', cursor: 'pointer' }}>
            <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
          </Box>
        </Box>
        <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
          <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
        </Box>
        <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
          {getUser() ? (
            <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img>
          ) : (
            <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>
          )}
        </Box>
      </Stack>

      {diaryDetail && (
        <>
          <Box
            sx={{
              backgroundImage: diaryDetail?.thumbnailUrl ? `url('${diaryDetail.thumbnailUrl}')` : 'none',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
              height: '110px',
              position: 'relative',
              padding: '20px 30px',
              boxSizing: 'border-box',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                backgroundColor: 'rgba(255,255,255, 0.5)',
              }}
            />
            <Box
              sx={{
                color: '#000000',
                position: 'relative',
                fontWeight: 700,
                fontSize: '18px',
              }}
            >
              {diaryDetail?.journalName}
            </Box>
            <Box
              sx={{
                marginTop: '10px',
                color: '#000000',
                position: 'relative',
                fontWeight: 400,
                fontSize: '12px',
              }}
            >
              생성일 : {diaryDetail?.createDate}
              <img
                src="/image/dot_menu.png"
                alt="menu"
                style={{ float: 'right', cursor: 'pointer' }}
                ref={anchorRef}
                onClick={handleToggle}
              />
              <Popper open={popperOpen} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }: any) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      position: 'absolute',
                      border: '1px solid #A9A9A987',
                      width: '97px',
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      fontWeight: 400,
                      fontSize: '18px',
                      color: '#000000',
                      right: -20,
                      top: 10,
                    }}
                  >
                    <Paper>
                      <Box
                        sx={{ padding: '5px 10px', cursor: 'pointer' }}
                        onClick={() => navigate(`/modify-diary/${personalPlantGrowthJournalId}`)}
                      >
                        수정하기
                      </Box>
                      <Box sx={{ padding: '5px 10px', cursor: 'pointer' }} onClick={() => setOpenDeleteModal(true)}>
                        삭제하기
                      </Box>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
          <Container sx={{ padding: '0' }}>
            <Box sx={{ marginTop: '25px', padding: '0 16px' }}>
              <Box sx={{ color: '#000000', fontWeight: 400, fontSize: '15px' }}>식물 : {diaryDetail?.plantType}</Box>
              <Box sx={{ color: '#000000', fontWeight: 400, fontSize: '15px' }}>
                구분 : {diaryDetail?.cultivationMethodNm}재배
              </Box>
              <Box
                sx={{
                  marginTop: '10px',
                  color: '#000000',
                  fontWeight: 400,
                  fontSize: '15px',
                }}
              >
                {diaryDetail && renderDateRange()}
              </Box>
              {diaryDetail?.deviceId && (
                <Box
                  sx={{
                    marginTop: '20px',
                    color: '#000000',
                    fontWeight: 400,
                    fontSize: '15px',
                  }}
                >
                  연결된 디바이스 : {diaryDetail?.deviceId}
                </Box>
              )}
            </Box>
            <Box sx={{ marginTop: '25px', padding: '0 16px' }}>
              <Box sx={{ color: '#000000', fontWeight: 700, fontSize: '18px' }}>
                식물 성장 영상 ({diaryDetail?.plantGrowthVideos.length ?? 0})
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {diaryDetail?.plantGrowthVideos.slice(0, visibleVideoLength).map((video: plantGrowthVideos) => (
                  <Box
                    key={video.fileStorageId}
                    sx={{
                      flex: '0 0 33.33%',
                      maxWidth: '33.33%',
                      boxSizing: 'border-box',
                      padding: '5px',
                    }}
                  >
                    <Box
                      sx={{
                        color: '#000000',
                        fontWeight: 400,
                        fontSize: '12px',
                      }}
                    >
                      {formatDate(video.createDate)}
                    </Box>
                    <video width="100%" height="86px" controls>
                      <source src={video.filePath} type={`video/mp4`} />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                ))}
              </Box>
              {diaryDetail?.plantGrowthVideos.length > 3 && (
                <Box
                  onClick={isShowAllVideo ? handleVideoFold : handleVideoShowAll}
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    fontWeight: 400,
                    fontSize: '15px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  더보기 ({!isShowAllVideo ? '펼치기' : '접기'})
                </Box>
              )}
            </Box>
            <Box sx={{ marginTop: '25px', padding: '0 16px' }}>
              <Box sx={{ color: '#000000', fontWeight: 700, fontSize: '18px' }}>재배 환경 정보</Box>
              {diaryDetail?.growthJournalDetails.length > 0 && <AreaCharts data={diaryDetail?.growthJournalDetails} />}
              <Box
                component={'span'}
                onClick={handleClickExcelDownload}
                style={{
                  display: 'inline-block',
                  float: 'right',
                  fontWeight: 400,
                  fontSize: '13px',
                  borderBottom: '0.3px solid #000',
                  cursor: 'pointer',
                }}
              >
                데이터 파일 다운로드 (.xlsx)
                <img
                  src="/image/tabler_download.png"
                  style={{ width: '20px', height: '20px', marginLeft: '3px', marginBottom: '-3px' }}
                  alt="download"
                />
              </Box>
            </Box>
            <Box sx={{ marginTop: '40px', padding: '0 16px' }}>
              <Box sx={{ color: '#000000', fontWeight: 700, fontSize: '18px' }}>
                성장일지 기록 ({diaryDetail?.growthJournalDetails.length ?? 0})
              </Box>
              {diaryDetail?.growthJournalDetails
                .filter((detail: IGrowthJournalDetails) => detail?.growthRecord && detail?.growthRecord.length > 0)
                .map((detail: IGrowthJournalDetails, idx: number) => {
                  const [datePart, timePart] = detail.createDate.split(' ');
                  return (
                    <Box
                      key={detail.personalPlantGrowthJournalId}
                      sx={{
                        color: '#000000',
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'flex-start',
                        marginBottom: '10px',
                        maxWidth: '360px',
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 700,
                          fontSize: '14px',
                          marginRight: '5px',
                        }}
                      >
                        #{idx + 1}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                          marginRight: '10px',
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: 400,
                            fontSize: '15px',
                          }}
                        >
                          {datePart} :
                        </Box>
                        <Box
                          sx={{
                            fontWeight: 400,
                            fontSize: '12px',
                          }}
                        >
                          {timePart}
                        </Box>
                      </Box>
                      <Box sx={{ fontWeight: 400, fontSize: '15px', width: '60%', wordWrap: 'break-word' }}>
                        {detail.growthRecord}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
            <Box sx={{ marginTop: '25px', padding: '0 16px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Box sx={{ color: '#000000', fontWeight: 700, fontSize: '18px' }}>
                  식물 이미지 ({diaryDetail?.plantGrowthImages.length ?? 0})
                </Box>
                <SelectInput
                  sx={{ minWidth: '135px', height: '30px', marginTop: 0 }}
                  label=""
                  placeholder="날짜 선택"
                  value={selectedDate || ''}
                  onChange={handleDateChange}
                  render={() => {
                    return uniqueDates.map((data: any) => (
                      <MenuItem key={data} value={data}>
                        {new Date(data).toLocaleDateString('ko-KR', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </MenuItem>
                    ));
                  }}
                />
              </Box>
              <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                {filteredImages.slice(0, visibleVideoImageLength).map((image) => (
                  <Grid key={image.fileStorageId} item xs={2}>
                    <Card>
                      <CardMedia component="img" height="40" image={image.filePath} alt="Plant Growth" />
                    </Card>
                  </Grid>
                ))}
              </Grid>
              {filteredImages.length > 6 && (
                <Box
                  onClick={isShowAllImage ? handleImageFold : handleImageShowAll}
                  style={{
                    width: '100%',
                    textAlign: 'right',
                    fontWeight: 400,
                    fontSize: '15px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  더보기 ({!isShowAllImage ? '펼치기' : '접기'})
                </Box>
              )}
            </Box>
            <Box
              onClick={() => setOpenModal(true)}
              sx={{
                background: '#2AC1BC',
                width: '200px',
                height: '49px',
                color: '#ffffff',
                fontWeight: 700,
                fontSize: '14px',
                margin: '40px auto 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              성장일지 기록 추가
            </Box>
          </Container>

          <Box
            sx={{
              marginTop: '30px',
              backgroundColor: '#F6F6F6',
              padding: '20px 30px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 1,
              }}
            >
              <Box
                sx={{
                  fontSize: '18px',
                  fontWeight: '700',
                  color: '#000000',
                  textAlign: 'left',
                }}
              >
                연관 포스팅 ( {diaryDetail?.relatedPosts.length ?? 0} )
              </Box>
              <Box
                onClick={() => {
                  if (diaryDetail?.plantGrowthVideos.length <= 0) return alert('식물 성장 영상이 없습니다');
                  navigate(`/newPosting/${personalPlantGrowthJournalId}`);
                }}
                sx={{
                  cursor: 'pointer',
                  background: '#2AC1BC',
                  width: '140px',
                  heigth: '30px',
                  color: '#ffffff',
                  fontWeight: 700,
                  fontSize: '14px',
                  padding: '10px 0',
                  textAlign: 'center',
                }}
              >
                + 포스팅 작성
              </Box>
            </Box>
            {diaryDetail?.relatedPosts.length > 0 ? (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  {diaryDetail?.relatedPosts?.slice(0, postItemLengths).map((listItem: IGrowthList, index: number) => (
                    <Box
                      key={index}
                      sx={{ cursor: 'pointer', flex: '0 0 calc(50% - 10px)', width: 'calc(50% - 10px)' }}
                      onClick={() => navigate(`/growth/posting/${listItem.growthJournalPostsId}`)}
                    >
                      <PostItem listItem={listItem} />
                    </Box>
                  ))}
                </Box>

                {canLoadMorePost && (
                  <Box
                    onClick={handleLoadMorePost}
                    sx={{
                      background: '#2AC1BC',
                      width: '120px',
                      heigth: '30px',
                      color: '#ffffff',
                      fontWeight: 700,
                      fontSize: '14px',
                      padding: '10px 0',
                      textAlign: 'center',
                      margin: '40px auto 0',
                    }}
                  >
                    더 불러오기
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  background: '#F2F2F2',
                  color: '#000000',
                  textAlign: 'center',
                  padding: '25px 0',
                  borderRadius: '14px',
                  mt: 4,
                }}
              >
                <img
                  src="/image/post_add_icon.png"
                  style={{
                    width: '60px',
                    height: '60px',
                    display: 'inline-block',
                  }}
                  alt="no Diary"
                />
                <Box sx={{ mt: 1, fontSize: '14px', fontWeight: '400' }}>성장일지에서</Box>
                <Box sx={{ fontSize: '14px', fontWeight: '400' }}>모두에게 공유되는</Box>
                <Box sx={{ fontSize: '14px', fontWeight: '400' }}>성장일지 포스팅을</Box>
                <Box sx={{ fontSize: '14px', fontWeight: '400' }}>생성할 수 있습니다.</Box>
              </Box>
            )}
          </Box>
        </>
      )}
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <DeleteDiaryModal closeModal={() => setOpenDeleteModal(false)} onDelete={deleteMyDetail} title="성장일지" />
        </>
      </Modal>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <AdditionalDiaryModal
            closeModal={() => setOpenModal(false)}
            personalPlantGrowthJournalId={personalPlantGrowthJournalId}
            handleRefresh={handleRefresh}
          />
        </>
      </Modal>
    </>
  );
};

export default GrowthDiaryDetail;
