import api from './Api';
import { setUser, removeUser } from "./Token";

export const requestSignUp = async (signUpModel: ISignUpModel) => (
    await api.post('/auth/signup', signUpModel)
);

export const requestLogin = async (userId: string, password: string) => {
    return api.post("/auth/login", { userId, password })
        .then((response) => {
            if (response.data.accessToken) {
                setUser(response.data);
            }
            return response.data;
        });
};

export const logout = (memberId: string) => {
    return api.post('/auth/logout', { memberId })
        .then((response) => {
            removeUser();
            return response.data;
        }).catch((error) => {
            console.error('error', error);
        })
};

export const checkExistUserId = async (userId: string): Promise<IOperationStatusModel> => {
    const res = await api.get(`/members/check/${userId}`);
    return res.data
}

export const checkExistUserIdAndPhoneNumber = async (userId: string, phoneNumber: string): Promise<IOperationStatusModel> => {
    const res = await api.get(`/members/check/${userId}/${phoneNumber}`);
    return res.data
}

export const getMembersByPhoneNumberWithPhoneAuthId = async (phoneNumber: string, phoneAuthId: string): Promise<Array<IProfile>> => {
    return api.post(`/auth/find-by-phone`, { phoneNumber, phoneAuthId })
        .then((response) => {
            return response.data;
        })
}

export const getMembersByPhoneNumber = async (phoneNumber: string): Promise<Array<IProfile>> => {
    return api.get(`/auth/${phoneNumber}`)
        .then((response) => {
            return response.data;
        })
}

export const requestResetPassword = async (userId: string, phoneNumber: string, phoneAuthId: string): Promise<IOperationStatusModel> => {
    const res = await api.post(`/auth/reset-password`, { userId, phoneNumber, phoneAuthId });
    return res.data
}