export const calculateDays = (startDate: string, endDate: any) => {
  const parseDate = (dateString: string) => {
    const [year, month, day] = dateString.split('.').map(Number);
    return new Date(year, month - 1, day);
  };
  const start: any = parseDate(startDate);
  const end: any = endDate && endDate !== '' ? parseDate(endDate) : new Date();
  const diffTime = Math.abs(end - start);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export function formatDate(inputDate: string) {
  const parsedDate = new Date(inputDate);

  const year = parsedDate.getFullYear() % 100;
  const month = parsedDate.getMonth() + 1;
  const day = parsedDate.getDate();

  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;

  const formattedDate = `${year}.${formattedMonth}.${formattedDay}`;

  return formattedDate;
}
