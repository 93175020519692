import React from "react";
import { InputBase, Stack, alpha, styled } from "@mui/material";

interface Props {
  title: string;
  handleChange: (e: any) => void;
}
const StyledInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: "20px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#EEEEEE",
    fontSize: "14px",
    width: "100%",
    padding: "14px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: [
      "Noto Sans KR",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha("#2AC1BC", 0.25)} 0 0 0 0.2rem`,
      borderColor: "#2AC1BC",
    },
  },
}));

const ItemText = styled("div")`
  margin: 5px 0;
  font-size: 12px;
  color: #000;
`;
const InputTitleItem = ({ title, handleChange }: Props) => {
  return (
    <Stack direction="column" sx={{ mt: 2, mb: 2 }}>
      <ItemText>포스팅 제목</ItemText>
      <StyledInput
        placeholder="포스팅 제목을 입력해주세요."
        name="postTitle"
        fullWidth
        onChange={handleChange}
        value={title}
      />
    </Stack>
  );
};

export default InputTitleItem;
