import { createContext, useState } from 'react';

interface ISignUpModel {
    userId: string;
    password: string;
}

export interface IMemberContext {
    signUpModel: ISignUpModel | undefined;
    setSignUpModel: (signUpModel: ISignUpModel) => void;
}

const defaultContext: IMemberContext = {
    signUpModel: undefined,
    setSignUpModel: () => { }
};

const MemberContext = createContext(defaultContext);

interface Props {
    children: JSX.Element | Array<JSX.Element>;
}

const MemberContextProvider = ({ children }: Props) => {
    const [signUpModel, setSignUpModel] = useState<ISignUpModel | undefined>(undefined);

    return (
        <MemberContext.Provider
            value={{
                signUpModel,
                setSignUpModel,
            }}>
            {children}
        </MemberContext.Provider>
    );
};
export { MemberContextProvider, MemberContext };