import api from './Api';

export const getMemberProfile = async (): Promise<IProfile> => {
    return api.get("/members/profile")
        .then((response) => {
            return response.data;
        });
}

export const checkPasswordApi = async (password: string): Promise<ICheckPassword> => {
    return api.post("/members/check-password", { password })
        .then((response) => response.data);
};

export const updateMember = async (userName: string, phoneNumber: string, phoneAuthId: string): Promise<IUpdateMember> => {
    return api.put("/members", { userName, phoneNumber, phoneAuthId })
        .then((response) => response.data);
};

export const updateMemberName = async (userName: string): Promise<IUpdateMember> => {
    return api.put("/members/update-name", { userName })
        .then((response) => response.data);
};

export const changePassword = async (changePassword: IChangePassword): Promise<IChangePassword> => {
    return api.put("/members/change-password", changePassword)
        .then((response) => {
            return response.data;
        })
}

export const deleteMember = async (deleteMember: IDeleteMember): Promise<IDeleteMember> => {
    return api.delete("/members", { data: deleteMember })
        .then((response) => {
            return response.data;
        })
}