import api from './Api';

export const revokeDeviceApi = async (deviceId: string): Promise<any> => {
    return api.post(`/device/${deviceId}`)
        .then((response) => response.data);
};
export const deleteDeviceApi = async (deviceId: string): Promise<any> => {
    return api.delete(`/device/${deviceId}`)
        .then((response) => response.data);
};
