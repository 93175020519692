
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
    Box,
    Button,
    IconButton
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from "react";

interface IProps {
    deviceList: Array<IDeviceItemForm>;
    updateDeviceList?: Function;
    isUpdate: boolean;
    disableDrag?: boolean;
}
//updateProductList
const ConnectedDeviceListNoDrag = ({ deviceList, updateDeviceList, isUpdate }: IProps) => {
    const [showMoreAction, setShowMoreAction] = useState('');
    const showMoreActionHandler = (id: string) => {
        setShowMoreAction(showMoreAction === id ? '' : id);
    }
    const handleDocumentClick = (e: any) => {
        if (showMoreAction.length && !e.target.closest('.more-action-button')) {
            setShowMoreAction('');
        }
    };
    
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [showMoreAction]);

    const updateHandler = (type: string, deviceId: string, id: string) => {
        if (updateDeviceList) {
            updateDeviceList(type, deviceId, id);
        }
    }

    const deviceListJSX = (item: IDeviceItemForm, index: number) => (
        <Box key={index} sx={{ border: '1px solid #EBECEE', padding: '20px', borderRadius: 2, marginBottom: '8px' }}>
            {isUpdate ?
                <Grid container spacing={2}>
                    {/* <Grid xs={2}>
                        <IconButton>
                            <DragHandleIcon style={{ fontSize: 30, color: '#2AC1BC' }} />
                        </IconButton>
                    </Grid> */}
                    <Grid xs={10}>
                        <Box sx={{ fontSize: '15px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>플랜트그램-{index + 1} ({item.deviceId})</Box>
                        <Box sx={{ marginTop: '10px', fontSize: '12px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start' }}>{item.deviceStatusNm}  
                            <Box sx={{ color: '#B3B4B9', fontSize: '12px', margin: '0 10px' }}>|</Box>
                            <Box sx={{ color: '#B3B4B9', fontSize: '12px' }}>
                                <Box sx={{fontWeight: 'normal'}}>최근연결: {item.lastUpdatedDate}</Box>
                                <Box sx={{ marginTop: '3px', fontWeight: 'normal' }}>(최초연결: {item.createDate})</Box>
                            </Box>
                        </Box >
                    </Grid>
                    <Grid xs={2}>
                        <IconButton className="more-action-button" onClick={() => showMoreActionHandler(item.id)} >
                            <MoreVertIcon style={{ fontSize: 25 }} />
                            {showMoreAction === item.id && 
                                <Box sx={{ position: 'absolute', bottom: item.deviceStatus !== 'DSCNCT' ? '-75px' : '-40px', left: '-60px', fontSize: '14px', 
                                           border: '1px solid #B3B4B9',wordBreak: 'keep-all', borderRadius: '5px', 
                                           background: '#fff', fontWeight: 'bold'}}>
                                    {item.deviceStatus !== 'DSCNCT' && (
                                        <Box onClick={() => updateHandler('revoke', item.deviceId, item.id)} sx={{ maxHeight: '35px', padding: '10px 15px', borderBottom: '1px solid #B3B4B9' }}>연결끊기</Box>
                                    )}
                                    <Box onClick={() => updateHandler('remove', item.deviceId, item.id)} sx={{ maxHeight: '35px', padding: '10px 15px' }}>삭제하기</Box>
                                </Box>
                            }
                        </IconButton>
                    </Grid>
                </Grid>
                :
                <>
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>플랜트그램-{index + 1} ({item.deviceId})</Box>
                    <Box sx={{ marginTop: '10px', fontSize: '12px', fontWeight: 'bold', display: 'flex', alignItems: 'flex-start' }}>{item.deviceStatusNm}  
                        <Box sx={{ color: '#B3B4B9', fontSize: '12px', margin: '0 10px' }}>|</Box>
                        <Box sx={{ color: '#B3B4B9', fontSize: '12px' }}>
                            <Box sx={{fontWeight: 'normal'}}>최근연결: {item.lastUpdatedDate}</Box>
                            <Box sx={{ marginTop: '3px', fontWeight: 'normal' }}>(최초연결: {item.createDate})</Box>
                        </Box>
                    </Box >
                </>
            }
        </Box>
    )

    return (
       <Box>
        <div>
        {deviceList.map((item: IDeviceItemForm, index: number) => (
            deviceListJSX(item, index)
        ))}
        </div>
       </Box>
    )
}

export default ConnectedDeviceListNoDrag;