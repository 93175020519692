import {
    Box,
    Button,
    Container,
    Stack
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { getDiagnosisById } from '../../Services/Diagnosis';
import { createConsult } from '../../Services/Consult';
import MetaTag from '../../Components/MetaTag/MetaTag';
const DiagnosisResult = () => {
    const navigate = useNavigate();
    const { diagnosisId } = useParams();
    const [diagnosisById, setDiagnosisById] = useState<IDiagnosisByIdResponse | undefined>(undefined);

    useEffect(() => {
        if (!diagnosisId) return alert('진단 id가 존재하지 않습니다.');

        getDiagnosisById(diagnosisId).then((res) => {
            if (res) {
                setDiagnosisById(res);
            } else {
                alert('잘못된 진단 id 입니다.');
                navigate('/');
            }
        });
    }, [])

    const getPlatTypeKr = (name: string) => {
        if (name === "ezabel") return "이자벨";
        if (name === "caipira") return "카이피라";
        if (name === "stanford") return "스탠포드";
        if (name === "basil") return "바질";
        return name;
    }

    const clickConsultButton = () => {
        if (window.confirm('등록된 휴대전화번호로\n 1:1 상담을 위한 메시지가 발송 됩니다.\n(카카오톡을 통해서 상담이 이뤄집니다.)')) {
            // Save it!
            createConsult({ diagnosisId: diagnosisId as string }).then(res => {
                if (res === 'success') {
                }
            });
        }
    }

    return <>
        <MetaTag
            title="수경재배기에서 키우는 식물의 질병 진단"
            description="키우는 수경재배식 식물이 아프거나 시들때 이미지로 검색해서 물어보세요. 에코그램이 바로 답변해드립니다."
            keywords="식물재배기, 수경재배기, 스마트팜, 수경재배,  식물영양제, 식물LED등, 식물LED, 순환펌프, 통풍관, 온도제어, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등"
        />
        <Container sx={{ padding: '0 0 20px 24px' }}>
            <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>진단 결과</Box>
        </Container>
        {diagnosisById && <>
            <Box sx={{ textAlign: 'center' }}><img src={diagnosisById?.imageUrl} style={{ maxHeight: '40vh', maxWidth: '100%' }} alt="diagnosis"></img></Box>
            <Container sx={{ padding: '24px' }}>
                <Box sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '33px', marginBottom: '16px' }}>식물 구분</Box>
                <Box sx={{ fontSize: '14px' }}>{getPlatTypeKr(diagnosisById.plantType)}</Box>
                <Box sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '33px', marginBottom: '16px' }}>진단 ID</Box>
                <Box sx={{ fontSize: '14px' }}>{diagnosisById.id}</Box>
                <Box sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '33px', marginBottom: '16px' }}>진단명</Box>
                <Box sx={{ fontSize: '14px' }}>{diagnosisById.diagnosisName}</Box>
                <Box sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '33px', marginBottom: '16px' }}>증상</Box>
                <Box sx={{ fontSize: '14px' }}>{diagnosisById.symptom}</Box>
                {diagnosisById.reason !== '' && <>
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '33px', marginBottom: '16px' }}>발생환경</Box>
                    <Box sx={{ fontSize: '14px' }}><div dangerouslySetInnerHTML={{ __html: diagnosisById.reason.replace(/\\n/g, '<br />') }}></div></Box>
                </>}
                {diagnosisById.solution !== '' && <>
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold', marginTop: '33px', marginBottom: '16px' }}>예방법</Box>
                    <Box sx={{ fontSize: '14px' }}><div dangerouslySetInnerHTML={{ __html: diagnosisById.solution.replace(/\\n/g, '<br />') }}></div></Box>
                </>}
                <Stack direction="row" spacing={2} sx={{ marginTop: '32px', marginBottom: '12px' }}>
                    <Button variant="outlined" component="label" sx={{ borderRadius: 10, paddingY: 1, width: '100%', borderColor: '#B3B4B9', color: '#B3B4B9', fontSize: '13px' }} onClick={() => navigate('/diagnosis')}>
                        다른 식물 진단하기
                    </Button>
                    <Button variant="outlined" component="label" sx={{ borderRadius: 10, paddingY: 1, width: '100%', borderColor: '#2AC1BC', color: '#2AC1BC', fontSize: '13px' }} onClick={clickConsultButton}>
                        전문가에게 1:1 문의
                    </Button>
                </Stack>
                <Button variant="contained" component="label" fullWidth sx={{ padding: '8px 16px 6px 16px', borderRadius: 10, height: '44px', fontSize: '15px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }} onClick={() => navigate('/')}>
                    메인 홈으로
                </Button>

            </Container>
        </>
        }
    </>
}

export default DiagnosisResult;