import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import {
    Box,
    Container,
    Stack,
    Button
} from '@mui/material';
import { getReviewPost } from '../../Services/ReviewPost';
import { getNews } from '../../Services/News';
import { getUser } from '../../Services/Token';
import MetaTag from '../../Components/MetaTag/MetaTag';

const Home = () => {
    const navigate = useNavigate();
    const [reviewPostList, setReviewPostList] = useState<Array<IReviewPostResponse> | undefined>(undefined);
    const [newsList, setNewsList] = useState<Array<INewsResponse> | undefined>(undefined);

    useEffect(() => {
        getReviewPostList();
        requestNewsList();
    }, []);

    const getReviewPostList = () => {
        getReviewPost().then((res: Array<IReviewPostResponse>) => {
            setReviewPostList(res);
        }).catch((error) => {
            console.error('server error', error);
        })
    }
    const requestNewsList = () => {
        getNews().then((res: Array<INewsResponse>) => {
            setNewsList(res);
        }).catch((error) => {
            console.error('server error', error);
        });
    }

    return <Box>
        <MetaTag
            title="에코그램 식물재배기 플랫폼"
            description="에코그램과 함께라면 누구나 쉽고 편한 식물생활을 할 수 있어요. 반려식물의 영양상태, 성장부진, 병진단을 알아보세요."
            keywords="식물재배기, 수경재배기, 스마트팜, 수경재배, 수경재배모종, 식물영양제, 수경식물영양제, 모종구독, 식물LED등, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유러피안샐러드, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등, 식물병진단, 식물이슈, 식물키우기, 식물재배, 채소재배, 교육용식물재배기"
        />
        <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
            <Box sx={{ width: getUser() ? '48px' : '58px' }}></Box>
            <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
            </Box>
            <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
                {getUser() ?
                    <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img> :
                    <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>}
            </Box>
        </Stack>
        <Box sx={{ backgroundImage: 'url(/image/home_banner_top.jpg)', backgroundSize: 'cover' }}>
            <Box sx={{ color: 'white', fontWeight: 'bold', padding: '50px 0 0 30px' }}>
                <Box sx={{ color: '#2AC1BC', fontSize: '20px', marginBottom: '11px' }}>WE'RE</Box>
                <Box sx={{ fontSize: '26px', paddingBottom: '42px', lineHeight: '38px' }}>
                    <Box>가장 가까운 텃밭</Box>
                    <Box>에코그램</Box>
                </Box>
            </Box>
        </Box>
        <Container sx={{ padding: '24px' }}>
            <Box sx={{ paddingTop: '8px', textAlign: 'center' }}>
                <Box sx={{ color: '#2AC1BC', fontWeight: 'bold', fontSize: '16px', marginBottom: '18px' }}>ABOUT US</Box>
                <Box sx={{ color: '#B3B4B9', fontSize: '15px', lineHeight: '24px' }}>국내최초 식물재배기 플랫폼, 에코그램은 <br />수경재배 방식의 식물재배기를<br /> 당신의 라이프 스타일에 맞춤형으로 제공합니다</Box>
                <br />
                <Box sx={{ color: '#B3B4B9', fontSize: '15px', lineHeight: '24px' }}>기르는 즐거움, 수확하는 기쁨은 누리고 <br /> 식물재배기 관리는<br />에코그램 매니저에게 맡겨주세요</Box>
            </Box>

            <Box>
                <Box sx={{ fontSize: '16px', fontWeight: 'bold', margin: '25px 0 16px 0' }}>설치 사례</Box>
                <Box sx={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
                    <Stack direction="row" spacing={1} sx={{ width: '100%', display: 'inline-block' }}>
                        {reviewPostList && reviewPostList.map((reviewPost: IReviewPostResponse) =>
                            <Box onClick={() => window.open(reviewPost.postUrl, '_blank')} sx={{ width: '147px', backgroundImage: `url(${reviewPost.imageUrl})`, backgroundSize: 'cover', display: 'inline-block', borderRadius: 2, position: 'relative', cursor: 'pointer' }}>
                                <Box sx={{ borderRadius: 2, backgroundColor: 'rgb(0 0 0 / 60%)' }}>
                                    <Box sx={{ padding: '63px 0 12px 12px', color: 'white', fontSize: '16px' }}>
                                        <Box sx={{ whiteSpace: 'break-spaces', height: '42px', overflow: 'hidden', lineHeight: '22px' }}>{reviewPost.title}</Box>
                                        <Box sx={{ marginTop: '15px', fontSize: '12px', fontWeight: 'normal' }}>
                                            {reviewPost.createDate.split('T')[0]}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Box>

            <Box sx={{ marginTop: '34px' }}>
                <Box sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '16px' }}>에코그램 새소식</Box>
                {newsList && newsList.slice(0, 5).map((news: INewsResponse) =>
                    <Box sx={{ border: '1px solid #EBECEE', padding: '17px 0 16px 20px', borderRadius: 1, marginBottom: 2, cursor: 'pointer' }} onClick={() => navigate(`/news/${news.id}`)}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box sx={{
                                fontSize: '14px', width: '75%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                            }}>{news.title}</Box>
                            <Box sx={{ fontSize: '12px', color: '#B3B4B9', width: '25%' }}>{news.createDate.split('T')[0].replaceAll('-', '.')}</Box>
                        </Stack>
                    </Box>)}
            </Box>
            <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                <img src="/image/logo_header.png" style={{ width: '96px', height: '30px' }} alt="logo"></img>
                <Box sx={{ color: '#B3B4B9', fontSize: '14px', marginTop: '16px' }}>에코그램 식물재배기에 대해서 <br />더 알고 싶으신가요?</Box>
                <Button onClick={() => navigate('/product')} variant="contained" sx={{ padding: '8px 16px 6px 16px', marginTop: '24px', borderRadius: 10, width: '212px', height: '44px', fontSize: '15px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}>보러가기</Button>
            </Box>
        </Container>

        <Box onClick={() => navigate('/company')} sx={{ marginTop: '16px', backgroundColor: '#2AC1BC', height: '91px', padding: '20px 20px 0 20px', cursor: 'pointer' }}>
            <Box sx={{ backgroundImage: 'url(/image/home_banner_icon.png)', backgroundSize: 'contain', backgroundColor: '#2AC1BC', backgroundRepeat: 'no-repeat', backgroundPosition: 'right' }}>
                <Box sx={{ fontSize: '15px', color: 'white' }}>클랫폼은 식물 재배의 모든것을 담고 있는<br />식물재배기 플랫폼 기업입니다</Box>
                <Button variant="contained" sx={{ padding: '8px 16px 6px 16px', textAlign: 'center', borderRadius: 10, fontSize: '12px', backgroundColor: '#209595', marginTop: '7px', ":hover": { backgroundColor: '#209595' } }}>더 알아보기</Button>
            </Box>
        </Box>
    </Box >
}

export default Home;