import { forwardRef } from 'react';
import {Button, Slide, Dialog,} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import Privacy from "../Priavy/Privacy";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    personalDataOpen: boolean;
    setPersonalDataOpen: Function;
}

const PersonalDataDialog = ({ personalDataOpen, setPersonalDataOpen }: IProps) => {
    return <Dialog
        open={personalDataOpen}
        onClose={() => setPersonalDataOpen(false)}
        TransitionComponent={Transition}
        maxWidth="xs"
    >
        <Privacy setPersonalDataOpen={setPersonalDataOpen} />
        <Button
            variant="contained"
            fullWidth
            onClick={() => setPersonalDataOpen(false)}
            sx={{ borderRadius: 0, fontSize: '16px', padding: '17px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            닫기
            </Button>
    </Dialog >
}

export default PersonalDataDialog;