import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2';
import { SelectChangeEvent } from '@mui/material/Select';
import { getProductBrandList } from '../Services/Product';
import {
    Box,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    IconButton,
    Button,
    MenuItem
} from '@mui/material';
import SelectInput from '../Components/SelectInput/SelectInput';
import TextInput from '../Components/TextInput/TextInput';

interface IProps {
    closeModal: Function;
    addProductList: Function;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '420px',
    width: '85%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    paddingY: '10px',
    paddingX: '24px'
};

const ProductRegisterModal = ({ closeModal, addProductList }: IProps) => {
    const [productBrandList, setProductBrandList] = useState<Array<IProductBrand>>([]);
    const [showCustomBrandName, setShowCustomBrandName] = useState<boolean>(false);
    const [showEcogramBrandName, setShowEcogramBrandName] = useState<boolean>(false);
    const [customBrandName, setCustomBrandName] = useState<string>('');
    const [values, setValues] = useState<IRegisterForm>({
        id: 0,
        brandName: '',
        modelName: '',
        way: '',
        size: '',
        indexNumber: 0
    });

    useEffect(() => {
        getProductBrandList()
            .then(res => {
                setProductBrandList([...res, { id: '0', brandName: '기타' }]);
            })
            .catch(error => console.warn(error));
    }, []);

    const handleChange = (prop: keyof IRegisterForm) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const addRegister = () => {
        if (values.brandName === '')
            return alert('브랜드명을 입력해주세요.');
        if (values.modelName === '')
            return alert('모델명을 입력해주세요.');
        if (values.way === '')
            return alert('식물재배기 방식을 입력해주세요.');
        if (values.size === '')
            return alert('식물재배기 규모를 입력해주세요.');

        const newProduct = { ...values, brandName: values.brandName === '기타' ? customBrandName : values.brandName };
        addProductList(newProduct);
        closeModal();
    }

    const handleBrandChange = (event: SelectChangeEvent) => {
        setShowCustomBrandName(event.target.value === '기타');
        setShowEcogramBrandName(event.target.value === '에코그램');
        setValues({ ...values, brandName: event.target.value, modelName: '' });
    };

    return <Box sx={style}>
        <Grid container spacing={2}>
            <Grid xs={10}>
                <Box sx={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '47px' }}>재배기 등록</Box>
            </Grid>
            <Grid xs={2} sx={{ textAlign: 'right', paddingRight: '0' }}>
                <IconButton
                    onClick={() => closeModal()}
                >
                    <CloseIcon style={{ fontSize: 30 }} />
                </IconButton>
            </Grid>
        </Grid>
        <Box sx={{ marginTop: '25px' }}>
            <SelectInput
                label="식물재배기 브랜드명"
                value={values.brandName}
                onChange={(event: SelectChangeEvent) => handleBrandChange(event)}
                render={() => {
                    return productBrandList.length !== 0 &&
                        productBrandList.map((productBrand: IProductBrand, index: number) =>
                            <MenuItem value={productBrand.brandName} key={index}>{productBrand.brandName}</MenuItem>)
                }}
            />
        </Box>
        {showCustomBrandName && <Box sx={{ marginTop: '25px' }}>
            <TextInput
                id="model-name-textInput"
                label=""
                placeholder="재배기 브랜드명을 직접 입력해 주세요"
                value={customBrandName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomBrandName(e.target.value)}
            />
        </Box>}

        {showEcogramBrandName ? <Box sx={{ marginTop: '25px' }}>
            <SelectInput
                label="식물 재배기 모델명"
                value={values.modelName}
                onChange={(event: SelectChangeEvent) => {
                    setValues({ ...values, modelName: event.target.value })
                }}
                placeholder="재배기 모델을 선택하세요"
                render={() => ([
                    <MenuItem value={"EG-5000"}>{"EG-5000"}</MenuItem>,
                    <MenuItem value={"EG-3000"}>{"EG-3000"}</MenuItem>,
                    <MenuItem value={"EG-2000"}>{"EG-2000"}</MenuItem>
                ])}
            />
        </Box> : <Box sx={{ marginTop: '25px' }}>
            <TextInput
                id="model-name-textInput"
                label="식물 재배기 모델명"
                placeholder="재배기 모델을 입력해 주세요"
                value={values.modelName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('modelName')(e)}
            />
        </Box>}


        <Box sx={{ marginTop: '25px' }}>
            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: 'black', fontSize: '13px', "&.Mui-focused": { color: "#2AC1BC" } }}>식물재배기 방식</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={values.way}
                    onChange={handleChange("way")}
                    sx={{}}
                >
                    <FormControlLabel value="밀폐형" control={<Radio sx={{ "&.Mui-checked": { color: "#2AC1BC" } }} />} label={<Box sx={{ fontSize: '15px' }}>밀폐형</Box>} />
                    <FormControlLabel value="개방형" control={<Radio sx={{ "&.Mui-checked": { color: "#2AC1BC" } }} />} label={<Box sx={{ fontSize: '15px' }}>개방형</Box>} />
                </RadioGroup>
            </FormControl>
        </Box>
        <Box sx={{ marginTop: '25px' }}>
            <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: 'black', fontSize: '13px', "&.Mui-focused": { color: "#2AC1BC" } }}>식물재배기 규모</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={values.size}
                    onChange={handleChange("size")}
                    sx={{ display: 'flex' }}
                >
                    <FormControlLabel value="1~10구" control={<Radio sx={{ "&.Mui-checked": { color: "#2AC1BC" } }} />} label={<Box sx={{ fontSize: '15px' }}>1~10구 </Box>} sx={{ width: '40%' }} />
                    <FormControlLabel value="11~30구" control={<Radio sx={{ "&.Mui-checked": { color: "#2AC1BC" } }} />} label={<Box sx={{ fontSize: '15px' }}>11~30구</Box>} sx={{ width: '40%' }} />
                    <FormControlLabel value="31~80구" control={<Radio sx={{ "&.Mui-checked": { color: "#2AC1BC" } }} />} label={<Box sx={{ fontSize: '15px' }}>31~80구</Box>} sx={{ width: '40%' }} />
                    <FormControlLabel value="81구 이상" control={<Radio sx={{ "&.Mui-checked": { color: "#2AC1BC" } }} />} label={<Box sx={{ fontSize: '15px' }}>81구 이상</Box>} sx={{ width: '40%' }} />
                </RadioGroup>
            </FormControl>
        </Box>

        <Box sx={{ textAlign: 'center', marginTop: '25px', marginBottom: '15px' }}>
            <Button
                variant="contained"
                sx={{ padding: '8px 16px 6px 16px', width: '160px', height: '42px', fontSize: '14px', color: 'white', backgroundColor: '#2AC1BC', borderRadius: '30px', ":hover": { backgroundColor: '#2AC1BC' } }}
                onClick={() => addRegister()}>
                등록하기
            </Button>
        </Box>

    </Box>
}
export default ProductRegisterModal;