import api from "./Api";

// 포스팅 리스트
export const getGrowthPostingList = (isFirst?: boolean) => {
  return api
    .get(`/growthJournal/post/list?first=${isFirst}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error("error", error);
    });
};

// 내 성장일지 리스트
export const getMyGrowthJournalList = () => {
  return api
    .get("/growthJournal/list")
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error("error", error);
    });
};

// 성장일지 리스트 디테일
export const getMydiaryDetailList = (personalPlantGrowthJournalId: string) => {
  return api
    .get(`/growthJournal/detail/${personalPlantGrowthJournalId}/info`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.error("error", error);
    });
};
//인기 성장 일지 리스트
export const getPostingPopularList = async () => {
  return await api
    .get("/growthJournal/post/popularList")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// 포스팅 디테일
export const getGrowthPostingDetail = async (
  listId: string,
  isFirst: boolean
) => {
  return await api
    .get(`/growthJournal/post/detail/${listId}/info?first=${isFirst}`)
    .then((res) => {
      console.log("getGrowthPostingDetail", res.data);

      return res.data;
    })
    .catch((error) => {
      console.error("error", error);
    });
};

// 포스팅 저장
export const postGrowthSave = async (
  params: FormData
): Promise<IGrowthPostingDetail> => {
  const res = await api.post("/growthJournal/post/save", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  console.log("postGrowthSave res.data", res.data);

  return res.data;
};

// 포스팅 업데이트
export const putGrowthPosting = async (
  params: FormData
): Promise<IGrowthPostingDetail> => {
  const res = await api.put("/growthJournal/post/update", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  console.log("putGrowthPosting res.data", res.data);

  return res.data;
};
export const deletePostingDetail = async (
  growthJournalPostsId: number
): Promise<void> => {
  const res = await api.delete(
    `/growthJournal/post/${growthJournalPostsId}/delete`
  );
  return res.data;
};
// 타임랩스 저장
export const postTimeLapse = (timeLapseUrl: string) => {
  return api
    .post(`/growthJournal/post/addTimelapse`, timeLapseUrl)
    .then((res) => {
      console.log("postTimeLapse", res);
      return res;
    })
    .catch((err) => {
      console.log("postTimeLapse err", err);
    });
};

// 댓글 저장
export const postCommentSave = (comment: IPostCommentSave) => {
  return api
    .post(`/growthJournal/post/comment/save`, comment)
    .then((res) => {
      console.log("postTimeLapse", res);
    })
    .catch((err) => {
      console.log("postTimeLapse err", err);
    });
};
