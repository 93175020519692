import React from "react";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { FileDownLink } from "./styled";
import { getUser } from "../../Services/Token";
import { useNavigate } from "react-router-dom";

interface Props {
  movieUrl: string;
  handleDownload: (movieUrl: string) => void;
}
const AnimationGifDown = ({ handleDownload, movieUrl }: Props) => {
  const navigate = useNavigate();
  return (
    <div style={{ margin: "10px 0 20px" }}>
      <FileDownLink
        onClick={() => {
          if (!getUser()) {
            alert("로그인이 필요합니다.");
            navigate("/login");
          } else {
            handleDownload(movieUrl);
          }
        }}
      >
        파일 다운로드
        <SaveAltIcon sx={{ marginLeft: "5px" }} />
      </FileDownLink>
    </div>
  );
};

export default AnimationGifDown;
