import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

const DiaryItem = ({ item }: { item: IDiaryItem }) => {
  const [device, setDevice] = useState<any>(null);

  const getDeviceStatus = () => {
    if (!item.deviceStatusNm) return;
    if (item.deviceStatusNm.includes('연결됨')) {
      return { img: `/image/device_connect.png`, msg: item.deviceStatusNm };
    } else if (item.deviceStatusNm.includes('끊김')) {
      return { img: `/image/device_disconnect.png`, msg: item.deviceStatusNm };
    } else {
      return { img: `/image/device_not.png`, msg: item.deviceStatusNm };
    }
  };
  useEffect(() => {
    setDevice(getDeviceStatus());
  }, [item.deviceStatus]);
  const handleImageError = (event: any) => {
    event.target.src = '';
    event.target.alt = '이미지 로딩 실패';
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', mt: 2 }}>
      <Box sx={{ width: '120px', height: '100px', display: 'flex', alignItems: 'center' }}>
        <img
          src={item.thumbnailUrl}
          onError={handleImageError}
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'left',
          flexDirection: 'column',
          color: '#000000',
          fontWeight: 400,
          fontSize: '14px',
          gap: '3px',
        }}
      >
        <Box
          sx={{
            fontWeight: 700,
            fontSize: '18px',
          }}
        >
          {item.journalName.length > 12 ? `${item.journalName.substring(0, 12)}.. ` : item.journalName}
        </Box>
        <Box>
          {item.startDate}~{item.endDate ? item.endDate : item.journalStatusNm}
        </Box>
        <Box>
          {item.plantType}( {item.cultivationMethodNm} ) <span style={{ margin: '0 5px' }}> | </span> 사진({' '}
          {item.imageCount} )
        </Box>
        {device && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img src={device.img} width="14px" height="18px" />
            {device.msg}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DiaryItem;
