import { useNavigate } from 'react-router-dom';
import { Container, Box, Button } from '@mui/material';

const ResultFindUserId = () => {
    const navigate = useNavigate();

    return (<>
        <Container sx={{ paddingTop: '100px', paddingX: 6 }}>
            <Box sx={{ textAlign: 'center' }}>
                <Box><img src="/image/ok_icon.png" style={{ width: '91px', padding: 4 }} alt="logo"></img></Box>
                <Box sx={{ fontSize: '20px', fontWeight: 'bold', marginTop: '33px' }}>본인인증이 완료되었습니다.</Box>
                <Box sx={{ fontSize: '15px', marginTop: '17px' }}>임시비밀번호가 등록된<br />휴대전화번호로 발송되었습니다.</Box>
                <Box sx={{ color: '#B3B4B9', fontSize: '13px', marginTop: '17px' }}>카카오톡 또는 문자메시지를 확인해 보세요.</Box>
                <Box sx={{ color: '#B3B4B9', fontSize: '13px', marginTop: '17px' }}>임시 비밀번호로 로그인 후<br />반드시 비밀번호를 변경해 주세요</Box>
            </Box>
        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={() => navigate('/login')}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            로그인 페이지로 이동
        </Button>
    </>)
}

export default ResultFindUserId;