import { useNavigate } from 'react-router-dom'
import {
    Box,
    Button,
    Container
} from '@mui/material';

const SignUpSuccess = () => {
    const navigate = useNavigate();
    const clickDetailButton = () => {
        navigate('/reg-detail-info');
    }
    return (
        <>
            <Container sx={{ paddingTop: '100px', paddingX: 6 }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Box><img src="/image/ok_icon.png" style={{ width: '91px', padding: 4 }} alt="logo"></img></Box>
                    <Box sx={{ fontSize: '20px', fontWeight: 'bold', marginY: 3 }}>회원 가입이 완료되었습니다.</Box>
                    <Box sx={{ marginBottom: 2, fontSize: '15px' }}>현재 식물 재배기를 사용중이신가요?</Box>
                    <Box sx={{ color: '#B3B4B9', fontSize: '13px' }}>식물 재배기 관리 및 서비스를 위해<br />추가 정보를 입력해 주세요</Box>
                    <Button variant="outlined" onClick={clickDetailButton} sx={{ paddingX: 5, fontSize: '15px', color: '#2AC1BC', borderColor: '#2AC1BC', ":hover": { borderColor: '#2AC1BC' }, borderRadius: '30px', marginTop: '32px' }}>추가 정보 입력하기</Button>
                    <Box sx={{ color: '#B3B4B9', fontSize: '13px', marginTop: '25px' }}>에코그램 이외의 식물재배기도 등록 가능합니다.</Box>
                </Box>
            </Container >

            <Button
                variant="contained"
                fullWidth
                onClick={() => navigate('/')}
                sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
            >
                메인 홈으로
        </Button>
        </>
    )
}

export default SignUpSuccess;