import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, IconButton, Button } from '@mui/material';

interface IProps {
  closeModal: Function;
  onDelete: Function;
  title: string;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '420px',
  width: '85%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  paddingY: '10px',
  paddingX: '24px',
};

const DeleteDiaryModal = ({ closeModal, onDelete, title }: IProps) => {
  return (
    <Box sx={style}>
      <Grid container spacing={2}>
        <Grid xs={10}>
          <Box sx={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '47px' }}>{title} 삭제</Box>
        </Grid>
        <Grid xs={2} sx={{ textAlign: 'right', paddingRight: '0' }}>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon style={{ fontSize: 30 }} />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '25px', width: '100%', textAlign: 'center' }}>
        <p>정말로 삭제하시겠습니까?</p>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '25px',
          marginBottom: '15px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '24px',
        }}
      >
        <Button
          variant="contained"
          sx={{
            padding: '8px 16px 6px 16px',
            width: '160px',
            height: '42px',
            fontSize: '14px',
            color: 'black',
            backgroundColor: '#fff',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#fff' },
          }}
          onClick={() => closeModal()}
        >
          취소
        </Button>
        <Button
          variant="contained"
          sx={{
            padding: '8px 16px 6px 16px',
            width: '160px',
            height: '42px',
            fontSize: '14px',
            color: 'white',
            backgroundColor: '#2AC1BC',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#2AC1BC' },
          }}
          onClick={() => onDelete()}
        >
          확인
        </Button>
      </Box>
    </Box>
  );
};
export default DeleteDiaryModal;
