import React from "react";
import { Box } from "@mui/material";

interface Props {
  navigate: any;
}
const TitleBar = ({ navigate }: Props) => {
  return (
    <Box
      onClick={() => navigate(-1)}
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: "15px",
      }}
    >
      <img
        src="/image/back_black.png"
        style={{ width: "12px", height: "25px" }}
        alt="back"
      ></img>
      <Box sx={{ fontWeight: 700, fontSize: "18px" }}>포스팅 작성하기</Box>
    </Box>
  );
};

export default TitleBar;
