import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { checkPasswordApi } from '../../../Services/Member';
import {
    Box,
    Container,
    Button,
    Modal
} from '@mui/material';
import TextInput from '../../../Components/TextInput/TextInput';
import PasswordInput from '../../../Components/TextInput/PasswordInput';
import InfoIcon from '@mui/icons-material/Info';
import InvalidPasswordModal from '../../../Components/Modal/InvalidPasswordModal/InvalidPasswordModal';
import { getUser } from '../../../Services/Token';

interface State {
    password: string;
    showPassword: boolean;
}

const CheckPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [errorPassword, setErrorPassword] = useState<string>('');
    const [values, setValues] = useState<State>({
        password: '',
        showPassword: false,
    });
    const user = getUser();

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const clickNextButton = (event: React.MouseEvent<HTMLElement>) => {
        if (!user) return alert('선택된 user 정보가 없습니다.');
        checkPasswordApi(values.password).then((res: ICheckPassword) => {
            if (!res.isCorrect) {
                setOpenModal(true);
                return;
            }
            if (location.pathname.includes('/basic')) {
                navigate('/profile/basic-info');
            } else if (location.pathname.includes('/detail')) {
                navigate('/profile/detail-info');
            }
        }).catch((error) => setErrorPassword(error));
    }

    return <>
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>비밀번호 확인</Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>계정정보의 보호를 위해 비밀번호를 다시 한번 입력해 주세요</Box>
            </Box>
            <Box sx={{ marginTop: '40px' }}>
                {user &&
                    <TextInput
                        id="id-textInput"
                        label="계정 ID"
                        placeholder="계정 ID를 입력해 주세요"
                        value={user.userId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { }}
                        readOnly={true}
                    />
                }
            </Box>
            <Box sx={{ marginTop: '25px' }}>
                <PasswordInput
                    id="password-textInput"
                    label="비밀번호"
                    placeholder="비밀번호"
                    value={values.password}
                    showPassword={values.showPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('password')(e)}
                    handleClickShowPassword={() => handleClickShowPassword()}
                />
            </Box>
            <Button
                sx={{
                    paddingX: 0,
                    paddingY: '10px',
                    color: '#2AC1BC',
                    textDecoration: 'underline',
                    fontSize: '12px'
                }}
                onClick={() => navigate('/find-member')}>
                <InfoIcon sx={{ fontSize: '18px', marginRight: '5px' }} />비밀번호를 잊으셨나요?
            </Button>
            <br /><br />
            {errorPassword !== '' && <Box>{errorPassword}</Box>}
            <br /><br /><br />
        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={clickNextButton}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            확인하기
        </Button>

        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <InvalidPasswordModal closeModal={() => setOpenModal(false)} />
            </>
        </Modal>
    </>
}

export default CheckPassword;