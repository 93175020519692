import { Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const PostItem = ({ listItem }: { listItem: IGrowthList }) => {
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "132px",
          backgroundImage: `url(${
            listItem.postThumbnailUrl ? listItem.postThumbnailUrl : ""
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "inline-block",
          position: "relative",
          cursor: "pointer",
        }}
      ></Box>
      <Box
        sx={{
          fontSize: "16px",
        }}
      >
        <Box
          sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            wordBreak: "break-all",
          }}
        >
          {listItem.postTitle}
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            wordBreak: "break-all",
          }}
        >
          {listItem.postContent}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              // marginTop: "15px",
              fontSize: "12px",
              fontWeight: "normal",
              display: "flex",
              alignItems: "center",
            }}
          >
            <VisibilityIcon />{" "}
            <span style={{ margin: "0 10px 0 5px" }}>{listItem.viewCount}</span>
          </Box>
          <Box
            sx={{
              // marginTop: "15px",
              fontSize: "12px",
              fontWeight: "normal",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FavoriteBorderIcon />{" "}
            <span style={{ margin: "0 5px" }}>{listItem.likeCount}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PostItem;
