import { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ProductListDragable from '../../Components/RegisterProduct/ProductListDragable';
import ConnectedDeviceListNoDrag from '../../Components/ConnectedDeviceList/ConnectedDeviceListNoDrag';
import { getMemberProfile } from '../../Services/Member';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../Services/Auth';
import {
    Box,
    Container,
    Button,
    IconButton,
    Stack,
    Chip,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TermsOfUseDialog from '../../Components/Dialog/TermsOfUseDialog';
import PersonalDataDialog from '../../Components/Dialog/PersonalDataDialog';

const Profile = () => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState<IProfile | undefined>(undefined);
    const [termsOfUseOpen, setTermsOfUseOpen] = useState<boolean>(false);
    const [personalDataOpen, setPersonalDataOpen] = useState<boolean>(false);

    useEffect(() => {
        getMemberProfile().then((res: IProfile) => {
            setProfile(res);
        }).catch((err: any) => {
            navigate('/login');
        });
    }, []);

    const updateProductList = (updateProductList: Array<IRegisterForm>) => {
        if (!profile) return;
        setProfile({ ...profile, memberSite: { ...profile.memberSite, memberProductList: updateProductList } });
    }

    const goToCheckPasswordPage = (url: string) => {
        if (!profile) return;
        navigate(url);
    }

    const ClickLogOut = (event: React.MouseEvent<HTMLElement>) => {
        if (!profile) return alert('유저가 없습니다.')

        logout(profile.id)
            .then((res) => {
                navigate('/logout-success');
            }).catch((res) => {
                console.error(res);
                alert('서버 에러가 발생하였습니다.');
            })
    }
    
    return <>
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>회원정보</Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>개인정보를 관리할 수 있습니다.</Box>
            </Box>
            {profile && (<>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '40px' }}>
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>기본정보</Box>
                    <Box>
                        <Chip
                            icon={<EditIcon style={{ marginLeft: '30px', fontSize: '18px', color: '#2AC1BC' }} />}
                            label={<Box sx={{ fontSize: '12px', color: '#2AC1BC', fontWeight: 'bold' }}>수정하기</Box>}
                            sx={{ backgroundColor: 'white' }}
                            onClick={() => goToCheckPasswordPage('/check-password/basic')}

                        />
                    </Box>
                </Stack>

                <Box sx={{ color: '#B3B4B9', marginTop: '24px', fontSize: '13px' }}>계정 ID</Box>
                <Box sx={{ marginTop: '15px', fontSize: '14px' }}>{profile.userId}</Box>

                <Box sx={{ color: '#B3B4B9', marginTop: '24px', fontSize: '13px' }}>이름</Box>
                <Box sx={{ marginTop: '15px', fontSize: '14px' }}>{profile.userName}</Box>

                <Box sx={{ color: '#B3B4B9', marginTop: '24px', fontSize: '13px' }}>휴대전화번호</Box>
                <Box sx={{ marginTop: '15px', fontSize: '14px' }}>{profile.phoneNumber}</Box>

                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: '40px' }}>
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>추가정보</Box>
                    <Box>
                        <Chip
                            icon={<EditIcon style={{ marginLeft: '30px', fontSize: '18px', color: '#2AC1BC' }} />}
                            label={<Box sx={{ fontSize: '12px', color: '#2AC1BC', fontWeight: 'bold' }}>수정하기</Box>}
                            sx={{ backgroundColor: 'white' }}
                            onClick={() => goToCheckPasswordPage('/check-password/detail')}

                        />
                    </Box>
                </Stack>

                {profile.memberSite ? (
                    <>
                        <Box sx={{ color: '#B3B4B9', marginTop: '24px', fontSize: '13px' }}>현장명</Box>
                        <Box sx={{ marginTop: '15px', fontSize: '14px' }}>{profile.memberSite.siteName}</Box>

                        <Box sx={{ color: '#B3B4B9', marginTop: '24px', fontSize: '13px' }}>현장 주소</Box>
                        <Box sx={{ marginTop: '15px', fontSize: '14px' }}>{profile.memberSite.address}</Box>

                        <Box sx={{ color: '#B3B4B9', marginTop: '24px', fontSize: '13px' }}>식물 재배기 목록</Box>
                        <Box sx={{ marginTop: '15px', fontSize: '14px' }}>
                            <ProductListDragable
                                productList={profile.memberSite.memberProductList}
                                updateProductList={updateProductList}
                                isUpdate={false}
                                disableDrag={true} />
                        </Box>
                    </>
                ) : (
                    <Box sx={{ marginTop: '15px', fontSize: '14px' }}>등록되어 있는 추가 정보가 없습니다.</Box>
                )}
                <Box sx={{ color: '#B3B4B9', marginTop: '24px', fontSize: '13px' }}>연결된 디바이스 목록</Box>
                {profile.devicesInfos && profile.devicesInfos.length > 0 ? (
                    <>
                        <Box sx={{ marginTop: '15px', fontSize: '14px' }}>
                            <ConnectedDeviceListNoDrag
                                deviceList={profile.devicesInfos}
                                isUpdate={false}
                                disableDrag={true} />
                        </Box>
                    </>
                ) : (
                    <Box sx={{ marginTop: '15px', fontSize: '14px' }}>등록되어 있는 디바이스 정보가 없습니다.</Box>
                )}
                <Box sx={{ border: '1px solid #EBECEE', padding: 2, borderRadius: 2, cursor: 'pointer', marginTop: '100px' }} onClick={() => setTermsOfUseOpen(true)}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid xs={10}>
                            <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>서비스 이용약관</Box>
                        </Grid>
                        <Grid xs={2}>
                            <IconButton>
                                <ArrowForwardIosIcon style={{ fontSize: 15, color: '#73747B' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ border: '1px solid #EBECEE', padding: 2, borderRadius: 2, cursor: 'pointer', marginTop: '8px', marginBottom: '100px' }} onClick={() => setPersonalDataOpen(true)}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid xs={10}>
                            <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>개인정보 처리방침</Box>
                        </Grid>
                        <Grid xs={2}>
                            <IconButton>
                                <ArrowForwardIosIcon style={{ fontSize: 15, color: '#73747B' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </>)
            }
        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={ClickLogOut}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#B3B4B9', color: 'white', maxWidth: '420px', ":hover": { backgroundColor: '#B3B4B9' } }}
        >
            로그아웃
        </Button>
        <TermsOfUseDialog termsOfUseOpen={termsOfUseOpen} setTermsOfUseOpen={setTermsOfUseOpen} />
        <PersonalDataDialog personalDataOpen={personalDataOpen} setPersonalDataOpen={setPersonalDataOpen} />
    </>
};

export default Profile;