import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import {
    Box,
    Button,
    Container,
    Grid,
    Stack
} from '@mui/material';
import { getUser } from '../../Services/Token';
import MetaTag from '../../Components/MetaTag/MetaTag';

const plantNameList = [
    "ezabel",
    "caipira",
    "stanford",
    "basil"
]

const Diagnosis = () => {
    const navigate = useNavigate();
    const [selectedPlant, setSelectedPlant] = useState<number>(0);


    return <Box sx={{ minHeight: 'calc(100vh - 245px)}' }}>
        <MetaTag
            title="수경재배기에서 키우는 식물의 질병 진단"
            description="키우는 수경재배식 식물이 아프거나 시들때 이미지로 검색해서 물어보세요. 에코그램이 바로 답변해드립니다."
            keywords="식물재배기, 수경재배기, 스마트팜, 수경재배,  식물영양제, 식물LED등, 식물LED, 순환펌프, 통풍관, 온도제어, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등"
        />
        <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px', borderBottom: '1px solid #F8F9FA' }}>
            <Box sx={{ width: getUser() ? '48px' : '58px' }}></Box>
            <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
            </Box>
            <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
                {getUser() ?
                    <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img> :
                    <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>}
            </Box>
        </Stack>
        <Container sx={{ padding: '24px', textAlign: 'center' }}>
            <Box sx={{ marginTop: '20px', fontSize: '22px', fontWeight: 'bold' }}>AI 식물 <span style={{ color: '#2AC1BC' }}>진단하기</span></Box>
            <Box sx={{ marginTop: '12px', color: '#B3B4B9', fontSize: '14px', lineHeight: '22px' }}>식물재배기 속 식물들도 지속적인 관심과 관리가<br />필요합니다. AI 분석을 통해 식물의 상태를<br />진단해 보세요</Box>
            <Box sx={{ marginTop: '24px' }}>
                <img
                    src={'/image/diagnosis_main.png'}
                    alt={'product unique'}
                    loading="lazy"
                    style={{ width: '272px' }}
                />
            </Box>
            <Box sx={{ fontSize: '17px', fontWeight: 'bold', marginTop: '40px' }}>진단할 식물을 선택하세요</Box>
            <Box sx={{ marginTop: '24px' }}>
                <Grid container>
                    <Grid xs={3}>
                        <Box
                            onClick={() => setSelectedPlant(0)}
                            sx={{ cursor: 'pointer' }}>
                            <img
                                src={selectedPlant === 0 ? '/image/diagnosis_ezabel_cir_on.jpg' : '/image/diagnosis_ezabel_cir_off.jpg'}
                                alt={'product unique'}
                                loading="lazy"
                                style={{ width: '90%' }}
                            />
                            <Box sx={{ color: selectedPlant === 0 ? 'black' : '#B3B4B9', fontSize: '14px', marginTop: '5px' }}>이자벨</Box>
                        </Box>
                    </Grid>
                    <Grid xs={3}>
                        <Box
                            onClick={() => setSelectedPlant(1)}
                            sx={{ cursor: 'pointer' }}>
                            <img
                                src={selectedPlant === 1 ? '/image/diagnosis_caipira_cir_on.jpg' : '/image/diagnosis_caipira_cir_off.jpg'}
                                alt={'product unique'}
                                loading="lazy"
                                style={{ width: '90%' }}
                            />
                            <Box sx={{ color: selectedPlant === 1 ? 'black' : '#B3B4B9', fontSize: '14px', marginTop: '5px' }}>카이피라</Box>
                        </Box>
                    </Grid>
                    <Grid xs={3}>
                        <Box
                            onClick={() => setSelectedPlant(2)}
                            sx={{ cursor: 'pointer' }}>
                            <img
                                src={selectedPlant === 2 ? '/image/diagnosis_stanford_cir_on.jpg' : '/image/diagnosis_stanford_cir_off.jpg'}
                                alt={'product unique'}
                                loading="lazy"
                                style={{ width: '90%' }}
                            />
                            <Box sx={{ color: selectedPlant === 2 ? 'black' : '#B3B4B9', fontSize: '14px', marginTop: '5px' }}>스탠포드</Box>
                        </Box>
                    </Grid>
                    <Grid xs={3}>
                        <Box
                            onClick={() => setSelectedPlant(3)}
                            sx={{ cursor: 'pointer' }}>
                            <img
                                src={selectedPlant === 3 ? '/image/diagnosis_basil_cir_on.jpg' : '/image/diagnosis_basil_cir_off.jpg'}
                                alt={'product unique'}
                                loading="lazy"
                                style={{ width: '90%' }}
                            />
                            <Box sx={{ color: selectedPlant === 3 ? 'black' : '#B3B4B9', fontSize: '14px', marginTop: '5px' }}>바질</Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ marginTop: '25px', marginBottom: '41px', color: '#B3B4B9', fontSize: '13px' }}><span style={{ color: '#2AC1BC', fontWeight: 'bold' }}>&bull;</span> 다른 식물 진단도 추가될 예정입니다.</Box>
            <Button variant="contained" component="label" fullWidth sx={{ padding: '8px 16px 6px 16px', borderRadius: 3, height: '50px', fontSize: '16px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}
                onClick={() => {
                    if (getUser()) {
                        navigate('/diagnosis/photo', { state: { plantName: plantNameList[selectedPlant] } });
                    } else {
                        alert('로그인이 필요합니다 ');
                        navigate('/login');
                    }
                }}>
                시작하기
            </Button>
        </Container>
    </Box >
}

export default Diagnosis