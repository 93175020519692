
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
    Box,
    IconButton
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

interface IProps {
    productList: Array<IRegisterForm>;
    updateProductList: Function;
    isUpdate: boolean;
    disableDrag?: boolean;
}

const ProductListDragable = ({ productList, updateProductList, isUpdate, disableDrag = false }: IProps) => {
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = [...productList];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateProductList(items.map((product: IRegisterForm, index: number) => ({ ...product, indexNumber: index })));
    };

    const removeProduct = (id: number) => {
        updateProductList(productList.filter((product: IRegisterForm) => product.id !== id));
    }

    const getItemStyle = (draggableStyle: any) => ({
        margin: '0 0 8px 0',
        ...draggableStyle
    });


    const productListJSX = (item: IRegisterForm, index: number) => (
        <Box key={index} sx={{ border: '1px solid #EBECEE', padding: '20px', borderRadius: 2, marginBottom: '8px' }}>
            {isUpdate ?
                <Grid container spacing={2}>
                    <Grid xs={2}>
                        <IconButton>
                            <DragHandleIcon style={{ fontSize: 30, color: '#2AC1BC' }} />
                        </IconButton>
                    </Grid>
                    <Grid xs={8}>
                        <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>{item.brandName} ({item.modelName})</Box>
                        <Box sx={{ color: '#B3B4B9', marginTop: '10px', fontSize: '12px' }}>{item.way} | {item.size}</Box>
                    </Grid>
                    <Grid xs={2}>
                        <IconButton onClick={() => removeProduct(item.id)}>
                            <CloseIcon style={{ fontSize: 25 }} />
                        </IconButton>
                    </Grid>
                </Grid>
                :
                <>
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>{item.brandName} ({item.modelName})</Box>
                    <Box sx={{ color: '#B3B4B9', marginTop: '10px', fontSize: '12px' }}>{item.way} | {item.size}</Box>
                </>
            }
        </Box>
    )

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {productList.sort((a, b) => a.indexNumber < b.indexNumber ? -1 : 1).map((item: IRegisterForm, index: number) => (
                            disableDrag ?
                                productListJSX(item, index) :
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {productListJSX(item, index)}
                                        </div>
                                    )}
                                </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext >
    )
}

export default ProductListDragable;