import { Box, Card, CardMedia, Grow, Modal, Paper, Popper } from '@mui/material';
import { useRef, useState } from 'react';
import AdditionalDiaryModifyModal from '../../Containers/AdditionalDiaryModifyModal';
import { deleteMyDiaryDetailEntry } from '../../Services/Diary';
import DeleteDiaryModal from '../GrowthDiary/components/DeleteDiaryModal';
const GrowthDiaryDetailModify = ({
  detail,
  diaryDetail,
  personalPlantGrowthJournalId,
  datePart,
  timePart,
  idx,
  handleRefresh,
}: {
  diaryDetail: IDiaryItemDetail;
  detail: IGrowthJournalDetails;
  personalPlantGrowthJournalId: string | number | undefined;
  datePart: string;
  timePart: string;
  idx: number;
  handleRefresh: () => void;
}) => {
  const [popperOpen, setPopperOpen] = useState(false);
  const anchorRef: any = useRef(null);
  const handlePopperToggle = (index: number) => {
    setPopperOpen((prevOpen) => !prevOpen);
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const deleteMyDetail = () => {
    if (!personalPlantGrowthJournalId || !detail?.growthJournalDetailId) return;
    deleteMyDiaryDetailEntry(detail.growthJournalDetailId.toString(), personalPlantGrowthJournalId.toString())
      .then((res) => {
        alert('삭제되었습니다.');
        handleRefresh();
      })
      .finally(() => {
        setOpenDeleteModal(false);
      });
  };

  return (
    <>
      <>
        <Box
          key={detail.personalPlantGrowthJournalId}
          sx={{
            color: '#000000',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'baseline',
            justifyContent: 'space-between',
            padding: '10px 0',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'space-between',
              fontWeight: 400,
              width: '100%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                fontWeight: 400,
              }}
            >
              <span style={{ fontWeight: 700, fontSize: '14px', marginRight: '5px' }}>#{idx + 1}</span>
              <span
                style={{
                  fontSize: '15px',
                  marginRight: '15px',
                }}
              >
                {datePart}
              </span>
              <span
                style={{
                  fontSize: '12px',
                }}
              >
                {timePart}
              </span>
            </Box>

            <Box>
              <img
                src="/image/dot_menu.png"
                alt="menu"
                style={{ float: 'right', cursor: 'pointer' }}
                ref={anchorRef}
                onClick={() => handlePopperToggle(idx)}
              />
              <Popper open={popperOpen} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }: any) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      position: 'absolute',
                      border: '1px solid #A9A9A987',
                      width: '97px',
                      transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      fontWeight: 400,
                      fontSize: '18px',
                      color: '#000000',
                      right: -20,
                      top: 10,
                    }}
                  >
                    <Paper>
                      <Box sx={{ padding: '5px 10px', cursor: 'pointer' }} onClick={() => setOpenModal(true)}>
                        수정하기
                      </Box>
                      <Box sx={{ padding: '5px 10px', cursor: 'pointer' }} onClick={() => setOpenDeleteModal(true)}>
                        삭제하기
                      </Box>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          </Box>
          {diaryDetail?.plantGrowthImages && (
            <Box sx={{ marginTop: '10px', overflowX: 'auto', whiteSpace: 'nowrap', width: '100%' }}>
              {diaryDetail?.plantGrowthImages
                .filter((item) => item.associatedPostId === detail.growthJournalDetailId)
                .map((dataUrl, index) => {
                  return (
                    <Card
                      key={index}
                      sx={{
                        display: 'inline-block',
                        marginRight: '10px',
                        width: '100px',
                        height: '86px',
                      }}
                    >
                      <CardMedia
                        component="img"
                        alt={`Uploaded Image ${index}`}
                        image={dataUrl.filePath}
                        width={'100%'}
                        height={'100%'}
                      />
                    </Card>
                  );
                })}
            </Box>
          )}
          {detail.temperature && detail.humidity && (
            <Box sx={{ marginTop: '5px' }}>
              <span style={{ fontWeight: 400, fontSize: '15px' }}>온도 : {detail.temperature}℃ </span>
              <span style={{ fontWeight: 400, fontSize: '15px' }}>/ 습도 : {detail.humidity}%</span>
            </Box>
          )}
          {detail.growthRecord && (
            <Box sx={{ marginTop: '5px' }}>
              <span style={{ fontWeight: 400, fontSize: '15px' }}>{detail.growthRecord}</span>
            </Box>
          )}
        </Box>
      </>
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <DeleteDiaryModal
            closeModal={() => setOpenDeleteModal(false)}
            onDelete={deleteMyDetail}
            title="성장일지 기록"
          />
        </>
      </Modal>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <AdditionalDiaryModifyModal
            closeModal={() => setOpenModal(false)}
            personalPlantGrowthJournalId={personalPlantGrowthJournalId}
            detail={detail}
            handleRefresh={handleRefresh}
          />
        </>
      </Modal>
    </>
  );
};

export default GrowthDiaryDetailModify;
