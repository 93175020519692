import React from "react";
import { Stack } from "@mui/material";

interface IProps {
  apiData: IGrowthPostingDetail | IDiaryItemDetail;
}

const AreaBox = ({ apiData }: IProps) => {
  return (
    <Stack
      direction="column"
      sx={{
        padding: "10px 15px",
        backgroundColor: "#EEEEEE",
        borderRadius: "4px",
        fontSize: "12px",
      }}
    >
      <div>식물 : {apiData.plantType}</div>
      <div>구분 : {apiData.cultivationMethodNm}</div>
      <div>
        기간 : {apiData.startDate} ~{" "}
        {apiData.endDate ?? apiData.journalStatusNm}
      </div>
    </Stack>
  );
};

export default AreaBox;
