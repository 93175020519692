import { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import ProductRegisterModal from '../../../Containers/ProductRegisterModal';
import ProductListDragable from '../../../Components/RegisterProduct/ProductListDragable';
import ConnectedDeviceListNoDrag from '../../../Components/ConnectedDeviceList/ConnectedDeviceListNoDrag';
import { updateMemberSite } from '../../../Services/MemberSite';
import { revokeDeviceApi, deleteDeviceApi } from '../../../Services/Device';
import { useDaumPostcodePopup } from 'react-daum-postcode';
import {
    Box,
    Container,
    Button,
    IconButton,
    Modal,
    Stack
} from '@mui/material';
import TextInput from '../../../Components/TextInput/TextInput';
import AddressInput from '../../../Components/TextInput/AddressInput';
import { getMemberProfile } from '../../../Services/Member';
import RevokeDeviceModal from '../../../Containers/RevokeDeviceModal';
import DeleteDeviceModal from '../../../Containers/DeleteDeviceModal';

interface IValues {
    siteName: string;
    address: string;
    addressDetail: string;
}

const DetailInfo = () => {
    const navigate = useNavigate();
    const open = useDaumPostcodePopup('https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js');
    const [profileForUpdate, setProfileForUpdate] = useState<IProfile | undefined>(undefined);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [productList, setProductList] = useState<Array<IRegisterForm>>([]);
    const [deviceList, setDeviceList] = useState<Array<IDeviceItemForm>>([]);
    const [showRevokeDeviceConfirm, setShowRevokeDeviceConfirm] = useState<boolean>(false);
    const [showDeleteDeviceConfirm, setShowDeleteDeviceConfirm] = useState<boolean>(false);
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
    const [selectedDeviceName, setSelectedDeviceName] = useState<string>('');
    const [values, setValues] = useState<IValues>({
        siteName: '',
        address: '',
        addressDetail: ''
    });
    
    useEffect(() => {
        getMemberInfo();
    }, [])

    const getMemberInfo = () => {
        getMemberProfile().then((res: IProfile) => {
            setProfileForUpdate(res);
            if (res.memberSite) {
                setValues({
                    siteName: res.memberSite.siteName,
                    address: res.memberSite.address,
                    addressDetail: res.memberSite.addressDetail
                })
            }
        }).catch((err: any) => {
            console.error('error', err);
            navigate('/login');
        });
    }

    useEffect(() => {
        if (!profileForUpdate) return;
        if (!profileForUpdate.memberSite) return;

        setValues({
            siteName: profileForUpdate.memberSite.siteName,
            address: profileForUpdate.memberSite.address,
            addressDetail: profileForUpdate.memberSite.addressDetail
        });
        setProductList(profileForUpdate.memberSite.memberProductList);
        setDeviceList(profileForUpdate.devicesInfos);
        const userInfo = JSON.parse(localStorage.getItem("ecogram-client-user") as string);
        userInfo.devicesInfos = profileForUpdate.devicesInfos;
        localStorage.setItem("ecogram-client-user", JSON.stringify(userInfo));
    }, [profileForUpdate]);
    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const closeModal = () => setOpenModal(false);

    const addProductList = (product: IRegisterForm) => {
        setProductList([...productList, { ...product, id: productList.length }])
    }
    const updateProductList = (updateProductList: Array<IRegisterForm>) => {
        setProductList(updateProductList)
    }

    const updateDeviceList = (type: string, deviceId: string, id: string) => {
        setSelectedDeviceName(deviceId);
        setSelectedDeviceId(id);
        if (type === 'revoke') {
            setShowRevokeDeviceConfirm(true);
        } else {
            setShowDeleteDeviceConfirm(true);
        }
    }
    const deleteDevice = () => {
        setShowDeleteDeviceConfirm(false);
        deleteDeviceApi(selectedDeviceId).then((res: any) => {
            if (res === 'ok') {
                getMemberInfo();
            }
        }).catch((err) => console.error(err.message));
    }
    const revokeDevice = () => {
        setShowRevokeDeviceConfirm(false);
        revokeDeviceApi(selectedDeviceId).then((res: any) => {
            if (res === 'ok') {
                getMemberInfo();
            }
        }).catch((err) => console.error(err.message));
    }
    
    const handleClickSaveButton = () => {
        const parameter = { ...values, memberProductList: productList };
        updateMemberSite(parameter).then((res: any) => {
            navigate('/profile/success-detail-info');
        }).catch((err) => console.error(err.message));
    }

    const handleComplete = (data: any) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        setValues({ ...values, address: fullAddress })
    };

    const openDaumPostcode = () => {
        open({ onComplete: handleComplete });
    };

    return <>
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>추가정보 등록 수정</Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>식물재배기 관리 및 서비스를 위한 정보 입니다.</Box>
            </Box>
            <Box sx={{ marginTop: '40px' }}>
                <TextInput
                    id="site-name-textInput"
                    label="현장명"
                    placeholder="현장명을 입력해 주세요 (예. 송파초등학교, 가산 롯데마트)"
                    value={values.siteName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('siteName')(e)}
                />
            </Box>
            <Box sx={{ marginTop: '25px' }}>
                <AddressInput
                    id="address-textInput"
                    label="현장 주소"
                    placeholder="현장 주소"
                    value={values.address}
                    onClick={openDaumPostcode}
                />
            </Box>
            <Box sx={{ marginTop: '8px' }}>
                <TextInput
                    id="site-name-textInput"
                    label=""
                    placeholder="상세 주소"
                    value={values.addressDetail}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('addressDetail')(e)}
                />
            </Box>

            <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '17px' }}>
                <Box style={{ fontSize: '13px', lineHeight: 3 }}>식물 재배기 목록</Box>
                <IconButton
                    onClick={() => setOpenModal(true)}
                    sx={{ fontSize: '12px', color: '#2AC1BC', lineHeight: '20px' }}
                >
                    <Box sx={{ paddingTop: '3px' }}>추가하기</Box>
                    <AddIcon sx={{ fontSize: '16px' }} />
                </IconButton>

            </Stack>
            {productList.length === 0 ?
                <Box sx={{ textAlign: 'center', color: '#B3B4B9', marginTop: '40px' }}>등록된 식물 재배기가 없습니다.</Box>
                :
                <ProductListDragable productList={productList} updateProductList={updateProductList} isUpdate={true} />
            }
            <br />
            <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '17px' }}>
                <Box style={{ fontSize: '13px', lineHeight: 3 }}>연결된 디바이스 목록</Box>
                {/* <IconButton
                    onClick={() => setOpenModal(true)}
                    sx={{ fontSize: '12px', color: '#2AC1BC', lineHeight: '20px' }}
                >
                    <Box sx={{ paddingTop: '3px' }}>추가하기</Box>
                    <AddIcon sx={{ fontSize: '16px' }} />
                </IconButton> */}

            </Stack>
            {deviceList.length === 0 ?
                <Box sx={{ textAlign: 'center', color: '#B3B4B9', marginTop: '40px' }}>등록된 식물 디바이스가 없습니다.</Box>
                :
                <ConnectedDeviceListNoDrag 
                    deviceList={deviceList} 
                    updateDeviceList={updateDeviceList} 
                    isUpdate={true}  />
            }
            <br /><br /><br />
        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={() => handleClickSaveButton()}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            추가정보 저장하기
        </Button>
        <Modal
            open={showRevokeDeviceConfirm}
            onClose={() => setShowRevokeDeviceConfirm(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <RevokeDeviceModal closeModal={() => setShowRevokeDeviceConfirm(false)} deviceName={selectedDeviceName} revokeDevice={revokeDevice} />
            </>
        </Modal>
        <Modal
            open={showDeleteDeviceConfirm}
            onClose={() => setShowDeleteDeviceConfirm(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <DeleteDeviceModal closeModal={() => setShowDeleteDeviceConfirm(false)} deviceName={selectedDeviceName} deleteDevice={deleteDevice} />
            </>
        </Modal>
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <ProductRegisterModal closeModal={closeModal} addProductList={addProductList} />
            </>
        </Modal>
    </>
}

export default DetailInfo;