import { Button, styled } from "@mui/material";

export const ItemText = styled("div")`
  margin: 5px 0;
  font-size: 12px;
  color: #000;
`;

export const CustomButton = styled(Button)`
  background-color: #2ac1bc;
  &:hover {
    background-color: #2ac1bc;
  }
`;

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
