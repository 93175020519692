import { useRoutes } from "react-router-dom";
import DefaultLayout from "./Components/Layout/DefaultLayout/DefaultLayout";
import AuthLayout from "./Components/Layout/AuthLayout/AuthLayout";
import WithoutHeaderFooter from "./Components/Layout/WithoutHeaderFooter/WithoutHeaderFooter";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import SignUpFirst from "./Pages/SignUp/SignUpFirst";
import SignUpSecond from "./Pages/SignUp/SignUpSecond";
import SignUpSuccess from "./Pages/SignUp/SignUpSuccess";
import LogoutSuccess from "./Pages/Login/LogoutSuccess";
import RegDetailInfo from "./Pages/RegDetailInfo";
import FindMember from "./Pages/FindMember";
import FindUserId from "./Pages/FindMember/FindUserId";
import ResultFindUserId from "./Pages/FindMember/ResultFindUserId";
import ResetPassword from "./Pages/ResetPassword";
import ResultResetPassword from "./Pages/ResetPassword/ResultResetPassword";
import Profile from "./Pages/Profile";
import CheckPassword from "./Pages/Profile/CheckPassword";
import BasicInfo from "./Pages/Profile/BasicInfo";
import Success from "./Pages/Profile/Success";
import UpdatePassword from "./Pages/Profile/UpdatePassword";
import DetailInfo from "./Pages/Profile/DetailInfo";
import SuccessDetailInfo from "./Pages/Profile/SuccessDetailInfo";
import SuccessDeleteMember from "./Pages/Profile/SuccessDeleteMember";
import DeleteMember from "./Pages/Profile/DeleteMember";
import ProductInfo from "./Pages/ProductInfo";
import ProductInfoDetail from "./Pages/ProductInfoDetail";
import Diagnosis from "./Pages/Diagnosis";
import CareService from "./Pages/CareService";
import DiagnosisPhoto from "./Pages/DiagnosisPhoto";
import DiagnosisResult from "./Pages/DiagnosisResult";
import Company from "./Pages/Company";
import NotFound from "./Pages/NotFound/NotFound";
import NewsDetail from "./Pages/NewsDetail/NewsDetail";
import GrowthDiaryCreate from "./Pages/GrowthDiaryCreate";
import GrowthDiaryDetail from "./Pages/GrowthDiaryDetail";
import GrowthPosting from "./Pages/GrowthCommuity";
import GrowthPostingDetails from "./Pages/GrowthCommuityDetails";
import GrowthPostingCreate from "./Pages/GrowthPostingCreate";
import GrowthDiary from "./Pages/GrowthDiary/index";
import { GrowthList } from "./Pages/GrowthList";
import GrowthDiaryModify from "./Pages/GrowthDiaryModify";
import Privacy from "./Components/Priavy/Privacy";

export interface IRoute {
  path?: string;
  element?: React.ReactNode | null;
  children?: React.ReactNode;
}

const Routes = () => {
  return useRoutes([
    {
      path: "/",
      element: <DefaultLayout />,
      children: [
        { path: "", element: <Home /> },
        { path: "product", element: <ProductInfo /> },
        { path: "product/EG-5000", element: <ProductInfoDetail /> },
        { path: "product/EG-3000", element: <ProductInfoDetail /> },
        { path: "product/EG-2000", element: <ProductInfoDetail /> },
        { path: "diagnosis", element: <Diagnosis /> },
        { path: "care", element: <CareService /> },
        { path: "company", element: <Company /> },
        { path: "growth", element: <GrowthList /> },
        { path: "growth/posting", element: <GrowthPosting /> },
        { path: "growth/posting/:listId", element: <GrowthPostingDetails /> },
        { path: "news/:newsId", element: <NewsDetail /> },
        { path: "diary", element: <GrowthDiary /> },
        {
          path: "diary/:personalPlantGrowthJournalId",
          element: <GrowthDiaryDetail />,
        },
      ],
    },
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        { path: "diagnosis/photo", element: <DiagnosisPhoto /> },
        { path: "diagnosis/result/:diagnosisId", element: <DiagnosisResult /> },
      ],
    },
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "signup-first", element: <SignUpFirst /> },
        { path: "signup-second", element: <SignUpSecond /> },
        { path: "reg-detail-info", element: <RegDetailInfo /> },
        { path: "find-member", element: <FindMember /> },
        { path: "find-userid", element: <FindUserId /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "profile", element: <Profile /> },
        { path: "check-password/basic", element: <CheckPassword /> },
        { path: "check-password/detail", element: <CheckPassword /> },
        { path: "profile/basic-info", element: <BasicInfo /> },
        { path: "update-password", element: <UpdatePassword /> },
        { path: "profile/detail-info", element: <DetailInfo /> },
        { path: "delete-member", element: <DeleteMember /> },
      ],
    },
    {
      path: "/",
      element: <WithoutHeaderFooter />,
      children: [
        { path: "result-find-userid", element: <ResultFindUserId /> },
        { path: "result-reset-password", element: <ResultResetPassword /> },
        { path: "signup-success", element: <SignUpSuccess /> },
        { path: "logout", element: <LogoutSuccess /> },
        { path: "success-delete-member", element: <SuccessDeleteMember /> },
        { path: "profile/success-detail-info", element: <SuccessDetailInfo /> },
        { path: "success-profile", element: <Success /> },
        { path: "newPosting", element: <GrowthPostingCreate /> },
        {
          path: "newPosting/edit/:growthJournalPostsId",
          element: <GrowthPostingCreate />,
        },
        {
          path: "newPosting/:personalPlantGrowthJournalId",
          element: <GrowthPostingCreate />,
        },
        { path: "new-diary", element: <GrowthDiaryCreate /> },
        {
          path: "modify-diary/:personalPlantGrowthJournalId",
          element: <GrowthDiaryModify />,
        },
        { path: "personalDataPrivacy", element: <Privacy setPersonalDataOpen={() => false} headerHide={true} /> },
      ],
    },
    {
      path: "/*",
      element: <NotFound />,
    },
  ]);
};

export default Routes;
