import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMembersByPhoneNumberWithPhoneAuthId } from '../../../Services/Auth';
import { Container, Box, Button } from '@mui/material';

interface CustomizedState {
    phoneNumber: string;
    phoneAuthId: string;
}

const ResultFindUserId = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as CustomizedState;
    const { phoneNumber, phoneAuthId } = state;
    const [userIds, setUserIds] = useState<Array<string> | undefined>(undefined);
    useEffect(() => {
        if (!phoneNumber) {
            alert("전화번호가 없습니다.");
            return navigate('/find-member');
        }

        getMembersByPhoneNumberWithPhoneAuthId(phoneNumber, phoneAuthId).then((res: Array<IProfile>) => {
            setUserIds(res.map((profile: IProfile) => profile.userId));
        }).catch((err: any) => {
            alert("로그인이 필요합니다.");
            navigate('/login');
        });
    }, []);

    return (<>
        <Container sx={{ paddingTop: '100px', paddingX: 6 }}>
            <Box sx={{ textAlign: 'center' }}>
                <Box><img src="/image/ok_icon.png" style={{ width: '91px', padding: 4 }} alt="logo"></img></Box>
                <Box sx={{ fontSize: '20px', fontWeight: 'bold', marginTop: '33px' }}>본인인증이 완료되었습니다.</Box>
                <Box sx={{ color: '#B3B4B9', fontSize: '13px', marginTop: '17px' }}>휴대전화번호와 연결된 계정 ID는<br />아래와 같습니다.</Box>
            </Box>
            {(userIds && userIds.length > 0) && <Box style={{ border: '1px solid #E9E9E9', padding: 20, fontSize: '15px', lineHeight: 2, marginTop: '32px' }}>
                {userIds.map((userId: string, index: number) => <Box key={index}>{userId}</Box>)}
            </Box>}
        </Container>

        <Button
            variant="contained"
            fullWidth
            onClick={() => navigate('/login')}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            로그인 페이지로 이동
        </Button>
    </>)
}

export default ResultFindUserId;