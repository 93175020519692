import { useState, forwardRef } from 'react';
import {
    Box,
    Button,
    Container,
    IconButton,
    Slide,
    Dialog,
    AppBar,
    Toolbar,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2';
import { TransitionProps } from '@mui/material/transitions';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2AC1BC',
        },
    },
});

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    pictureGuideOpen: boolean;
    setPictureGuideOpen: Function;
}

const PictureGuideDialog = ({ pictureGuideOpen, setPictureGuideOpen }: IProps) => {
    const [hiddenChecked, setHiddenChecked] = useState<boolean>(localStorage.getItem('hiddenPictureGuideDialog') === '1');

    const clickHiddenCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        localStorage.setItem('hiddenPictureGuideDialog', e.currentTarget.checked ? "1" : "0")
        setHiddenChecked(!hiddenChecked);
        if (e.currentTarget.checked) {
            setPictureGuideOpen(false)
        }
    }

    return <Dialog
        open={pictureGuideOpen}
        onClose={() => setPictureGuideOpen(false)}
        TransitionComponent={Transition}
    >
        <AppBar sx={{ position: 'relative', backgroundColor: 'white', boxShadow: 'none' }}>
            <Toolbar sx={{ justifyContent: 'end' }}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setPictureGuideOpen(false)}
                    aria-label="close"
                    sx={{ color: 'black' }}
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <Container sx={{ padding: '24px', maxWidth: { xs: '350px' } }}>
            <Box sx={{ textAlign: 'center', marginBottom: '30px' }}>
                <Box sx={{ fontSize: '17px', lineHeight: '28px' }}>
                    <Box>식물 전체의 모습 <span style={{ color: '#2AC1BC' }}>X</span></Box>
                    <Box>흰종이 위에 병든 식물의 잎 모습 <span style={{ color: '#2AC1BC' }}>OK</span></Box>
                </Box>
                <Box sx={{ marginTop: '17px', color: '#B3B4B9', fontSize: '15px' }}>(A4 용지를 배경으로 사용하면 편리합니다.)</Box>
                <Box sx={{ marginTop: '28px', fontSize: '40px', fontWeight: 'bold', color: '#2AC1BC' }}><img src="/image/picguide_good.png" style={{ width: '165px' }} alt="logo"></img></Box>
                <Grid container spacing='10px'>
                    <Grid xs={6}><img src="/image/photo_good1.jpg" style={{ width: '100%', height: '240px' }} alt="logo"></img></Grid>
                    <Grid xs={6}><img src="/image/photo_good2.jpg" style={{ width: '100%', height: '240px' }} alt="logo"></img></Grid>
                </Grid>
                <Box sx={{ color: '#B3B4B9', fontSize: '15px', marginTop: '25px' }}>진단할 잎이 구분되어 명확히 보입니다</Box>
                <Box sx={{ marginTop: '50px', fontSize: '40px', fontWeight: 'bold', color: '#2AC1BC' }}><img src="/image/picguide_bad.png" style={{ width: '130px' }} alt="logo"></img></Box>
                <Grid container spacing='10px'>
                    <Grid xs={5}><img src="/image/photo_bad1.jpg" style={{ width: '100%', height: '200px' }} alt="logo"></img></Grid>
                    <Grid xs={7}>
                        <img src="/image/photo_bad2.jpg" style={{ width: '100%', height: '96px' }} alt="logo"></img>
                        <img src="/image/photo_bad3.jpg" style={{ width: '100%', height: '96px', marginTop: '3px' }} alt="logo"></img>
                    </Grid>
                </Grid>
                <Box sx={{ color: '#B3B4B9', fontSize: '15px', marginTop: '25px' }}>진단할 잎이 정확히 구분되지 않습니다</Box>
                <br />
                <ThemeProvider theme={theme}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hiddenChecked}
                                onChange={(e) => clickHiddenCheckBox(e)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                sx={{ color: '#EBECEE' }}
                            />}
                        label={<Box sx={{ fontSize: '15px' }}>다시 보지 않기</Box>}
                    />
                </ThemeProvider>

            </Box>
        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={() => setPictureGuideOpen(false)}
            sx={{ borderRadius: 0, fontSize: '16px', padding: '17px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            닫기
            </Button>
    </Dialog>
}

export default PictureGuideDialog;