import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { ResponsiveContainer, AreaChart, XAxis, Tooltip, Area, Label } from 'recharts';

interface Props {
  data: any;
}

const AreaCharts = ({ data }: Props) => {
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    if (!data) return;
    const _formattedData = data.map((item: any) => ({
      date: new Date(item.createDate),
      기온: item.temperature === '-99' || !item.temperature ? null : Number(item.temperature),
      습도: item.humidity === '-99' || !item.humidity ? null : Number(item.humidity),
      SOILHUMIDITY: item.soilHumidity === '-99' || !item.soilHumidity ? null : Number(item.soilHumidity),
    }));

    setFormattedData(_formattedData);
  }, [data]);

  const getIntroOfPage = (soilHumidity: any) => {
    const numberValue = parseFloat(soilHumidity);

    let soilHumidityObj: any = null;
    if (numberValue >= 0 && numberValue < 25) {
      soilHumidityObj = {
        img: '/image/soil1.png',
        text: '토양 수분이 매우 부족합니다.',
      };
    } else if (numberValue < 50) {
      soilHumidityObj = {
        img: '/image/soil2.png',
        text: '토양 수분이 부족한 편입니다.',
      };
    } else if (numberValue < 75) {
      soilHumidityObj = {
        img: '/image/soil3.png',
        text: '토양 수분이 충분한 편입니다.',
      };
    } else if (numberValue <= 100) {
      soilHumidityObj = {
        img: '/image/soil4.png',
        text: '토양 수분이 매우 충분합니다.',
      };
    }

    return (
      soilHumidityObj && (
        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '14px', marginTop: '10px' }}>
          <img src={soilHumidityObj.img} style={{ width: '28px', height: '28px' }} alt="icon" />
          {soilHumidityObj.text}
        </Box>
      )
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const dateObject = new Date(label);

      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, '0');
      const day = String(dateObject.getDate()).padStart(2, '0');
      const hours = String(dateObject.getHours()).padStart(2, '0');
      const minutes = String(dateObject.getMinutes()).padStart(2, '0');
      const seconds = String(dateObject.getSeconds()).padStart(2, '0');
      const period = dateObject.getHours() >= 12 ? 'PM' : 'AM';

      const formattedDate = (
        <>
          {`${year}.${month}.${day}`}
          <span style={{ marginLeft: '12px' }}>{`${hours}:${minutes}:${seconds} ${period}`}</span>
        </>
      );

      return (
        <Box
          sx={{ backgroundColor: '#fff', height: '120px', width: '200px', border: '1px solid', padding: '0px 10px' }}
        >
          <p style={{ fontSize: '11px' }}>{formattedDate}</p>
          {payload[0].payload.습도 && (
            <span
              style={{ fontSize: '14px', color: '#1E5A8C', display: 'block' }}
            >{`습도: ${payload[0].payload.습도}%`}</span>
          )}
          {payload[0].payload.기온 && (
            <span
              style={{ fontSize: '14px', color: '#d0445e', display: 'block' }}
            >{`기온: ${payload[0].payload.기온}C`}</span>
          )}
          {getIntroOfPage(payload[0].payload.SOILHUMIDITY)}
        </Box>
      );
    }

    return null;
  };
  return (
    <div style={{ width: '100%', height: '200px', fontSize: '12px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={formattedData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis dataKey="date" tickFormatter={(label) => formatXAxisDate(label)} />
          <Label />
          <Tooltip content={<CustomTooltip />} payload={formattedData} />
          <Area type="linear" dataKey="습도" stackId="1" stroke="#1E5A8C" fill="#5C9ACF" />
          <Area type="linear" dataKey="기온" stackId="2" stroke="#d0445e" fill="#E7A2A3" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AreaCharts;

const formatXAxisDate = (label: string) => {
  const currentDate: any = new Date();
  const referenceDate: any = new Date(label);

  const elapsedTime = currentDate - referenceDate;
  const elapsedDays = Math.floor(elapsedTime / (1000 * 60 * 60 * 24));

  if (elapsedDays === 0) {
    const hours = Math.floor(elapsedTime / (1000 * 60 * 60));
    if (hours === 0) {
      const minutes = Math.floor(elapsedTime / (1000 * 60));
      return `${minutes}m`;
    }
    return `${hours}h`;
  } else if (elapsedDays >= 1 && elapsedDays <= 7) {
    return `${elapsedDays}d`;
  } else if (elapsedDays >= 7 && elapsedDays <= 30) {
    const weeks = Math.floor(elapsedDays / 7);
    return `${weeks}w`;
  } else if (elapsedDays < 0) {
    const minutes = Math.floor(elapsedTime / (1000 * 60));
    return `${minutes * -1}m`;
  } else {
    const months = Math.floor(elapsedDays / 30);
    return `${months}M`;
  }
};
