import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, InputLabel, MenuItem, alpha } from '@mui/material';

const ITEM_HEIGHT = 34;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IProps {
  label: string;
  value: string;
  onChange: Function;
  render: Function;
  placeholder?: string;
  isDiarySelect?: boolean;
  sx?: any;
}

export default function SelectInput({
  label,
  value,
  onChange,
  render,
  placeholder,
  isDiarySelect = false,
  sx,
}: IProps) {
  return (
    <Box>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{
            fontSize: '13px',
            color: 'black',
            transform: 'none',
            '&.Mui-focused': { color: '#2AC1BC' },
          }}
        >
          {label}
        </InputLabel>
        <Select
          displayEmpty
          value={value}
          onChange={(e: SelectChangeEvent) => {
            onChange(e);
          }}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            marginTop: '24px',
            fontSize: '13px',
            opacity: value === '' ? 0.7 : 1,
            backgroundColor: isDiarySelect ? '#eeeeee' : '',
            ...sx,

            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#2AC1BC',
            },

            '& .MuiOutlinedInput-notchedOutline': isDiarySelect
              ? {
                  border: 'none',
                  outline: 'none',
                }
              : {},

            '&.Mui-focused': isDiarySelect
              ? {
                  boxShadow: `${alpha('#2AC1BC', 0.25)} 0 0 0 0.2rem`,
                  borderColor: '#2AC1BC',
                }
              : {},
          }}
        >
          {!isDiarySelect && placeholder && (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}
          {isDiarySelect && placeholder && !value && (
            <MenuItem disabled value="" sx={{ fontSize: '14px', fontWeight: 700, color: '#000000' }}>
              {placeholder}
            </MenuItem>
          )}
          {render()}
        </Select>
      </FormControl>
    </Box>
  );
}
