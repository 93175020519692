import { useNavigate } from 'react-router-dom'
import {
    Box,
    Container,
    Stack,
    Button
} from '@mui/material';
import { getUser } from '../../Services/Token';
import { ReactComponent as FrameTop } from '../../Svg/frame_top.svg';
import { ReactComponent as FrameBottom } from '../../Svg/frame_bottom.svg';
import MetaTag from '../../Components/MetaTag/MetaTag';

const Company = () => {
    const navigate = useNavigate();

    const handleDownload = () => {
        fetch('https://img.ecogram.kr/2022/11/30/%EC%97%90%EC%BD%94%EA%B7%B8%EB%9E%A8_e_%EC%B9%B4%ED%83%88%EB%A1%9C%EA%B7%B8_221101.pdf', { method: 'GET' })
            .then(res => {
                return res.blob();
            })
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = 'ecogram.pdf';
                document.body.appendChild(a);
                a.click();
                setTimeout(
                    _ => { window.URL.revokeObjectURL(url); },
                    60000);
                a.remove();
            })
            .catch(err => {
                console.error('err: ', err);
            })
    };

    return <>
        <MetaTag
            title="We are 가장 가까운 텃밭, 에코그램"
            description="실내에서 수경재배 텃밭을 길러보세요. 직접 키워 365일 갓-따서 먹는 신선한 채소. 미래의 바른 먹거리를 위한 에코그램."
            keywords="식물재배기, 수경재배기, 스마트팜, 수경재배, 수경재배모종, 식물영양제, 수경식물영양제, 모종구독, 식물LED등, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유러피안샐러드, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등, 교육용식물재배기,식물진단, 식물이슈, 식물병진단, 샐러드구독"
        />
        <Box>
            <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
                <Box sx={{ width: getUser() ? '48px' : '58px' }}>
                    <Box onClick={() => navigate(-1)} sx={{ padding: '10px 0 0 10px', cursor: 'pointer' }}>
                        <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
                    </Box>
                </Box>
                <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                    <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
                </Box>
                <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
                    {getUser() ?
                        <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img> :
                        <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>}
                </Box>
            </Stack>

            <Box sx={{ backgroundImage: 'url(/image/company_banner_top.jpg)', backgroundSize: 'cover' }}>
                <Box sx={{ color: 'white', fontWeight: 'bold', padding: '50px 0 0 30px' }}>
                    <Box sx={{ color: '#2AC1BC', fontSize: '20px', marginBottom: '11px' }}>Technology</Box>
                    <Box sx={{ fontSize: '26px', paddingBottom: '42px', lineHeight: '38px' }}>
                        <Box>기술을 통해</Box>
                        <Box>지속 가능한</Box>
                        <Box>미래가치 실현</Box>
                    </Box>
                </Box>
            </Box>

            <Container sx={{ padding: '14px' }}>
                <FrameTop width="100%" />
                <Box sx={{ padding: '5px 0 10px 0', textAlign: 'center', fontSize: '15px' }}>
                    <Box sx={{ color: '#2AC1BC', fontWeight: 'bold' }}>WE ARE ECO GRAM</Box>
                    <Box sx={{ marginTop: '14px' }}>국내 최고의 식물재배기<br />케어 서비스 기술로 지속 가능한<br />에코 라이프를 실현 합니다.</Box>
                </Box>
                <FrameBottom width="100%" />
            </Container>

            <Box sx={{ backgroundColor: '#2D2E2D', padding: '24px' }}>
                <Box sx={{ color: '#2AC1BC', fontSize: '15px', fontWeight: 'bold' }}>WHAT WE DO</Box>
                <Box sx={{ marginTop: '10px', color: '#B3B4B9', fontSize: '13px', lineHeight: '16px' }}>라이프스타일에 맞는 식물재배기를 제공하고,<br />국내 최초 식물 재배기 관리 서비스를 연구합니다.</Box>
            </Box>

            <Container sx={{ padding: '24px 24px 0 24px' }}>
                <Stack direction="row">
                    <Box sx={{ width: '100px', marginRight: '16px' }}>
                        <Box sx={{ backgroundColor: '#F4F4F5', padding: '14px 0', borderRadius: 4 }}>
                            <img
                                src={'/image/EG5000-product.png'}
                                alt={'식물재배기'}
                                loading="lazy"
                                style={{ width: '100%', height: '68px', fontSize: 0, objectFit: 'cover' }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginY: '10px', color: 'black' }}>식물 재배기 제품군</Box>
                        <Box sx={{ color: '#B3B4B9', fontSize: '13px', lineHeight: '18px' }}>라이프 스타일에 따른 맞춤 제공 <br />(대형, 중형, 소형 식물재배기)</Box>
                    </Box>
                </Stack>

                <Stack direction="row" sx={{ marginTop: '15px' }}>
                    <Box sx={{ width: '100px', marginRight: '16px' }}>
                        <Box sx={{ backgroundColor: '#F4F4F5', padding: '14px', borderRadius: 4 }}>
                            <img
                                src={'/image/EG2000-product.png'}
                                alt={'식물재배기'}
                                loading="lazy"
                                style={{ width: '100%', height: '68px', fontSize: 0 }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginY: '10px', color: 'black' }}>모종 구독 서비스</Box>
                        <Box sx={{ color: '#B3B4B9', fontSize: '13px', lineHeight: '18px' }}>유러피안 샐러드 모종, 새싹인삼 모종</Box>
                    </Box>
                </Stack>

                <Stack direction="row" sx={{ marginTop: '15px' }}>
                    <Box sx={{ width: '100px', marginRight: '16px' }}>
                        <Box sx={{ backgroundColor: '#F4F4F5', padding: '14px', borderRadius: 4 }}>
                            <img
                                src={'/image/company_vegie.png'}
                                alt={'식물재배기'}
                                loading="lazy"
                                style={{ width: '100%', height: '68px', fontSize: 0 }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginY: '10px', color: 'black' }}>베지이지 식물 영양제</Box>
                        <Box sx={{ color: '#B3B4B9', fontSize: '13px', lineHeight: '18px' }}>수경재배 식물 맞춤 영양제<br />(부스터, 그로우, 블룸)</Box>
                    </Box>
                </Stack>
                <Stack direction="row" sx={{ marginTop: '15px' }}>
                    <Box sx={{ width: '100px', marginRight: '16px' }}>

                        <Box sx={{ backgroundColor: '#F4F4F5', borderRadius: 4 }}>
                            <img
                                src={'/image/product_care.png'}
                                alt={'식물재배기'}
                                loading="lazy"
                                style={{ width: '100%', height: '100px', fontSize: 0 }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginY: '10px', color: 'black' }}>에코매니저 케어서비스</Box>
                        <Box sx={{ color: '#B3B4B9', fontSize: '13px', lineHeight: '18px' }}>전문가의 정기적인 맞춤 관리 서비스<br />(양액공급, 클리닝, 모종교체)</Box>
                    </Box>
                </Stack>
                <Box sx={{ marginTop: '24px', borderBottom: '1px solid #EBECEE' }}></Box>
            </Container>

            <Container sx={{ padding: '24px' }}>
                <Box sx={{ fontSize: '16px', fontWeight: 'bold' }}>후기</Box>
                <Box sx={{ marginTop: '16px', border: '1px solid #EBECEE', padding: '33px', borderRadius: 3, textAlign: 'center' }}>
                    <Box sx={{ fontSize: '14px' }}>"학생들의 자연생태체험학습 교육에<br />많은 도움이 됩니다."</Box>
                    <Box sx={{ color: '#B3B4B9', marginTop: '17px', fontSize: '13px' }}>- 송파구 소재 초등학교 교장 -</Box>
                </Box>
                <Box sx={{ marginTop: '12px', border: '1px solid #EBECEE', padding: '33px', borderRadius: 3, textAlign: 'center' }}>
                    <Box sx={{ fontSize: '14px' }}>"대량 수확이 가능한 식물재배기,<br />플랜테리어로도 활용 가능"</Box>
                    <Box sx={{ color: '#B3B4B9', marginTop: '17px', fontSize: '13px' }}>- 서울신문 -</Box>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: 10, marginTop: '23px', padding: '14px 26px', fontSize: '15px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}
                        onClick={handleDownload}
                    >
                        회사소개서 다운로드 (PDF)
                        </Button>
                </Box>
            </Container>
        </Box>
    </>
}

export default Company