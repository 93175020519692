import {
    Box
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const clickBackButton = () => {
        location.pathname === '/login' ?
            navigate('/') :
            navigate(-1)
    }

    return <Box sx={{ padding: '10px' }}>
        <Box sx={{ padding: '10px', cursor: 'pointer', display: 'inline-block' }} onClick={() => clickBackButton()}>
            <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
        </Box>
    </Box>

}

export default AuthHeader;