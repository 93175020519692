import { alpha, styled } from '@mui/material/styles';
import {
    InputBase,
    InputLabel,
    FormControl,
    Stack,
    Button
} from '@mui/material';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#fcfcfb',
        border: '1px solid #ced4da',
        fontSize: '13px',
        width: '100%',
        padding: '14px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha('#2AC1BC', 0.25)} 0 0 0 0.2rem`,
            borderColor: '#2AC1BC',
        },
    },
}));

interface IProps {
    label: string;
    placeholder: string;
    id: string;
    value: string;
    onClick: Function;
}

const AddressInput = ({ label, placeholder, id, value, onClick }: IProps) => {
    return (
        <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink htmlFor="bootstrap-input" sx={{ fontSize: '13px', color: 'black', transform: 'none', "&.Mui-focused": { color: "#2AC1BC" } }}>
                {label}
            </InputLabel>
            <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginTop: '26px' }}>
                <BootstrapInput
                    value={value}
                    placeholder={placeholder}
                    id={id}
                    fullWidth
                    onClick={() => onClick()}
                />
                <Button variant="contained" sx={{ padding: '8px 16px 6px 16px', width: '150px', backgroundColor: '#2AC1BC', fontSize: '13px', ":hover": { backgroundColor: '#2AC1BC' } }} onClick={() => onClick()}>주소 검색</Button>
            </Stack>
        </FormControl >
    )
}

export default AddressInput;