import api from './Api';

export const createDiagnosis = async (createDiagnosisParam: { imageUrl: string }): Promise<any> => {
    return api.post("/diagnosis", createDiagnosisParam)
        .then((response) => {
            return response.data;
        });
}

export const getDiagnosisById = (id: string) => {
    return api.get(`/diagnosis/${id}`).then((res) => {
        return res.data;
    }).catch((error) => {
        console.error('error', error);
    });
}