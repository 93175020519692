import { useState } from 'react';

const useLoadMore = (initialVisibleItems = 3, increment = 3, totalItemLength: number) => {
  const [visibleItemLength, setVisibleItemLength] = useState(initialVisibleItems);
  const [isShowAll, setIsShowAll] = useState(false);

  const handleLoadMore = () => {
    setVisibleItemLength((prevVisibleItems) => prevVisibleItems + increment);
  };
  const handleShowAll = () => {
    setVisibleItemLength(totalItemLength);
    setIsShowAll(true);
  };

  const handleFold = () => {
    setVisibleItemLength(initialVisibleItems);
    setIsShowAll(false);
  };
  return {
    visibleItemLength,
    handleLoadMore,
    handleShowAll,
    handleFold,
    canLoadMore: visibleItemLength < totalItemLength,
    isShowAll,
  };
};

export default useLoadMore;
