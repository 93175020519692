import { useNavigate } from 'react-router-dom';
import { Container, Box, Button } from '@mui/material';

const Success = () => {
    const navigate = useNavigate();

    return (
        <>
            <Container sx={{ paddingTop: '100px', paddingX: 6 }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Box><img src="/image/ok_icon.png" style={{ width: '91px', padding: 4 }} alt="logo"></img></Box>
                    <Box sx={{ fontSize: '20px', fontWeight: 'bold', marginTop: '33px' }}>기본정보 수정이 완료되었습니다</Box>
                    <Box sx={{ color: '#B3B4B9', fontSize: '13px', marginTop: '17px' }}>원활한 서비스 이용을 위해<br /> 주기적으로 업데이트 해주세요.</Box>
                </Box>
            </Container>

            <Button
                variant="contained"
                fullWidth
                onClick={() => navigate('/')}
                sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
            >
                확인
        </Button>
        </>
    )
}

export default Success;