import { useState } from 'react';
import Carousel from 'react-material-ui-carousel'
import {
    Box,
    Container,
    Stack,
    Paper
} from '@mui/material';
import {
    EG5000,
    EG3000,
    EG2000
} from '../../Utils/ProductName';

interface IProps {
    productname: string;
}

interface IItem {
    name: string;
    imageUrls: Array<string>;
    description: string;
}

const CarouselProductInfo = ({ productname }: IProps) => {
    const [indexImage, setIndexImage] = useState<number>(0);

    var items = [
        {
            name: EG5000,
            description: '대량으로 수확할 수 있는 양문형 식물 재배기<br />에코매니저의 전문적인 케어서비스 제공',
            imageUrls: ['/image/EG5000-1.jpg', '/image/EG5000-2.jpg', '/image/EG5000-3.jpg', '/image/EG5000-4.jpg']
        },
        {
            name: EG3000,
            description: '1년 365일 샐러드를 바로 먹을 수 있는 식물재배기<br />3칸은 키우고, 1칸은 먹고! 부족함 없는 샐러드양 공급',
            imageUrls: ['/image/EG-3000_1.jpg', '/image/EG-3000_2.jpg', '/image/EG-3000_3.jpg', '/image/EG-3000_4.jpg']
        },
        {
            name: EG2000,
            description: '실내 작은 텃밭, 수경재배식 식물재배기<br />누구나 쉽고 편하게 식물을 키우고 수확하는 Greenery Life.',
            imageUrls: ['/image/EG-2000_1.jpg', '/image/EG-2000_2.jpg', '/image/EG-2000_3.jpg', '/image/EG-2000_4.jpg']
        }
    ]

    const selectedItem = items.find((item: IItem) => item.name === productname);
    const Item = ({ imageUrl }: any) => {
        if (!selectedItem) return <></>;

        return (
            <Paper sx={{ backgroundColor: 'black', height: '400px', borderRadius: 0 }}>
                <img
                    src={imageUrl}
                    alt={`${productname}`}
                    loading="lazy"
                    style={{ width: '100%', height: '400px', objectFit: 'contain' }}
                />
            </Paper >
        )
    };

    return (
        <>
            <Box sx={{ backgroundColor: 'black' }}>
                <Carousel
                    next={(next, active) => setIndexImage(next as number)}
                    prev={(prev, active) => setIndexImage(prev as number)}
                    autoPlay={false}
                    navButtonsAlwaysVisible={true}
                    indicators={false}
                    animation={'slide'}
                    index={indexImage}
                >
                    {selectedItem && selectedItem.imageUrls.map((imageUrl: string) => <Item imageUrl={imageUrl}></Item>)}
                </Carousel>

                {selectedItem && <Box sx={{ color: 'white', backgroundColor: 'black', paddingY: 1, paddingX: 3, height: '100px' }}>
                    <Box sx={{ fontSize: '18px', fontWeight: 'bold', marginY: 1 }}>{productname}</Box>
                    <Box sx={{ fontSize: '14px' }}>
                        <div dangerouslySetInnerHTML={{ __html: selectedItem.description }}></div>
                    </Box>
                </Box>}
            </Box>
            <Container sx={{ padding: '18px' }}>
                <Stack direction="row" spacing="8px">
                    <Box sx={{ width: '25%', cursor: 'pointer' }} onClick={() => setIndexImage(0)}>
                        <Box sx={{ aspectRatio: '1/1', fontSize: 0, backgroundColor: '#F4F4F5', padding: '17px', borderRadius: 4, border: indexImage === 0 ? '2px solid #2AC1BC' : '2px solid white' }}>
                            <img
                                src={selectedItem?.imageUrls[0]}
                                alt={'식물재배기'}
                                loading="lazy"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: '25%', cursor: 'pointer' }} onClick={() => setIndexImage(1)}>
                        <Box sx={{ aspectRatio: '1/1', fontSize: 0, backgroundColor: '#F4F4F5', padding: '17px', borderRadius: 4, border: indexImage === 1 ? '2px solid #2AC1BC' : '2px solid white' }}>
                            <img
                                src={selectedItem?.imageUrls[1]}
                                alt={'식물재배기'}
                                loading="lazy"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: '25%', cursor: 'pointer' }} onClick={() => setIndexImage(2)}>
                        <Box sx={{ aspectRatio: '1/1', fontSize: 0, backgroundColor: '#F4F4F5', padding: '17px', borderRadius: 4, border: indexImage === 2 ? '2px solid #2AC1BC' : '2px solid white' }}>
                            <img
                                src={selectedItem?.imageUrls[2]}
                                alt={'식물재배기'}
                                loading="lazy"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ width: '25%', cursor: 'pointer' }} onClick={() => setIndexImage(3)}>
                        <Box sx={{ aspectRatio: '1/1', fontSize: 0, backgroundColor: '#F4F4F5', padding: '17px', borderRadius: 4, border: indexImage === 3 ? '2px solid #2AC1BC' : '2px solid white' }}>
                            <img
                                src={selectedItem?.imageUrls[3]}
                                alt={'식물재배기'}
                                loading="lazy"
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            />
                        </Box>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}

export default CarouselProductInfo;