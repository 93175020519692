import api from './Api';

export const createMemberSite = async (memberSite: IMemberSite): Promise<IMemberSite> => {
    return api.post("/member/site", { ...memberSite })
        .then((response) => {
            return response.data;
        });
}

export const updateMemberSite = async (memberSite: IMemberSite): Promise<IMemberSite> => {
    return api.put("/member/site", { ...memberSite })
        .then((response) => response.data);
};