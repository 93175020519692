import {
    Box,
    Button,
    Container
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: { xs: '336px' },
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '20px',
    paddingY: 1,
    paddingX: 3
};

interface IProps {
    message: string;
    closeModal: Function;
}

const AuthCodeErrorModal = ({ closeModal, message }: IProps) => {
    return <Container sx={style}>
        <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ marginTop: '32px' }}>
                <Box sx={{ border: "1px solid #B3B4B9", borderRadius: 20, display: 'inline-block', padding: 3 }}>
                    <LockIcon sx={{ fontSize: '70px', color: '#B3B4B9' }} />
                </Box>
            </Box>
            <Box sx={{ fontSize: '18px', fontWeight: 'bold', marginTop: '25px' }}>
                <div dangerouslySetInnerHTML={{ __html: message.replace(/\\n/g, '<br />') }}></div>
            </Box>
            <Box sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                <Button
                    variant="contained"
                    sx={{ width: '160px', height: '42px', fontSize: '14px', color: 'white', backgroundColor: '#2AC1BC', borderRadius: '30px', ":hover": { backgroundColor: '#2AC1BC' } }}
                    onClick={() => closeModal()}>
                    확인
            </Button>
            </Box>
        </Box>
    </Container >
}
export default AuthCodeErrorModal;