import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardMedia,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
  Switch,
  alpha,
} from '@mui/material';

import { getUser } from '../../Services/Token';
import MetaTag from '../../Components/MetaTag/MetaTag';
import DiaryTextInput from '../../Components/TextInput/DiaryTextInput';
import { useContext, useEffect, useRef, useState } from 'react';
import { deleteMyDiaryDetail, getMyDiaryDetailById, modifyMyDiary } from '../../Services/Diary';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ComCodeContext } from '../../Context/ComCode';
import SelectInput from '../../Components/SelectInput/SelectInput';
import dayjs from 'dayjs';
import GrowthDiaryDetailModify from './GrowthDiaryDetailModify';
import { extractDeviceData } from '../GrowthDiaryCreate';
import DeleteDiaryModal from '../GrowthDiary/components/DeleteDiaryModal';
import { getMemberProfile } from '../../Services/Member';

const GrowthDiaryModify = () => {
  const [devicesInfos, setDevicesInfos] = useState<Array<IDeviceItemForm> | undefined>(undefined);

  useEffect(() => {
    if (!getUser()) {
      alert('로그인이 필요합니다 ');
      navigate('/login');
    }

    getMemberProfile()
      .then((res: IProfile) => {
        setDevicesInfos(res?.devicesInfos);
      })
      .catch((err: any) => {
        setDevicesInfos(getUser()?.devicesInfos || []);
        navigate('/login');
      });
  }, []);

  const { comCodeList } = useContext(ComCodeContext);
  const { personalPlantGrowthJournalId } = useParams();
  const navigate = useNavigate();
  const [diaryDetail, setDiaryDetail] = useState<IDiaryItemDetail | undefined>(undefined);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [values, setValues] = useState<IModifyDiaryData>({
    personalPlantGrowthJournalId: Number(personalPlantGrowthJournalId),
    thumbnailUrl: '',
    journalStatus: '',
    startDate: '',
    journalName: '',
    endDate: '',
    plantType: '',
    cultivationMethod: '',
    deviceKey: '',
    deviceId: '',
    deviceStatus: '',
    userId: getUser()?.userId ?? '',
  });
  const fetchMyDiaryDetail = async () => {
    if (!personalPlantGrowthJournalId) return;
    getMyDiaryDetailById(personalPlantGrowthJournalId)
      .then(async (res) => {
        setDiaryDetail(res);

        setValues((prevState: IModifyDiaryData) => ({
          ...prevState,
          thumbnailUrl: res.thumbnailUrl,
          journalStatus: res.journalStatus,
          endDate: res.endDate || '',
          journalName: res.journalName,
          startDate: res.startDate,
          plantType: res.plantType,
          cultivationMethod: res.cultivationMethodNm,
          deviceKey: res.deviceKey,
          deviceId: res.deviceId,
          deviceStatus: res.deviceStatus,
        }));

        setSelectedDate(dayjs(res.startDate));
        res.deviceId && setDeviceIdAndStatus(`name:${res.deviceId},status:${res.deviceStatusNm}`);
        res.deviceId && handlePlantgramToggle();
      })
      .catch(() => {
        setDiaryDetail(undefined);
      });
  };
  const handleRefresh = () => {
    fetchMyDiaryDetail();
  };
  useEffect(() => {
    fetchMyDiaryDetail();
  }, [personalPlantGrowthJournalId]);

  const [deviceIdAndStatus, setDeviceIdAndStatus] = useState<string>('');
  const [selectedDeviceKey, setSelectedDeviceKey] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageDataUrl, setImageDataUrl] = useState<string | null>(null);
  const clickUpdateButton = () => {
    const formData = new FormData();
    if (imageFile) {
      formData.append('file', imageFile);
    }
    const deviceInfo = deviceIdAndStatus.split(',') || [];
    let deviceKey = selectedDeviceKey || values.deviceKey;
    let deviceId = deviceInfo[0]?.split(':')[1] || '';
    let deviceStatus = comCodeList.find((code) => code.cmName === deviceInfo[1]?.split(':')[1])?.['cmCd'] || '';
    if (!togglePlant) {
      deviceKey = '';
      deviceId = '';
      deviceStatus = '';
    }

    const cultivationMethod =
      comCodeList.find((code) => values.cultivationMethod.includes(code.cmName))?.['cmCd'] || '';
    const blob = new Blob([JSON.stringify({ ...values, deviceKey, deviceId, deviceStatus, cultivationMethod })], {
      type: 'application/json',
    });
    formData.append('data', blob);
    modifyMyDiary(formData)
      .then((res: IDiaryItem) => {
        navigate(`/diary/${personalPlantGrowthJournalId}`);
      })
      .catch((err) => {
        alert(`서버 오류가 발생하였습니다. ${err}`);
      });
  };
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setValues({ ...values, ['startDate']: `${date.$y}-${date.$M + 1}-${date.$D}` });
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFile(file);
        setImageDataUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteImage = () => {
    setImageFile(null);
    setImageDataUrl(null);
    setValues((prevState: IModifyDiaryData) => ({
      ...prevState,
      thumbnailUrl: '',
    }));
  };
  const handleChange = (prop: keyof IModifyDiaryData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (prop === 'journalName' && event.target.value.length > 35) {
      return alert('35자 이하로 작성해주세요');
    }

    setValues({ ...values, [prop]: event.target.value });
  };
  const [togglePlant, setTogglePlant] = useState(false);
  const handlePlantgramToggle = () => {
    setTogglePlant(!togglePlant);
  };

  const deleteMyDetail = () => {
    if (!personalPlantGrowthJournalId) return;

    deleteMyDiaryDetail(personalPlantGrowthJournalId)
      .then((res) => {
        alert('삭제되었습니다.');
        navigate('/diary');
      })
      .finally(() => {
        setOpenDeleteModal(false);
      });
  };

  const updateDeviceStatusNm = (
    devicesInfos: Array<{ id: string; deviceId: string; deviceStatusNm: string }>,
    diaryDetail: IDiaryItemDetail | undefined,
  ) => {
    return devicesInfos?.map((device) => {
      if (device.deviceId === diaryDetail?.deviceId) {
        return {
          ...device,
          deviceStatusNm: diaryDetail.deviceStatusNm,
        };
      }
      return device;
    });
  };

  return (
    <Box sx={{ minHeight: 'calc(100vh - 245px)}' }}>
      <MetaTag
        title="에코그램 성장일지로 내 반려식물을 관찰, 기록, 공유 하세요."
        description="나의 멋진 반려식물들을 관찰하고 기록하고 공유할 수 있습니다. 성장일지에 업로드된 이미지로 식물 성장 타임랩스 영상을 자동으로 생성해 줍니다. 전용 디바이스인 ‘플랜트그램'을 이용해 자동으로 내 식물의 사진 촬영 및 온도/습도 정보를 기록할 수 있습니다. 성장일지 포스팅으로 다른 사람과 성장일지를 공유할 수 있어요."
        keywords="반려식물, 성장일지, 플랜트그램, 식물 SNS, 타임랩스, 에코그램, 식물재배기, 온도/습도, 재배 환경 정보"
      />
      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px', borderBottom: '1px solid #F8F9FA' }}>
        <Box sx={{ width: getUser() ? '48px' : '58px' }}></Box>
        <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
          <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
        </Box>
        <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
          {getUser() ? (
            <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img>
          ) : (
            <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>
          )}
        </Box>
      </Stack>
      <Container sx={{ padding: '24px 24px 80px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', position: 'relative' }}>
          <img
            src="/image/back_black.png"
            style={{ width: '12px', height: '25px', cursor: 'pointer' }}
            onClick={() => navigate(-1)}
            alt="back"
          ></img>
          <Box sx={{ fontWeight: 700, fontSize: '18px' }}>성장일지 수정하기</Box>
          <div
            style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }}
            onClick={() => setOpenDeleteModal(true)}
          >
            <img src="/image/delete.png" style={{ width: '16px', height: '18px' }} alt="delete" />
          </div>
        </Box>
        <Box sx={{ marginTop: '25px' }}>
          <DiaryTextInput
            id="diary-name-textInput"
            label="성장일지 이름"
            placeholder="성장일지 이름을 입력해주세요"
            value={values.journalName}
            onChange={handleChange('journalName')}
          />
        </Box>
        <Box sx={{ marginTop: '25px', display: 'flex' }} className="diary-datePicker">
          <FormControl>
            <FormLabel
              id="radio-label"
              sx={{ fontSize: '12px', color: 'black', transform: 'none', '&.Mui-focused': { color: '#2AC1BC' } }}
            >
              날짜 선택
            </FormLabel>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  format="YYYY.MM.DD"
                  value={selectedDate}
                  onChange={handleDateChange}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      outline: 'none',
                    },
                    '& .MuiInputBase-input': {
                      borderRadius: '4px',
                      position: 'relative',
                      backgroundColor: '#EEEEEE',
                      fontSize: '14px',
                      width: '114px',
                      height: '48px',
                      padding: '0px',
                      textAlign: 'center',
                      '&:focus': {
                        boxShadow: `${alpha('#2AC1BC', 0.25)} 0 0 0 0.2rem`,
                        borderColor: '#2AC1BC',
                      },
                    },
                  }}
                />
              </LocalizationProvider>
              <Box
                onClick={handleToggle}
                sx={{
                  backgroundColor: '#2AC1BC',
                  width: '103px',
                  height: '48px',
                  color: '#ffffff',
                  fontSize: '14px',
                  fontWeight: 700,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                날짜 선택
              </Box>
            </Box>
          </FormControl>
        </Box>
        <Box sx={{ marginTop: '25px' }}>
          <FormLabel id="stop-label" sx={{ color: 'black', fontSize: '13px', '&.Mui-focused': { color: '#2AC1BC' } }}>
            성장일지 종료하기
          </FormLabel>
          <Box
            component="button"
            disabled={values.endDate !== ''}
            onClick={() => {
              setValues({ ...values, ['endDate']: dayjs().format('YYYY.MM.DD') });
            }}
            sx={{
              marginTop: '10px',
              backgroundColor: values.endDate !== '' ? '#e1e1e1' : '#2AC1BC',
              width: '120px',
              height: '41px',
              color: values.endDate !== '' ? '#000' : '#ffffff',
              fontSize: '14px',
              fontWeight: values.endDate !== '' ? 400 : 700,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none',
            }}
          >
            종료하기
          </Box>
          <Box
            sx={{
              marginTop: '10px',
              fontSize: '12px',
              fontWeight: 400,
              color: '#000000',
            }}
          >
            <Box>※ 오늘 날짜 기준으로 성장일지가 종료됩니다.</Box>{' '}
            <Box>
              ※ 설정된 플랜트그램 디바이스와의 연결이 자동으로 끊기며, 사진과 환경정보 저장이 중단됩니다. (플랜트그램은
              성장일지와 연결된 기간 동안, 해당 성장일지에 정보가 저장됩니다.)
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <DiaryTextInput
            id="diary-type-textInput"
            label="식물 종류"
            placeholder="식물 종류를 입력해주세요"
            value={values.plantType}
            onChange={handleChange('plantType')}
          />
        </Box>
        <Box sx={{ marginTop: '25px' }}>
          <FormControl>
            <FormLabel
              id="radio-label"
              sx={{ color: 'black', fontSize: '13px', '&.Mui-focused': { color: '#2AC1BC' } }}
            >
              식물재배기 방식
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="radio-label"
              name="culture-radio-group"
              value={values.cultivationMethod}
              onChange={handleChange('cultivationMethod')}
            >
              <FormControlLabel
                value="수경"
                control={<Radio sx={{ '&.Mui-checked': { color: '#2AC1BC' } }} />}
                label={<Box sx={{ color: '#616161', fontSize: '14px', fontWeight: 400 }}>수경재배</Box>}
              />
              <FormControlLabel
                value="토경"
                control={<Radio sx={{ '&.Mui-checked': { color: '#2AC1BC' } }} />}
                label={<Box sx={{ color: '#616161', fontSize: '14px', fontWeight: 400 }}>토경재배</Box>}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box sx={{ marginTop: '25px' }}>
          <FormLabel id="img-label" sx={{ color: 'black', fontSize: '13px', '&.Mui-focused': { color: '#2AC1BC' } }}>
            썸네일 이미지
          </FormLabel>
          {(imageDataUrl || values.thumbnailUrl) && (
            <Card sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                alt="Uploaded Image"
                height="85"
                width="315"
                image={imageDataUrl || values.thumbnailUrl}
              />
              <div style={{ position: 'absolute', top: 5, right: 5 }} onClick={handleDeleteImage}>
                <img src="/image/delete.png" style={{ width: '16px', height: '18px' }} alt="delete" />
              </div>
            </Card>
          )}
          <label htmlFor="upload-button">
            <Box
              sx={{
                marginTop: '15px',
                backgroundColor: '#2AC1BC',
                width: '103px',
                height: '35px',
                color: '#ffffff',
                fontSize: '14px',
                fontWeight: 700,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                float: 'right',
              }}
            >
              파일 업로드
            </Box>
          </label>

          <input
            type="file"
            accept="image/*"
            id="upload-button"
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
        </Box>
        <Box sx={{ marginTop: '80px' }}>
          <FormLabel id="radio-label" sx={{ color: 'black', fontSize: '13px', '&.Mui-focused': { color: '#2AC1BC' } }}>
            플랜트그램 사용
          </FormLabel>
          <Switch checked={togglePlant} onChange={handlePlantgramToggle} />
        </Box>
        {togglePlant && (
          <Box sx={{ marginTop: '10px' }}>
            <SelectInput
              label="연결할 플랜트그램 디바이스 선택"
              placeholder="디바이스 선택"
              isDiarySelect={true}
              value={deviceIdAndStatus}
              onChange={(event: SelectChangeEvent) => setDeviceIdAndStatus(event.target.value)}
              render={() => {
                const deviceInfos = diaryDetail
                  ? updateDeviceStatusNm(devicesInfos || [], diaryDetail)
                  : devicesInfos || [];
                return extractDeviceData(deviceInfos)?.map((data: any, index: number) => (
                  <MenuItem
                    value={`name:${data.deviceId},status:${data.deviceStatusNm}`}
                    onClick={() => setSelectedDeviceKey(data.id)}
                    key={index}
                    sx={{ fontSize: '14px', fontWeight: 700, color: '#000000' }}
                  >
                    디바이스 #{index + 1} ({data.deviceId}) | {data.deviceStatusNm}
                  </MenuItem>
                ));
              }}
            />
            <Box
              sx={{
                marginTop: '10px',
                fontSize: '12px',
                fontWeight: 400,
                color: '#000000',
              }}
            >
              <Box>
                ※ 하나의 플랜트그램 디바이스는 하나의 성장일지와만 연결됩니다. 새로운 성장일지와 연결 시, 기존 연결된
                성장일지와의 연결은 끊어집니다.
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={{ marginTop: '25px' }}>
          <Box sx={{ color: '#000000', fontWeight: 700, fontSize: '18px' }}>
            성장일지 기록 ({diaryDetail?.growthJournalDetails.length})
          </Box>
          {diaryDetail?.growthJournalDetails.map((detail: IGrowthJournalDetails, idx: number) => {
            const [datePart, timePart] = detail.createDate.split(' ');
            return (
              <GrowthDiaryDetailModify
                diaryDetail={diaryDetail}
                detail={detail}
                datePart={datePart}
                timePart={timePart}
                personalPlantGrowthJournalId={personalPlantGrowthJournalId}
                idx={idx}
                handleRefresh={handleRefresh}
              />
            );
          })}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '15px',
            marginTop: '50px',
          }}
        >
          <Box
            onClick={() => navigate(-1)}
            sx={{
              backgroundColor: '#959595',
              width: '120px',
              height: '41px',
              color: '#ffffff',
              fontSize: '14px',
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            취소
          </Box>
          <Box
            onClick={clickUpdateButton}
            sx={{
              backgroundColor: '#2AC1BC',
              width: '120px',
              height: '41px',
              color: '#ffffff',
              fontSize: '14px',
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            저장하기
          </Box>
        </Box>
      </Container>
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="modal-delete-title"
        aria-describedby="modal-delete-description"
      >
        <>
          <DeleteDiaryModal closeModal={() => setOpenDeleteModal(false)} onDelete={deleteMyDetail} title="성장일지" />
        </>
      </Modal>
    </Box>
  );
};

export default GrowthDiaryModify;
