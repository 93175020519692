
export const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("ecogram-client-user") as string);
    return user?.refreshToken;
};

export const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem("ecogram-client-user") as string);
    return user?.accessToken;
};

export const updateLocalAccessToken = (token: string) => {
    let user = JSON.parse(localStorage.getItem("ecogram-client-user") as string);
    user.accessToken = token;
    localStorage.setItem("ecogram-client-user", JSON.stringify(user));
};

export const getUser = () => {
    return JSON.parse(localStorage.getItem("ecogram-client-user") as string);
};

export const setUser = (user: string) => {
    localStorage.setItem("ecogram-client-user", JSON.stringify(user));
};

export const removeUser = () => {
    localStorage.removeItem("ecogram-client-user");
};
