import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUpdateProfileContext, UpdateProfileContext } from '../../../Context/UpdateProfile';
import { updateMember, updateMemberName } from '../../../Services/Member';
import {
    Box,
    Container,
    Button,
    Modal
} from '@mui/material';
import PhoneInput from '../../../Components/TextInput/PhoneInput';
import TextInput from '../../../Components/TextInput/TextInput';
import AuthCodeInput from '../../../Components/TextInput/AuthCodeInput';
import { checkAuthCode, createPhoneAuth } from '../../../Services/PhoneAuth';
import AuthCodeErrorModal from '../../../Components/Modal/AuthCodeErrorModal/AuthCodeErrorModal';
import { getUser } from '../../../Services/Token';
import { getMemberProfile } from '../../../Services/Member';

interface IState {
    userName: string,
    phoneNumber: string,
    securityNumber: string,
}

const BasicInfo = () => {
    const navigate = useNavigate();
    const user = getUser();
    const [values, setValues] = useState<IState>({
        userName: '',
        phoneNumber: '',
        securityNumber: '',
    });
    const [profileForUpdate, setProfileForUpdate] = useState<IProfile | undefined>(undefined);
    const [isPhoneError, setIsPhoneError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [startTimerAuthCode, setStartTimerAuthCode] = useState<boolean>(false);
    const [minutes, setMinutes] = useState<number>(3);
    const [seconds, setSeconds] = useState<number>(0);
    const [authCodeResponse, setAuthCodeResponse] = useState<ICheckAuthCodeResponse>({ result: false, message: '' });
    const [phoneAuthId, setPhoneAuthId] = useState<string>('');
    const [authCodeErrorOpenModal, setAuthCodeErrorOpenModal] = useState<boolean>(false);
    const [completeAuthCode, setCompleteAuthCode] = useState<boolean>(false);
    const [isDisablePhoneNumberInput, setIsDisablePhoneNumberInput] = useState<boolean>(false);
    const [isShowAuthCodeInput, setIsShowAuthCodeInput] = useState<boolean>(false);
    const [isDisableUpdateProfile, setIsDisableUpdateProfile] = useState<boolean>(true);

    useEffect(() => {
        if (!startTimerAuthCode) {
            setMinutes(3);
            setSeconds(0);
            return;
        }

        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds, startTimerAuthCode]);

    useEffect(() => {
        if (!user) return;
        getMemberProfile().then((res: IProfile) => {
            setProfileForUpdate(res);
            setValues({
                userName: res.userName,
                phoneNumber: res.phoneNumber,
                securityNumber: ''
            })
        }).catch((err: any) => {
            console.error('error', err);
            navigate('/login');
        });
    }, [])
    const handleChange = (prop: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const checkPhonenumber = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        var regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/
        setIsPhoneError(!regExp.test(e.target.value));
    }

    const clickUpdateButton = (event: React.MouseEvent<HTMLElement>) => {
        if (values.userName === '') return alert('이름을 입력해주세요.');
        if (isPhoneError) return alert('휴대전화번호를 확인해주세요.');
        if (profileForUpdate?.phoneNumber !== values.phoneNumber) {
            if (authCodeResponse && !authCodeResponse.result) return alert('휴대번호 인증을 해주세요.');

            updateMember(values.userName, values.phoneNumber, phoneAuthId).then((res: IUpdateMember) => {
                navigate('/success-profile')
            }).catch((err) => {
                alert(`서버 오류가 발생하였습니다. ${err}`);
                setErrorMessage(`서버 오류가 발생하였습니다. ${err}`);
            });
        }
        if (profileForUpdate?.userName !== values.userName) {
            updateMemberName(values.userName).then((res: IUpdateMember) => {
                navigate('/success-profile')
            }).catch((err) => {
                alert(`서버 오류가 발생하였습니다. ${err}`);
                setErrorMessage(`서버 오류가 발생하였습니다. ${err}`);
            });
        }
    }

    const clickSendAuthCode = () => {
        createPhoneAuth({ phoneNumber: values.phoneNumber }).then((res: IPhoneAuthCreateResponse) => setPhoneAuthId(res.phoneAuthId));
        setCompleteAuthCode(false);
        setMinutes(3);
        setSeconds(0);
        setAuthCodeResponse({ result: false, message: '' });
        setStartTimerAuthCode(true);
        setIsShowAuthCodeInput(true);
        setIsDisableUpdateProfile(true);
        setIsDisablePhoneNumberInput(true);
    }


    const clickCheckAuthCodeButton = () => {
        checkAuthCode({ id: phoneAuthId, authCode: values.securityNumber }).then((res: ICheckAuthCodeResponse) => {
            if (res.result) {
                setStartTimerAuthCode(false);
                setAuthCodeResponse(res);
                setCompleteAuthCode(true);
                setIsDisableUpdateProfile(false);
            } else {
                setAuthCodeErrorOpenModal(true);
                setAuthCodeResponse(res);
            }
        });
    }

    return <>
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>기본정보 수정</Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>기본 계정 정보를 수정합니다</Box>
            </Box>


            <Box sx={{ textAlign: 'right' }}>
                <Button
                    sx={{ color: '#B3B4B9', textDecorationLine: 'underline' }}
                    onClick={() => navigate('/delete-member')}>회원탈퇴하기</Button>
            </Box>
            <TextInput
                id="security-textInput"
                label="계정 ID"
                placeholder=""
                value={profileForUpdate?.userId as string}
                onChange={() => { }}
            />
            <Box sx={{ marginTop: '25px' }}>
                <Box sx={{ fontSize: '13px' }}>비밀번호</Box>
                <Button sx={{ padding: '8px 16px 6px 16px', marginTop: '8px', borderRadius: 1, fontSize: '13px', height: '48px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}
                    variant="contained" fullWidth onClick={() => navigate('/update-password')}>비밀번호 변경</Button>
                <Box sx={{ marginTop: '10px', color: '#B3B4B9', fontSize: '12px' }}>(마지막 변경일: 2022/06/25)</Box>
            </Box>

            <Box sx={{ marginTop: '25px' }}>
                <TextInput
                    id="security-textInput"
                    label="이름"
                    placeholder="이름을 입력해주세요"
                    value={values.userName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('userName')(e)}
                />
            </Box>

            <Box sx={{ marginTop: '25px' }}>
                <PhoneInput
                    id="id-textInput"
                    label="휴대전화번호"
                    placeholder="휴대전화번호를 입력해 주세요"
                    value={values.phoneNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('phoneNumber')(e)}
                    checkPhonenumber={(e: React.FocusEvent<HTMLInputElement, Element>) => checkPhonenumber(e)}
                    clickSendAuthCode={clickSendAuthCode}
                    error={isPhoneError}
                    disabledPhoneInput={isDisablePhoneNumberInput}
                />
            </Box>

            {isShowAuthCodeInput && <>
                <Box sx={{ marginTop: '25px' }}>
                    <AuthCodeInput
                        id="security-textInput"
                        label="인증번호"
                        placeholder="휴대전화로 전송된 인증번호"
                        value={values.securityNumber}
                        expiredTime={`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
                        clickCheckAuthCodeButton={clickCheckAuthCodeButton}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('securityNumber')(e)}
                        disabledAuthCode={completeAuthCode || (minutes === 0 && seconds === 0)}
                    />
                    <Box sx={{ color: '#2AC1BC', fontSize: '12px', marginTop: '10px' }}>{authCodeResponse.result && authCodeResponse.message}</Box>
                </Box>


                {!authCodeResponse.result && <>
                    <Box sx={{ color: '#B3B4B9', fontSize: '12px', marginTop: '10px' }}>
                        <Box>인증번호는 카카오톡 또는 문자메시지를 확인해 보세요</Box>
                        <Box>인증번호를 받지 못하셨나요?</Box>
                    </Box>
                    <Button
                        sx={{ textDecoration: 'underline', color: '#2AC1BC', fontSize: '12px', paddingLeft: 0, marginTop: '2px' }}
                        disabled={!(minutes === 0 && seconds === 0)}
                        onClick={() => {
                            setIsShowAuthCodeInput(false);
                            setIsDisablePhoneNumberInput(false);
                            setValues({ ...values, securityNumber: '' });
                        }}
                    >인증번호 재전송 하기</Button>
                </>}
            </>}

            {errorMessage && <Box style={{ color: 'rgb(211, 47, 47)', marginTop: '25px' }}>{errorMessage}</Box>}

        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={clickUpdateButton}
            disabled={isDisableUpdateProfile && (profileForUpdate?.userName === values.userName)}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            기본정보 수정하기
        </Button>

        <Modal
            open={authCodeErrorOpenModal}
            onClose={() => setAuthCodeErrorOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <AuthCodeErrorModal closeModal={() => setAuthCodeErrorOpenModal(false)} message={authCodeResponse.message} />
            </>
        </Modal>
    </>
}

export default BasicInfo;