import api from './Api'

export const createPhoneAuth = async (params: { phoneNumber: string }): Promise<IPhoneAuthCreateResponse> => {
    return api.post("/phone/auth", { ...params })
        .then((response) => {
            return response.data;
        });
}


export const checkAuthCode = async (params: ICheckAuthCode): Promise<ICheckAuthCodeResponse> => {
    return api.post("/phone/auth/check", { ...params })
        .then((response) => {
            return response.data;
        });
}
