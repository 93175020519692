import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, FormControl, FormLabel, IconButton, Card, CardMedia, InputLabel, TextField, alpha } from '@mui/material';
import DiaryTextInput from '../Components/TextInput/DiaryTextInput';
import styled from '@emotion/styled';
import { modifyMyDiaryDetails } from '../Services/Diary';

interface IProps {
  closeModal: Function;
  personalPlantGrowthJournalId: string | number | undefined;
  detail: IGrowthJournalDetails;
  handleRefresh: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '360px',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  paddingY: '10px',
  paddingX: '24px',
  paddingBottom: '25px',
};

const AdditionalDiaryModifyModal = ({ closeModal, personalPlantGrowthJournalId, detail, handleRefresh }: IProps) => {
  const [values, setValues] = useState<IGrowthJournalDetails>({
    growthJournalDetailId: Number(detail.growthJournalDetailId),
    journalId: Number(personalPlantGrowthJournalId),
    temperature: detail.temperature || '',
    humidity: detail.humidity || '',
    growthRecord: detail.growthRecord || '',
  });
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [imageDataUrls, setImageDataUrls] = useState<string[]>([]);

  const handleChange = (prop: keyof IGrowthJournalDetails) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSaveMyDiaryDetails = () => {
    const formData = new FormData();
    if (imageFiles) {
      imageFiles.forEach((file) => {
        formData.append('file', file);
      });
    }
    const blob = new Blob([JSON.stringify({ ...values })], {
      type: 'application/json',
    });
    formData.append('data', blob);

    modifyMyDiaryDetails(formData)
      .then((res: IGrowthJournalDetails) => {
        closeModal();
        handleRefresh();
      })
      .catch((err) => {
        alert(`서버 오류가 발생하였습니다. ${err}`);
      });
  };

  const handleImageChange = (e: any) => {
    const files = e.target.files;
    if (files) {
      const newImageFiles = Array.from(files);
      Promise.all(
        newImageFiles.map((file) => {
          return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              resolve(reader.result as string);
            };
            reader.readAsDataURL(file as Blob);
          });
        })
      ).then((dataUrls: string[]) => {
        setImageFiles((prevFiles: any) => [...prevFiles, ...newImageFiles]);
        setImageDataUrls((prevDataUrls) => [...prevDataUrls, ...dataUrls]);
      });
    }
  };

  const handleDeleteImage = (index: number) => {
    const newImageFiles = [...imageFiles];
    const newDataUrls = [...imageDataUrls];
    newImageFiles.splice(index, 1);
    newDataUrls.splice(index, 1);
    setImageFiles(newImageFiles);
    setImageDataUrls(newDataUrls);
  };

  return (
    <Box sx={style}>
      <Grid container spacing={2}>
        <Grid xs={10}>
          <Box sx={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '47px' }}>성장일지 기록 수정</Box>
        </Grid>
        <Grid xs={2} sx={{ textAlign: 'right', paddingRight: '0' }}>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon style={{ fontSize: 30 }} />
          </IconButton>
        </Grid>
      </Grid>
      <Box>
        <FormLabel id="img-label" sx={{ color: 'black', fontSize: '13px', '&.Mui-focused': { color: '#2AC1BC' } }}>
          성장기록 이미지
        </FormLabel>
        <Box sx={{ marginTop: '10px', overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {imageDataUrls.map((dataUrl, index) => (
            <Card
              key={index}
              sx={{
                position: 'relative',
                display: 'inline-block',
                marginRight: '10px',
                width: '100px',
                height: '86px',
              }}
            >
              <CardMedia component="img" alt={`Uploaded Image ${index}`} image={dataUrl} height={'100%'} />
              <div style={{ position: 'absolute', top: 5, right: 5 }} onClick={() => handleDeleteImage(index)}>
                <img src="/image/delete.png" style={{ width: '16px', height: '18px' }} alt="delete" />
              </div>
            </Card>
          ))}
        </Box>

        <label htmlFor="upload-modify-img-button">
          <Box
            sx={{
              marginTop: '15px',
              backgroundColor: '#2AC1BC',
              width: '103px',
              height: '35px',
              color: '#ffffff',
              fontSize: '14px',
              fontWeight: 700,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              float: 'right',
            }}
          >
            파일 업로드
          </Box>
        </label>
        <input
          type="file"
          accept="image/*"
          id="upload-modify-img-button"
          style={{ display: 'none' }}
          onChange={handleImageChange}
          multiple
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '80px',
          gap: '15px',
        }}
      >
        <Box>
          <DiaryTextInput
            id="diary-type-textInput"
            label="온도"
            placeholder="온도(℃)"
            value={values.temperature}
            onChange={handleChange('temperature')}
          />
        </Box>
        <Box>
          <DiaryTextInput
            id="diary-type-textInput"
            label="습도"
            placeholder="습도(%)"
            value={values.humidity}
            onChange={handleChange('humidity')}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: '25px' }}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <InputLabel
            htmlFor="bootstrap-input"
            sx={{ fontSize: '13px', color: 'black', transform: 'none', '&.Mui-focused': { color: '#2AC1BC' } }}
          >
            성장일지 기록
          </InputLabel>
          <DiaryTextAreaInput
            id="diary-textArea"
            placeholder="성장일지 기록을 입력하세요."
            multiline
            minRows={4}
            value={values.growthRecord}
            onChange={handleChange('growthRecord')}
          />
        </FormControl>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '40px',
          gap: '10px',
          fontSize: '14px',
          fontWeight: 700,
          color: '#ffffff',
        }}
      >
        <Box
          sx={{
            width: '30%',
            height: '49px',
            backgroundColor: '#959595',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ':hover': { backgroundColor: '#959595' },
          }}
          onClick={() => closeModal()}
        >
          취소
        </Box>
        <Box
          sx={{
            width: '70%',
            height: '49px',
            backgroundColor: '#2AC1BC',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ':hover': { backgroundColor: '#2AC1BC' },
          }}
          onClick={handleSaveMyDiaryDetails}
        >
          성장일지 기록 수정하기
        </Box>
      </Box>
    </Box>
  );
};
export default AdditionalDiaryModifyModal;
const DiaryTextAreaInput = styled(TextField)(({ theme }: any) => ({
  'label + &': {
    marginTop: '26px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 4,
    fontSize: '12px',
    width: '100%',
    '&.Mui-focused fieldset': {
      boxShadow: `${alpha('#2AC1BC', 0.25)} 0 0 0 0.2rem`,
      border: 'none',
    },
  },
}));
