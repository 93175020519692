import { forwardRef } from 'react';
import {
    Box,
    Button,
    Container,
    IconButton,
    Slide,
    Dialog,
    AppBar,
    Toolbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    termsOfUseOpen: boolean;
    setTermsOfUseOpen: Function;
}

const TermsOfUseDialog = ({ termsOfUseOpen, setTermsOfUseOpen }: IProps) => {
    return <Dialog
        open={termsOfUseOpen}
        onClose={() => setTermsOfUseOpen(false)}
        TransitionComponent={Transition}
        maxWidth="xs"
    >
        <AppBar sx={{ position: 'relative', backgroundColor: 'white', boxShadow: 'none', marginBottom: '60px' }}>
            <Toolbar sx={{ justifyContent: 'end', position: 'absolute', right: 0 }}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setTermsOfUseOpen(false)}
                    aria-label="close"
                    sx={{ color: 'black' }}
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
        <Box sx={{ padding: { xs: '24px', sm: '40px' }, overflowY: 'scroll', border: '1px solid #dedede' }}>
            <Box sx={{ textAlign: 'center' }}>서비스 이용약관</Box>
            <Box sx={{ textAlign: 'right', fontSize: '13px' }}>Ver. 1.0</Box>
            <Box sx={{ textAlign: 'right', fontSize: '13px' }}>2022.11.01</Box>
            <Box sx={{ fontSize: '13px' }}>
                <Box>1. 목적</Box>
                <Box sx={{ marginLeft: '20px' }}>1. 본 이용약관은 (주)클랫폼 (이하 '회사')이 제공하는 온라인 플랫폼 서비스 에코그램(https://ecogram.kr, Mobile App 및 기타 도메인 주소를 통해 제공하는 서비스를 이하 '서비스'라 한다)과 관련하여 회사와 이용자간의 권리와 의무 및 책임사항과 기타 사항을 규정함을 목적으로 합니다.</Box>
                <Box>2. 용어정의</Box>
                <Box sx={{ marginLeft: '20px' }}>1. 이 약관에 사용하는 용어의 정의는 다음과 같으며, 정의되지 않은 용어에 대한 해석은 관계법령 및 서비스별 안내에서 정하는 바에 따릅니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>1. '에코그램': ㈜클랫폼이 제공하는 '서비스'의 브랜드명이며, ㈜클랫폼이 제공하는 컨시어지 서비스(고객이 입력한 정보를 바탕으로 자동으로 고객에게 가장 적합한 정보를 추천해주는 서비스), 전문가 정보 제공 서비스, 컨텐츠 제공 서비스, 홍보 대행 서비스, 컨설팅 서비스, 상품(재화 및 용역 포함)의 판매, 판촉행위 등을 포함한 모든 서비스를 총칭합니다. 이하 전체 '서비스'를 대표하는 브랜드명을 말할 때는 '에코그램'이라 한다.</Box>
                <Box sx={{ marginLeft: '40px' }}>2. '회사': '에코그램 서비스'를 제공하는 주체인 ㈜클랫폼를 말하며 이하 '회사'라 한다.</Box>
                <Box sx={{ marginLeft: '40px' }}>3. '회원': '에코그램 서비스'에 본 약관에 따라 '회원'으로 가입하여 '클랫폼'이 제공하는 '에코그램 서비스'를 이용하는 자를 말합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>4. '비회원': '회원'으로 가입하지 않고 '서비스'를 이용하는 자를 말합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>5. '아이디': '회원'의 식별과 '회원'의 서비스 이용을 위하여 '회원'이 설정하고 회사가 승인하여 회사에 등록된 영문, 숫자, 기호 또는 영문과 숫자와 기호의 조합을 말합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>6. '비밀번호': 회원 여부를 확인하고 비밀을 보호하기 위하여 회원이 스스로가 설정해 회사에 등록한 영문과 숫자, 기호의 조합을 말합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>7. '운영자': 회사에서 제공하는 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 자를 말합니다.</Box>
                <Box>3. 약관의 게시와 효력 및 변경</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회사'는 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소, 전화번호, 전자우편 주소, 사업자번호 등을 이용자가 쉽게 알 수 있도록 사이트 초기 화면에 해당 정보 및 링크를 게시합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회사'는 영업상 사유가 있을 경우 약관을 변경할 수 있으며, 약관을 개정할 경우에는 개정 내용과 적용일자 및 개정 사유를 명시하여 적용일 7일전부터 적용일까지 회원에게 알립니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>3. 본 약관은 제공하는 서비스의 성질에 반하지 않는 한 PC, 스마트폰, 태블릿 및 웹, 앱 등의 다양한 기기와 매체 및 방식을 통해 이용하는 경우에도 동일하게 적용됩니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>4. '회사'가 개정된 약관 적용일 이후에도 '회원'이 '회사'의 서비스를 계속해서 사용하는 경우, 개정된 약관에 동의하는 것으로 봅니다. 개정된 약관에 동의하지 않는 경우 '회원'은 언제든지 서비스 이용계약을 해지할 수 있습니다. 다만 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우 '회사'는 이용계약을 해지할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>5. '회사'는 개별 '서비스'에 대해 별도의 이용약관 및 정책을 둘 수 있으며 개별 서비스에 대하여는 해당 이용약관 및 정책이 우선하여 적용됩니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>6. {`본 약관에 명시되지 아니한 사항은 <전기통신기본법>, <전자거래 기본법>, <정보통신망 이용촉진 및 정보보호 등에 관한 법률>, <전자상거래 등에서의 소비자보호에 관한 법률> 등 관련법의 규정 및 상관례에 따릅니다.`}</Box>
                <Box>4. 이용계약의 체결</Box>
                <Box sx={{ marginLeft: '20px' }}>1. 이용자는 '본 약관' 및 '개인정보취급방침'에 각각 동의를 하고 '회사'가 정한 가입 양식에 따라 회원정보를 정상적으로 기입하여 가입 신청을 하고, '회사'가 승인을 함으로써 '회원' 또는 '전문가 회원' 이용자가 됨과 동시에 이용계약이 성립됩니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회사'는 다음 각 호에 해당하는 신청에 대하여서는 승인하지 않거나 사후에 이용계약을 해지할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>1. 실명이 아니거나 본인의 명의가 아닌 타인의 명의로 서비스를 신청한 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>2. 이전에 약관의 위반 또는 법률의 위반으로 회원자격을 상실한 적이 있는 대표자 또는 사업자인 경우, 단 '회사'의 재가입 승락을 받은 경우에는 예외로 함</Box>
                <Box sx={{ marginLeft: '40px' }}>3. 가입양식에 회원정보를 허위로 기재하거나, 누락, 오기 또는 비정상적인 방법의 사용으로 인하여 회원정보 중 중요한 내용이 입력되지 않거나 인식할 수 없는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>4. 부정한 용도 또는 '회사'가 제공하는 '서비스' 이외의 방식으로 영리를 추구할 목적으로 이용하고자 하는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>5. '회사'의 기술과 설비상 현저한 지장이 있거나 여유가 없는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>6. 기타 '회사'가 정한 가입 신청 요건이 만족되지 않았을 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>7. 회원가입 및 전자상거래상의 계약에 관한 서비스는 만 14세 이상인 자에 한함</Box>
                <Box sx={{ marginLeft: '40px' }}>8. 기타 회원으로 회사 소정의 이용신청요건을 충족하지 못하는 경우</Box>
                <Box sx={{ marginLeft: '20px' }}>3. '회사'는 '회원'이 가입신청 시 필요에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>4. '회사'는 '전문가 회원'이 가입신청 시 필요에 따라 전문기관을 통해 사업자등록증(법인, 개인 포함)에 대한 인증 확인을 요청할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>5. '회사'는 '회원'을 회사정책에 따라 등급별로 구분하여 제공하는 서비스에 차등을 둘 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>6. '회원'은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 '회사'에 대하여 회원정보 수정 등 방법으로 그 변경사항을 알려야 합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>7. '회사'는 '회원'에서 '회사'의 '서비스'와 관련하여 '회사'가 정하는 이용조건 및 약관에 따라 '서비스'를 이용할 수 있는 이용권한만을 부여한 것이며, '회원'은 '회사' 및 '서비스'에 대한 어떠한 권리도 이를 제 3자에게 양도, 판매, 담보제공 등의 방법으로 처분할 수 없습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>8. 법정 대리인의 동의가 필요한 만 14세 미만의 회원가입은 받고 있지 않습니다.</Box>
                <Box>5. 이용계약의 종료</Box>
                <Box sx={{ marginLeft: '20px' }}>1. 회원의 해지</Box>
                <Box sx={{ marginLeft: '40px' }}>1. '회원'은 언제든지 회사에게 해지의사를 통지함으로써 이용계약을 해지할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>2. 이용계약은 '회원'의 해지의사가 회사에 도달한 때에 종료됩니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>3. '회원'이 탈퇴하는 경우 이용하던 아이디(이메일 주소)를 재사용 할 수 없으며 새로운 아이디(이메일)을 사용하는 것을 원칙으로 합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>4. '회원'이 탈퇴하는 경우, '회원'의 계정정보를 제외한 기타 작성한 '게시물'들은 자동으로 삭제되지 않으니 사전에 삭제 확인 후 탈퇴하시기 바랍니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>5. '회원'이 탈퇴하는 경우, 기존에 '회사'가 서비스의 운영, 전시, 전송, 배포, 홍보 등의 목적으로 이용한 '회원'이 기존에 등록한 저작물을 지속적으로 사용하는 것을 승인합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>6. '회원'은 개인정보 변경 메뉴를 통해 개인정보를 열람하고 수정 할 수 있습니다. 다만 서비스 운영에 필요한 아이디(이메일) 정보는 수정이 불가능 합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. 회사의 해지</Box>
                <Box sx={{ marginLeft: '40px' }}>1. 4조 2항에서 정하고 있는 이용계약의 승낙거부사유가 있음이 확인된 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>2. 회원이 회사나 다른 회원 기타 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 행위를 한 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>3. 기타 본 약관에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>4. 1년 이상 서비스를 이용한 적이 없는 경우</Box>
                <Box>6. 개인정보취급 방침</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회사'는 '정보통신망법' 등 관계 법령이 정하는 바에 따라 '회원'의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 '회사'의 개인정보처리방침이 적용됩니다. 다만, '회사'의 공식 사이트 이외의 링크된 사이트에서는 '회사'의 개인정보처리방침이 적용되지 않습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회사'는 수집된 개인정보를 지정한 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용∙제공단계에서 당해 '회원'에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.</Box>
                <Box>7. '회원'의 '아이디' 및 '비밀번호' 관리에 대한 의무</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '아이디'와 '비밀번호'에 관한 관리책임은 '회원'에게 있습니다. '회원'은 자신의 '아이디' 및 '비밀번호'를 제 3자에게 알려주거나 이용하게 해서는 안됩니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회사'는 '회원'의 계정이 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 '회사' 및 '회사'의 운영자로 오인한 우려가 있는 경우 해당 '아이디'의 이용을 제한할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>3. '회원'이 자신의 '아이디' 및 '비밀번호'를 도난 당하거나 제 3자가 사용하고 있음을 인지한 경우에는 바로 '회사'와 '운영자'에게 통보하고 안내에 따라야 합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>4. 제 3항의 경우에 해당 '회원'이 '회사'에 그 사실을 통지하지 않거나, 통지한 경우에도 '회사'의 안내에 따르지 않아 발생한 불이익에 대하여 '회사'는 책임지지 않습니다.</Box>
                <Box>8. '회사'의 의무</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회사'는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화 용역을 제공하는데 최선을 다하여야 합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회사'는 이용자가 안전하게 '서비스'를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>3. '회사'는 서비스이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>4. '회사'는 서비스 이용과 관련하여 '회원'으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. '회원'이 제기한 의견이나 불만사항에 대해서는 이메일 등을 통하여 '회원'에게 처리과정 및 결과를 전달합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>5. '회사'는 '회원'의 개인정보를 본인의 승낙 없이 타인에게 누설하거나 배포하지 않습니다. 단, 전기통신관련법령 등 관계 법령에 의하여 관련 국가기관의 요구가 있는 경우에는 관계 법령에 따라 제공 될 수 있습니다.</Box>
                <Box>9. '회원' 및 이용자의 의무</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회원' 및 이용자는 다음 행위를 하여서는 아니되며, 위반시 이용계약의 해지 및 차단 사유가 될 수 있습니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>1. 신청 또는 변경시 허위 내용의 등록</Box>
                <Box sx={{ marginLeft: '40px' }}>2. 타인의 정보 도용</Box>
                <Box sx={{ marginLeft: '40px' }}>3. '회사'가 게시한 정보의 비정상적인 방법을 통한 변경 행위</Box>
                <Box sx={{ marginLeft: '40px' }}>4. '회사'가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</Box>
                <Box sx={{ marginLeft: '40px' }}>5. '회사'와 기타 제 3자의 저작권 등 지적재산권에 대한 침해</Box>
                <Box sx={{ marginLeft: '40px' }}>6. '회사' 및 기타 제 3자의 명예를 손상시키거나 업무를 방해하는 행위</Box>
                <Box sx={{ marginLeft: '40px' }}>7. 외설 또는 폭력적인 메시지, 화상, 음성 등 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위</Box>
                <Box sx={{ marginLeft: '40px' }}>8. '회사'의 동의 없이 사적인 영리를 목적으로 '회사'의 '서비스'를 부정 사용하는 행위</Box>
                <Box sx={{ marginLeft: '40px' }}>9. '회사'의 승인을 받지 않고 다른 '회원'의 개인정보를 수집, 저장, 유포, 게시를 하는 행위</Box>
                <Box sx={{ marginLeft: '40px' }}>10. '회사'의 직원이나 운영자를 사칭하거나 타인의 명의를 도용하여 글을 게시하거나 메시지 또는 메일을 발송하는 행위</Box>
                <Box sx={{ marginLeft: '40px' }}>11. 기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 관계법령에 위배되는 행위</Box>
                <Box sx={{ marginLeft: '40px' }}>12. '회원'은 관계법, 이 약관의 규정, 이용안내 및 '서비스'와 관련하여 공지한 주의사항, '회사'가 통지하는 사항 등을 준수하여야 하며, 기타 '회사'의 업무에 방해되는 행위를 하여서는 안됩니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>13. '회원'은 '서비스' 페이지의 공지사항을 수시로 확인하여야 합니다.</Box>
                <Box>10. 서비스 제공 및 변경</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '서비스'는 원칙적으로 연중무휴, 1일 24시간 제공됩니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회사'는 다음과 같은 '서비스'를 제공합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>1. 식물 재배 관련 정보의 제공, 컨텐츠 정보의 제공, 캠페인 페이지 등 게시판 서비스</Box>
                <Box sx={{ marginLeft: '40px' }}>2. 정보 제공 서비스, 홍보 서비스, 판매</Box>
                <Box sx={{ marginLeft: '40px' }}>3. 기타 '회사'가 정하는 '서비스'</Box>
                <Box sx={{ marginLeft: '20px' }}>3. '회사'는 '서비스'의 제공에 필요한 경우 정기점검을 실시할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>4. '회사'는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 '서비스'를 변경할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>5. '회사'는 무료로 제공되는 '서비스'의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며 이에 대하여 관련법에 특별한 규정이 없는 한 '회원'에게 별도의 보상을 하지 않습니다.</Box>
                <Box>11. '서비스' 변경 및 중단</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회사'는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 '서비스'의 제공을 일시적으로 중단할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. 제 1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제 3자가 입은 손해에 대하여 '회사'의 과실이 없는 경우에는 배상하지 아니합니다.</Box>
                <Box>12. 이용의 제한</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회원'이 본 약관의 의무를 위반하거나 '서비스'의 정상적인 운영을 방해한 경우, 경고, 일시 정지, 회원자격 상실, 접속 차단 등으로 '서비스' 이용을 단계적으로 제한할 수 있습니다. 단, 그 정도가 심각한 경우 '회사'는 해당 이용자에게 법적인 책임 및 피해 보상을 요청할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. 다음 각 호의 사유에 해당하는 경우에는 즉시 경고, 접속차단 또는 '회원' 자격을 일시 정지시킬 수 있습니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>1. 가입 신청 시 허위 내용을 등록한 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>2. 차명계정을 사용하거나 제 3자에 위탁하여 허위게시물 또는 허위 활동기록(평점, 조회수, 댓글, 공유, 스크랩 등)을 발생시켜 부당한 이득을 취하거나 '서비스'의 신뢰성을 해야는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>3. '서비스'를 이용하여 법령 또는 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>4. '서비스'의 운영과 관련하여 근거 없는 사실 또는 허위의 사실을 적시하거나 유포하여 '회사'의 명예를 실추시키고 '서비스'의 신뢰성을 해하는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>5. '서비스'의 리버스 엔지니어링, 디컴파일, 디스어셈블, 코드 인젝션, 하이재킹, 서비스 취약성을 이용한 접속권한 초과행위, 해킹, 크래킹, 어뷰징 등으로 회사의 업무를 방해하는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>6. 기타 '회사'가 판단하여 '서비스'의 운영에 부적절한 활동을 하는 경우.</Box>
                <Box sx={{ marginLeft: '20px' }}>3. '회사'가 '회원'의 자격을 최종 상실시키는 경우에, '회사'는 '회원'의 등록을 말소하고 '회원'의 재가입이 영구히 거부됩니다. 이 경우 '회원'에게 이를 통지하고, '회원' 등록 말소 전 15일 이상의 기간을 정하여 소명할 기회를 부여합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>4. '회원'은 본 조에 따른 이용제한 등에 대해 '회사'가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 '회사'가 인정하는 경우 '회사'는 즉시 '서비스'의 이용제한을 해제합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>5. '회사'가 회원자격을 제한/정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 '회원'의 자격을 영구 상실시킬 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>6. '회사'는 본 조의 사항은 전체 '서비스'에서 유효하나, 세부 '서비스'의 개별 운영정책 및 세부약관이 있는 경우 해당 사항에서 정하는 바를 우선합니다.</Box>
                <Box>13. 정보제공 및 광고게재</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회사'는 '회원'이 '서비스' 이용 중 필요하다고 인정되는 다양한 정보를 공지사항 이나 이메일, 서신우편, SMS(MMS), SNS(카카오톡 등) 등의 방법으로 '회원'에게 제공할 수 있습니다. 다만, '회원'은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 이메일 등에 대해 수신 거절을 할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회사'는 '서비스'의 화면, 이메일 등에 광고를 게재할 수 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>3. '회사'는 '회원'이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주 혹은 다른 '회원'의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은, 전적으로 '회원'과 광고주 혹은 다른 '회원' 간의 문제입니다. 만약, '회원'과 광고주 혹은 다른 '회원' 간에 문제가 발생할 경우에도 '회원'과 광고주 혹은 다른 '회원'이 직접 해결하여야 하며 이와 관련하여 회사는 그 어떠한 책임도 지지 않습니다.</Box>
                <Box>14. 게시물의 저작권 및 권리의 귀속</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회사'가 제공하는 '서비스'와 컨텐츠에 대한 저작권 및 지적재산권은 '회사'에 있습니다. 단, '회사'가 제공하는 '서비스'와 컨텐츠에 '회원' 또는 협약에 의해 제공된 외부의 저작물이 있는 경우 해당 협약에 정의된 자에게 해당하는 저작권 및 지적재산권이 있습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회원'(일반 회원, 전문가 회원 및 이용자 포함)이 '서비스'에 게시한 게시물의 원 저작권은 저작권법에 의해 보호를 받습니다. 단, 제 3자의 저작물이 포함된 경우 해당 저작물의 저작권은 원저작자에게 있으며 '회원'은 이를 명기해야 할 의무가 있습니다. 해당 게시물의 저작권 침해를 비롯한 민∙형사상 모든 책임은 '회원' 본인이 집니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>3. '회원'은 '회사'가 '서비스'의 운영, 전시, 전송, 배포, 홍보 등의 목적으로 저작권법에서 규정하는 공정한 관행에 합치되는 합리적인 범위 내에서 '회원'이 등록한 게시물을 '회원'의 별도의 승인 없이도 무상으로 국내∙외에서 다음 각 호의 목적으로 사용하는 것을 승인합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>1. '서비스'(제 3자가 운영하는 서비스 또는 미디어의 일정 영역 내 입점하여 서비스가 제공되는 경우까지 포함) 내에서 게시물의 복제, 수정, 개조, 전시, 전송, 배포 및 저작물성을 해치지 않는 범위 내에서의 편집 및 수정 가능합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>2. '회사' 또는 관계사가 운영하는 '서비스' 및 연동 서비스에 게시물을 복제, 전송, 전사하는 것. 단, '회원'이 게시물의 복제, 전송, 전시에 반대 의견을 이메일을 통해 서비스 관리자에게 통지할 경우에는 사용을 불가합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>3. '회사'의 '서비스'를 홍보하기 위한 목적으로 미디어, 통신사 등 '서비스' 제휴 파트너에게 게시물의 내용을 제공, 보도 및 방영하게 하는 것. 단, '회사'는 '회원'의 별도 동의 없이 '회원'의 이용자 아이디 외 '회원'의 개인정보를 제공하지 않습니다. 또한 사용권한은 '회사'가 '서비스'를 운영하는 한, '회원'의 탈퇴 또는 제명 이후에도 존속합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>4. 전 항의 규정에도 불구하고, '회사'가 '회원'의 게시물을 전 항 각 호에 기재된 목적 이외에 제 3자에게 게시물을 제공하고 금전적 대가를 지급받는 경우에는 사전에 전화, 이메일 등의 방법으로 '회원'의 동의를 얻고 '회사'는 '회원'에게 별도의 보상을 제공합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>5. '회원'이 서비스에 게시물을 게재하는 것은 다른 '회원'이 게시물을 '서비스' 내에서 사용하거나 회사가 검색결과로 사용하는 것을 승인한 것으로 봅니다. 그리고 스마트폰, 태블릿, PC 등의 '서비스' 이용자(앱 또는 브라우저로 서비스를 비가입 방문한 경우도 포함)가 소프트웨어(예: 앱, 브라우저) 또는 디바이스(예: 스마트폰, 태블릿 PC) 에서 제공하는 기능을 이용하여 게시물을 저장한 후 활용하는 것 또한 승인한 것으로 봅니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>6. '회사'는 '서비스' 운영정책상 또는 '회사'가 운영하는 기타 '서비스' 간의 통합 등을 하는 경우 게시물의 내용을 변경하지 아니하고 게시물의 게재 위치를 변경∙이전하거나 '서비스'간 공유 하여 제공할 수 있습니다. 다만, 게시물의 이전∙변경 또는 공유를 하는 경우에는 사전에 공지합니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>7. '회원'은 본 '서비스'를 이용하여 취득한 정보를 임의 가공, 판매하는 행위 등 '서비스'에 게재된 자료를 상업적으로 사용할 수 없고 아울러 '회사'의 허가 없이 타인에 의해 게시물이 다른 곳에서 사용 또는 인용되는 것을 허용할 수 없습니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>8. '회사'는 '회원'이 본 '서비스'를 통하여 게시, 게재 및 이메일로 전송한 정보의 정확성 및 신뢰도 등 내용에 대해 책임을 지지 않으며 '회원'의 게시물로 인하여 발생하는 민, 형사상의 책임은 전적으로 '회원'이 부담하여야 합니다.</Box>
                <Box>15. 게시물의 정보 관리 및 책임</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회원'의 게시물이 “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 및 “저작권법”등 관련법에 위반되는 내용을 포함하는 경우, '서비스' 운영자는 관련법이 정한 절차에 따라 사전 통지 없이 해당 게시물의 게시 중단을 집행할 수 있고 삭제 등이 필요할 경우는 사전 요청 통해 집행 할 수 있습니다. 단, '회사'는 관련법에 따라 조치를 취하여야 합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회원'의 공개 게시물의 내용이 다음의 경우에 해당될 경우 사전 통지 없이 게시물 노출을 즉시 중단하고 그 게시물에 대한 접근을 제한하는 조치를 취하는 것과 동시에 '회원'에게 자진 삭제를 요청하거나 소명기회를 부여한 후 삭제할 수 있으며, 해당 이용자의 '회원' 자격을 제한하거나, 정지 또는 상실 시킬 수 있습니다. 다만, 긴급한 경우 해당 게시물을 우선 삭제 후 '회원'에게 소명을 요청할 수 있으며 소송 관련해서는 '회원' 본인의 책임이며 '회사'는 그 어떠한 책임도 지지 않습니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>1. 다른 '회원'이나 타인을 비방, 프라이버시를 침해하거나 중상모략으로 명예를 심각하게 손상시키거나 손상시킬 우려가 있는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>2. 타인의 명의 등을 무단으로 도용하여 작성한 내용이거나, 타인이 입력한 정보를 무단으로 위∙변조한 내용인 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>3. 공공질서 및 미풍양속에 위반되는 내용의 게시물에 해당하는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>4. '전문가'의 자격 조건에 불충한 회원의 서비스와 제품에 대한 정보가 담긴 게시물을 서비스 내에 게재할 경우에는 '회사'는 해당 게시물을 사전 통지 없이 삭제할 수 있으며, 이에 대해 '회사'는 어떤 책임도 지지 않습니다.</Box>
                <Box sx={{ marginLeft: '40px' }}>5. '회사'의 안정적인 운영에 지장을 주거나 줄 우려가 있는 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>6. 범죄적 행위에 관련된다고 인정되는 내용일 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>7. '회사'의 지적재산권, 타인의 지적재산권 등 기타 권리를 침해하는 내용인 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>8. '회사'에서 규정한 '서비스'와 관련 없는 내용인 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>9. '회사'에서 규정한 게시기간을 초과한 경우</Box>
                <Box sx={{ marginLeft: '40px' }}>10. 기타 관련법령에 위반된다고 판단되는 경우</Box>
                <Box>16. 책임의 한계와 법적 고지</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회사'는 천재지변 또는 이에 준하는 불가항력으로 인하여 '서비스'를 제공할 수 없는 경우에는 '서비스' 제공에 관한 책임이 면제됩니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회사'는 '회원'및 이용자의 귀책사유로 인한 '서비스' 이용의 장애에 대하여는 책임을 지지 않습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>3. '회사'는 정보를 중개하며 '회원'이 '서비스'와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>4. '회사'는 '회원'간 또는 '회원'과 제 3자 상호간에 거래 등을 하는 경우에 있어서 '회사'는 책임을 지지 않습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>5. '회사'는 무료로 제공되는 '서비스' 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>6. '회사'는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우에는 책임이 면제됩니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>7. '회사'는 이용자의 컴퓨터 및 모바일 환경으로 인하여 발생하는 제반 문제 또는 '회사'의 귀책사유가 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서는 책임지지 않습니다.</Box>
                <Box>17. 분쟁해결</Box>
                <Box sx={{ marginLeft: '20px' }}>1. '회사'는 '회원'이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치 운영합니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. '회사'는 '회원'로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자께 그 사유와 처리일정을 즉시 통보해 드립니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>3. '회사'는 '회원'간에 발생한 전자상거래 분쟁에 관련하여 '회원'의 피해구제신청이 있는 경우에는 공정거래위원회나 대한상사중재원 또는 시 도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</Box>
                <Box>18. 관할법원</Box>
                <Box sx={{ marginLeft: '20px' }}>1. 서비스 이용과 관련하여 '회사'와 '회원' 사이에 분쟁이 발생한 경우, 쌍방간에 분쟁의 해결을 위해 성실히 협의한 후가 아니면 제소할 수 없습니다.</Box>
                <Box sx={{ marginLeft: '20px' }}>2. 본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 '회사'의 주소지를 관할하는 지방 법원을 합의관할로 지정하여 대한민국 법령에 준거하여 소송을 진행합니다.</Box>
            </Box>
        </Box>

        <Button
            variant="contained"
            fullWidth
            onClick={() => setTermsOfUseOpen(false)}
            sx={{ borderRadius: 0, fontSize: '16px', padding: '17px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            닫기
            </Button>
    </Dialog >
}

export default TermsOfUseDialog;