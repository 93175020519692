import React, { MouseEvent, useContext, useEffect, useState } from 'react';
import { Box, Button, Container, Menu, MenuItem, Stack, TextField } from '@mui/material';
import { getUser } from '../../Services/Token';
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AnimationGifDown from '../../Components/AnimationGif/AnimationGifDown';
import AreaCharts from '../../Components/AreaCharts/AreaCharts';
import { deletePostingDetail, getGrowthPostingDetail, postCommentSave } from '../../Services/GrowthPosting';
import AreaBox from './../../Components/AreaBox/AreaBox';
import { ComCodeContext } from '../../Context/ComCode';
import { getLikeAdd, getLikeDel, getLikeList } from '../../Services/LikeState';
import MetaTag from '../../Components/MetaTag/MetaTag';

const GrowthPostingDetails = () => {
  const path_name = location.pathname.split('/');
  const posting_number = location.pathname.split('/')[3];
  const navigate = useNavigate();

  const [likeList, setLikeList] = useState<Array<IlikeInfo>>([]);
  const [recordToggle, setRecordToggle] = useState<boolean>(false);

  const [detailData, setDetailData] = useState<IGrowthPostingDetail>({
    cultivationMethod: '',
    cultivationMethodNm: '',
    endDate: '',
    growthJournalDetails: [],
    growthJournalPostsId: 0,
    journalId: 0,
    journalStatus: '',
    journalStatusNm: '',
    likeCount: 0,
    movieUrl: '',
    plantType: '',
    postCommentInfos: [],
    postContent: '',
    postEndDate: '',
    postStartDate: '',
    postThumbnailUrl: '',
    postTitle: '',
    startDate: '',
    totalCommentCnt: 0,
    userId: '',
    viewCount: 0,
  });
  const [commentInfo, setCommentInfo] = useState<Array<IPostCommentInfos>>([]);
  const [comment, setComment] = useState<string>('');
  const [depCommentToggle, setDepCommentToggle] = useState<Array<any>>([]);
  const [depCommentSelect, setDepCommentSelect] = useState<number>(0);

  const [journalList, setJournalList] = useState<Array<IGrowthJournalDetails>>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let isFirst: boolean;

  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
    navigator.clipboard.writeText(location.href);
    alert('클립보드에 복사되었습니다.');
  };

  const handleCommandSave = (depCommentSelect?: number) => {
    let inert_comment: IPostCommentSave;
    if (comment === '') {
      alert('댓글을 입력해주세요.');
      return;
    }
    const filteredText = comment.replace(/@\w+\s/g, '');

    if (depCommentSelect) {
      inert_comment = {
        postId: Number(detailData.growthJournalPostsId),
        parentId: depCommentSelect,
        commentContent: filteredText,
      };
    } else {
      inert_comment = {
        postId: Number(detailData.growthJournalPostsId),
        commentContent: filteredText,
      };
    }

    postCommentSave(inert_comment).then((res) => {
      setComment('');
      setDepCommentSelect(0);
      getPostingDetail(false);
    });
  };

  useEffect(() => {
    const storedPaths = JSON.parse(localStorage.getItem('PostingVisited') || '[]');

    if (!storedPaths.includes(posting_number)) {
      storedPaths.push(posting_number);
      localStorage.setItem('PostingVisited', JSON.stringify(storedPaths));
      isFirst = true;
    } else {
      isFirst = false;
    }
    if (getUser()) {
      getLikeInfoList();
    }
    getPostingDetail(isFirst);
  }, []);
  useEffect(() => {}, [detailData]);

  const getPostingDetail = (isFirst: boolean) => {
    let list_id = path_name[3];

    getGrowthPostingDetail(list_id, isFirst).then((res) => {
      if (!res) return;
      setDetailData(res);
      let comments: Array<IPostCommentInfos> = res.postCommentInfos ?? [];
      const sortedComments = sortComments(comments);

      setCommentInfo(sortedComments);
      setJournalList(res.growthJournalDetails);
    });
  };
  // 댓글 정렬
  const sortComments = (comments: Array<IPostCommentInfos>) => {
    let organizedComments: Array<IPostCommentInfos> = [];
    let addedIds = new Set();

    const addCommentAndChildren = (commentId: number) => {
      let comment = comments.find((c) => c.journalPostCommentId === commentId);
      if (comment && !addedIds.has(commentId)) {
        organizedComments.push(comment);
        addedIds.add(commentId);
        comments.forEach((c) => {
          if (c.parentId === commentId) {
            addCommentAndChildren(c.journalPostCommentId);
          }
        });
      }
    };

    comments.forEach((comment) => {
      if (comment.parentId == null) {
        addCommentAndChildren(comment.journalPostCommentId);
      }
    });

    return organizedComments;
  };
  const handlePostDelete = (growthJournalPostsId: number) => {
    deletePostingDetail(growthJournalPostsId);
    navigate('/growth');
  };
  const getLikeInfoList = () => {
    getLikeList().then((res: Array<IlikeInfo>) => {
      if (!res) return;
      setLikeList(res);
      getPostingDetail(false);
    });
  };
  const handleAddLike = (growthJournalPostsId: number) => {
    getLikeAdd(growthJournalPostsId).then((res) => {
      getLikeInfoList();
    });
  };
  const handleDelLike = (growthJournalPostsId: number) => {
    getLikeDel(growthJournalPostsId).then((res) => {
      getLikeInfoList();
      getPostingDetail(isFirst);
    });
  };

  const handleDownloadMp4 = (movieUrl: string) => {
    fetch(movieUrl, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.setAttribute('href', url);
        link.setAttribute('download', 'timeLapse.mp4');

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <>
      <MetaTag
        title="에코그램 성장일지로 내 반려식물을 관찰, 기록, 공유 하세요."
        description="나의 멋진 반려식물들을 관찰하고 기록하고 공유할 수 있습니다. 성장일지에 업로드된 이미지로 식물 성장 타임랩스 영상을 자동으로 생성해 줍니다. 전용 디바이스인 ‘플랜트그램'을 이용해 자동으로 내 식물의 사진 촬영 및 온도/습도 정보를 기록할 수 있습니다. 성장일지 포스팅으로 다른 사람과 성장일지를 공유할 수 있어요."
        keywords="반려식물, 성장일지, 플랜트그램, 식물 SNS, 타임랩스, 에코그램, 식물재배기, 온도/습도, 재배 환경 정보"
      />
      <Box>
        <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
          <Box sx={{ width: getUser() ? '48px' : '58px' }}>
            <Box onClick={() => navigate(-1)} sx={{ padding: '10px 0 0 10px', cursor: 'pointer' }}>
              <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
            </Box>
          </Box>
          <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
          </Box>
          <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
            {getUser() ? (
              <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img>
            ) : (
              <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>
            )}
          </Box>
        </Stack>

        <Container sx={{ padding: '24px' }}>
          {detailData &&
            [detailData].map((el: IGrowthPostingDetail) => (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ pb: 1, borderBottom: '1px solid #7B7B7B' }}
                >
                  <div>{el.postTitle}</div>
                  {getUser() && getUser().userId === detailData.userId ? (
                    <>
                      <EditIcon
                        onClick={() => navigate(`/newPosting/edit/${el.growthJournalPostsId}`)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Stack>

                <Box sx={{ pt: 2, pb: 2, borderBottom: '1px solid #7B7B7B' }}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <div style={{ fontSize: '12px' }}>{el.userId}</div>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: '12px',
                          fontWeight: 'normal',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <VisibilityIcon /> <span style={{ margin: '0 10px 0 5px' }}>{el.viewCount}</span>
                      </Box>
                      <Box
                        sx={{
                          fontSize: '12px',
                          fontWeight: 'normal',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {likeList.some((like: IlikeInfo) => like.postId === el.growthJournalPostsId) ? (
                          <FavoriteIcon
                            onClick={() => {
                              handleDelLike(el.growthJournalPostsId);
                            }}
                            style={{ cursor: 'pointer', color: '#e64c3c' }}
                          />
                        ) : (
                          <FavoriteBorderIcon
                            onClick={() => {
                              if (!getUser()) {
                                alert('로그인이 필요합니다.');
                                navigate('/login');
                              } else {
                                handleAddLike(el.growthJournalPostsId);
                              }
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                        <span style={{ margin: '0 5px' }}>{el.likeCount}</span>
                      </Box>
                    </Box>
                  </Stack>

                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <div style={{ fontSize: '12px' }}>{el.postStartDate}</div>
                    <Stack direction="row">
                      <div onClick={(e: any) => handleClick2(e)}>
                        <ShareIcon style={{ cursor: 'pointer' }} />
                      </div>
                      <Menu
                        id="basic-menu2"
                        anchorEl={anchorEl2}
                        open={open2}
                        onClose={handleClose2}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={handleClose2}>공유링크</MenuItem>
                      </Menu>
                      {getUser() && getUser().userId === detailData.userId ? (
                        <>
                          <div onClick={(e: any) => handleClick(e)}>
                            <MoreVertIcon style={{ cursor: 'pointer' }} />
                          </div>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={() => navigate(`/newPosting/edit/${el.growthJournalPostsId}`)}>
                              수정하기
                            </MenuItem>
                            <MenuItem onClick={() => handlePostDelete(el.growthJournalPostsId)}>삭제하기</MenuItem>
                          </Menu>
                        </>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Stack>
                </Box>

                <Box sx={{ pt: 2, pb: 2 }}>
                  <Stack direction="column">
                    {el.movieUrl !== '' ? (
                      <video autoPlay loop style={{ width: '100%' }}>
                        <source src={el.movieUrl} />
                      </video>
                    ) : (
                      <div
                        style={{
                          width: '100%',
                          height: '480px',
                          fontSize: '12px',
                          background: '#dadada',
                          padding: 20,
                          borderRadius: '5px',
                        }}
                      >
                        타임랩스가 없습니다
                      </div>
                    )}

                    <AnimationGifDown movieUrl={el.movieUrl} handleDownload={() => handleDownloadMp4(el.movieUrl)} />

                    <AreaBox apiData={detailData} />
                  </Stack>
                </Box>

                <Box sx={{ pt: 2, pb: 2 }}>
                  <h3>재배 환경 기록</h3>
                  <AreaCharts data={detailData.growthJournalDetails} />
                </Box>

                <Box sx={{ pt: 2, pb: 2 }}>
                  <h3>성장일지기록 (6)</h3>
                  <Stack
                    direction="column"
                    sx={
                      recordToggle
                        ? {
                            fontSize: '12px',
                            marginBottom: '20px',
                            padding: '10px 15px',
                            backgroundColor: '#EEEEEE',
                            borderRadius: '10px',
                            maxHeight: '100%',
                            overflow: 'hidden',
                          }
                        : {
                            fontSize: '12px',
                            marginBottom: '20px',
                            padding: '10px 15px',
                            backgroundColor: '#EEEEEE',
                            borderRadius: '10px',
                            maxHeight: '150px',
                            overflow: 'hidden',
                          }
                    }
                  >
                    {journalList.map((el: IGrowthJournalDetails) => (
                      <Stack direction="row" sx={{ mt: 0.5, mb: 0.5 }}>
                        <span>{el.createDate}</span>
                        <span style={{ marginLeft: '10px' }}>{el.growthRecord}</span>
                      </Stack>
                    ))}
                  </Stack>
                  {journalList.length > 4 ? (
                    <div
                      onClick={() => setRecordToggle(!recordToggle)}
                      style={{
                        width: '100%',
                        textAlign: 'right',
                        fontSize: '12px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      더보기({!recordToggle ? '펼치기' : '접기'})
                    </div>
                  ) : (
                    <></>
                  )}
                </Box>

                <Box sx={{ pt: 2, pb: 2 }}>
                  <div style={{ fontSize: '13px', lineHeight: '1.4' }}>{el.postContent}</div>
                </Box>

                <Box sx={{ pt: 2, pb: 2, borderTop: '2px solid #aeaeae' }}>
                  <h3 style={{ marginTop: 0 }}>댓글({el.totalCommentCnt})</h3>
                  {commentInfo.length > 0 ? (
                    <>
                      {commentInfo.map((item: IPostCommentInfos, index: number) => (
                        <Box sx={{ mb: 2 }}>
                          <Stack direction="column" sx={{ mb: 2 }}>
                            <Stack direction="row" alignContent="center" justifyContent="space-between">
                              <div style={{ fontSize: '14px', fontWeight: 500 }}>{item.userId}</div>
                              <div style={{ fontWeight: 300 }}>{item.createDate}</div>
                            </Stack>
                            <div>{item.commentContent}</div>
                            <div
                              onClick={() => {
                                if (!getUser()) {
                                  alert('로그인이 필요합니다.');
                                  navigate('/login');
                                } else {
                                  if (depCommentSelect === item.journalPostCommentId) {
                                    setDepCommentSelect(0);
                                    setComment('');
                                  } else {
                                    setDepCommentSelect(item.journalPostCommentId);
                                    setComment(`@${item.userId} `);
                                  }
                                }
                              }}
                              style={{
                                cursor: 'pointer',
                                color: '#8B8B8B',
                                textAlign: 'right',
                              }}
                            >
                              답글달기
                            </div>
                          </Stack>
                          {depCommentSelect === item.journalPostCommentId ? (
                            <>
                              <TextField
                                id="outlined-multiline-static"
                                rows={4}
                                multiline
                                placeholder="댓글을 입력하세요."
                                sx={{ width: '100%' }}
                                onChange={(e: any) => setComment(e.target.value)}
                                value={comment}
                              />
                              <div style={{ marginTop: 16, textAlign: 'right' }}>
                                <Button
                                  variant="contained"
                                  sx={{ backgroundColor: '#2AC1BC' }}
                                  onClick={() => handleCommandSave(item.journalPostCommentId)}
                                >
                                  댓글쓰기
                                </Button>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                        </Box>
                      ))}
                      {depCommentSelect === 0 ? (
                        <>
                          <TextField
                            id="outlined-multiline-static"
                            rows={4}
                            multiline
                            placeholder="댓글을 입력하세요."
                            sx={{ width: '100%' }}
                            onChange={(e: any) => setComment(e.target.value)}
                            value={comment}
                          />
                          <div style={{ marginTop: 16, textAlign: 'right' }}>
                            <Button
                              variant="contained"
                              sx={{ backgroundColor: '#2AC1BC' }}
                              onClick={() => {
                                if (!getUser()) {
                                  alert('로그인이 필요합니다.');
                                  navigate('/login');
                                } else {
                                  handleCommandSave();
                                }
                              }}
                            >
                              댓글쓰기
                            </Button>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <>
                      <TextField
                        id="outlined-multiline-static"
                        rows={4}
                        multiline
                        placeholder="댓글을 입력하세요."
                        sx={{ width: '100%' }}
                        onChange={(e: any) => setComment(e.target.value)}
                        value={comment}
                      />
                      <div style={{ marginTop: 16, textAlign: 'right' }}>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: '#2AC1BC' }}
                          onClick={() => {
                            if (!getUser()) {
                              alert('로그인이 필요합니다.');
                              navigate('/login');
                            } else {
                              handleCommandSave();
                            }
                          }}
                        >
                          댓글쓰기
                        </Button>
                      </div>
                    </>
                  )}
                </Box>
              </>
            ))}
        </Container>
      </Box>
    </>
  );
};

export default GrowthPostingDetails;
