import { useNavigate, useParams } from 'react-router-dom'
import {
    Box,
    Container,
    Stack,
    Button
} from '@mui/material';
import { getUser } from '../../Services/Token';
import { useEffect, useState } from 'react';
import { getNewsById } from '../../Services/News';
import MetaTag from '../../Components/MetaTag/MetaTag';
import './content-styles.css';

const NewsDetail = () => {
    const navigate = useNavigate();
    const { newsId } = useParams();
    const [newsDetail, setNewsDetail] = useState<INewsDetail | undefined>(undefined);

    useEffect(() => {
        if (!newsId) return;

        getNewsById(newsId).then((res) => {
            setNewsDetail(res);
        })
    }, [newsId])

    return <>
        <MetaTag />
        <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
            <Box sx={{ width: getUser() ? '48px' : '58px' }}>
                <Box onClick={() => navigate(-1)} sx={{ padding: '10px 0 0 10px', cursor: 'pointer' }}>
                    <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
                </Box>
            </Box>
            <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
            </Box>
            <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
                {getUser() ?
                    <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img> :
                    <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>}
            </Box>
        </Stack>
        <Container sx={{ padding: '15px 24px', borderTop: '1px solid #EBECEE' }}>
            {newsDetail && <>
                <Box sx={{ fontSize: '18px' }}>{newsDetail.title}</Box>
                <Box sx={{ fontSize: '12px', color: '#B3B4B9', marginTop: '10px' }}>새소식: {newsDetail.createDate.split('T')[0]}</Box>
            </>}
        </Container>
        <Container sx={{ padding: '24px', borderTop: '1px solid #EBECEE' }}>
            {newsDetail && <Box className="ck-content"><div id="news-content-wrap" dangerouslySetInnerHTML={{ __html: newsDetail.content }}></div></Box>}
            <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                <img src="/image/logo_header.png" style={{ width: '96px', height: '30px' }} alt="logo"></img>
                <Box sx={{ color: '#B3B4B9', fontSize: '14px', marginTop: '16px' }}>올 하반기에는 다양한 크기의<br />식물재배기를 선보일 예정이니<br />많은 관심 부탁드립니다.</Box>

                <Stack direction="row" spacing={2} sx={{ marginTop: '32px', marginBottom: '12px', marginX: '30px' }}>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{
                            borderRadius: 10,
                            paddingY: 1,
                            width: '100%',
                            backgroundColor: newsDetail?.previousNewsId === 0 ? '#B3B4B9' : '#2AC1BC',
                            borderColor: newsDetail?.previousNewsId === 0 ? '#B3B4B9' : '#2AC1BC',
                            color: newsDetail?.previousNewsId === 0 ? '#B3B4B9' : '#ffffff',
                            fontSize: '13px',
                            '&:hover': {
                                backgroundColor: '#2AC1BC'
                            },
                        }}
                        onClick={() => navigate(`/news/${newsDetail?.previousNewsId}`)}
                        disabled={newsDetail?.previousNewsId === 0}>
                        이전글
                    </Button>
                    <Button
                        variant="contained"
                        component="label"
                        sx={{
                            borderRadius: 10,
                            paddingY: 1,
                            width: '100%',
                            backgroundColor: newsDetail?.nextNewsId === 0 ? '#B3B4B9' : '#2AC1BC',
                            borderColor: newsDetail?.nextNewsId === 0 ? '#B3B4B9' : '#2AC1BC',
                            color: newsDetail?.nextNewsId === 0 ? '#B3B4B9' : '#ffffff',
                            fontSize: '13px',
                            '&:hover': {
                                backgroundColor: '#2AC1BC'
                            },
                        }}
                        onClick={() => navigate(`/news/${newsDetail?.nextNewsId}`)}
                        disabled={newsDetail?.nextNewsId === 0}>

                        다음글
                    </Button>
                </Stack>
            </Box>
        </Container>
    </>
}

export default NewsDetail;