import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Box, FormControl, Stack } from '@mui/material';
import { CustomButton, ItemText } from './styled';
import { getUser } from '../../Services/Token';
import MetaTag from '../../Components/MetaTag/MetaTag';
import { Container } from '@mui/system';
import AreaBox from '../../Components/AreaBox/AreaBox';
import { Delta } from 'quill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TitleBar from './components/TitleBar';
import InputTitleItem from './components/InputTitleItem';
import DateSelectItem from './components/DateSelectItem';
import RadioImageSelectItem from './components/RadioImageSelectItem';
import ThumbnailImgItem from './components/ThumbnailImgItem';
import {
  getGrowthPostingDetail,
  getMydiaryDetailList,
  postGrowthSave,
  postTimeLapse,
  putGrowthPosting,
} from '../../Services/GrowthPosting';

const GrowthPostingCreate = () => {
  let posting_num: string =
    location.pathname.split('/')[2] !== 'edit' ? location.pathname.split('/')[2] : location.pathname.split('/')[3];
  const isEdit = location.pathname.split('/')[3] ? true : false;
  const navigate = useNavigate();
  const quillRef = useRef(null);

  const [editerContents, setEditerContents] = useState<string>('');
  const [isImageFile, setIsImageFile] = useState<File | null>(null);
  const [movieUrl, setMovieUrl] = useState<string>('');
  const [apiData, setApiData] = useState<IDiaryItemDetail>({
    createDate: '',
    cultivationMethod: '',
    cultivationMethodNm: '',
    deviceId: '',
    deviceStatus: '',
    deviceStatusNm: '',
    endDate: '',
    growthJournalDetails: [],
    imageCount: 0,
    journalName: '',
    journalStatus: '',
    journalStatusNm: '',
    personalPlantGrowthJournalId: 0,
    plantGrowthImages: [],
    plantGrowthVideos: [],
    plantType: '',
    relatedPosts: [],
    startDate: '',
    thumbnailUrl: '',
    userId: '',
  });
  const [editData, setEditData] = useState<IGrowthPostingDetail>({
    cultivationMethod: '',
    cultivationMethodNm: '',
    endDate: '',
    growthJournalDetails: [],
    growthJournalPostsId: 0,
    journalId: 0,
    journalStatus: '',
    journalStatusNm: '',
    likeCount: 0,
    movieUrl: '',
    plantType: '',
    postCommentInfos: [],
    postContent: '',
    postEndDate: '',
    postStartDate: '',
    postThumbnailUrl: '',
    postTitle: '',
    startDate: '',
    totalCommentCnt: 0,
    userId: '',
    viewCount: 0,
  });
  const [formData, setFormData] = useState<IPostSaveGrowth>({
    personalPlantGrowthJournalId: 0,
    postThumbnailUrl: '',
    postTitle: '',
    postStartDate: '',
    postEndDate: '',
    postContent: '',
    userId: '',
    fileStorageId: 0,
  });

  const [fileStorageId, setFileStorageId] = useState<number>(0);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (isEdit) {
      getEditPosting(posting_num);
    } else {
      fetchDiaryDetail(posting_num);
    }
  }, []);

  const fetchDiaryDetail = async (posting_num: string) => {
    if (!posting_num) return;
    try {
      const res: IDiaryItemDetail = await getMydiaryDetailList(posting_num);
      setApiData(res);
      if (!isEdit)
        setFormData({
          personalPlantGrowthJournalId: res.personalPlantGrowthJournalId,
          postThumbnailUrl: '',
          postTitle: '',
          postStartDate: '',
          postEndDate: '',
          postContent: '',
          userId: getUser().userId,
          fileStorageId: 0,
        });
    } catch (error) {}
  };

  const getEditPosting = async (posting_num: string) => {
    if (!posting_num) return;
    try {
      const res = await getGrowthPostingDetail(posting_num, false);
      fetchDiaryDetail(res.journalId);
      setEditData(res);
      setFormData({
        ...formData,
        growthJournalPostsId: res.growthJournalPostsId,
        postThumbnailUrl: res.postThumbnailUrl,
        postTitle: res.postTitle,
        postStartDate: res.postStartDate,
        postEndDate: res.postEndDate,
        postContent: res.postContent,
        fileStorageId: res.fileStorageId,
      });
      setMovieUrl(res.movieUrl);
      setEditerContents(res.postContent);
      setKey((prevKey) => prevKey + 1);
    } catch (error) {}
  };

  const handleInputChange = (e: any) => {
    e.preventDefault();
    if (e.target.name === 'movieUrl') {
      setMovieUrl(e.target.value);
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleQuillChange = (
    content: string,
    delta: Delta,
    source: 'user' | 'api',
    editor: ReactQuill.UnprivilegedEditor,
  ) => {
    if (source === 'user') {
      setEditerContents(editor.getText());
    }
  };

  const handleSave = () => {
    let formArray: IPostSaveGrowth;

    if (formData.postTitle === '') {
      alert('포스팅 제목을 입력해주세요.');
      return;
    }
    if (formData.postStartDate === '') {
      alert('시작일을 선택해주세요.');
      return;
    }
    if (formData.postThumbnailUrl === '') {
      alert('썸네일 이미지를 선택해주세요.');
      return;
    }
    if (movieUrl === '' && fileStorageId === 0) {
      alert('타임랩스 영상이 없습니다.');
      return;
    }

    formArray = {
      ...formData,
      personalPlantGrowthJournalId: apiData.personalPlantGrowthJournalId,
      postThumbnailUrl: formData.postThumbnailUrl,
      postTitle: formData.postTitle,
      postStartDate: formData.postStartDate,
      postEndDate: formData.postEndDate,
      postContent: editerContents,
      userId: getUser().userId,
      fileStorageId: fileStorageId,
    };

    const finalFormData = new FormData();
    if (isImageFile) {
      finalFormData.append('file', isImageFile);
    }

    const blob: Blob = new Blob([JSON.stringify({ ...formArray })], {
      type: 'application/json',
    });

    finalFormData.append('data', blob);

    if (isEdit) {
      // 포스팅 업데이트
      putGrowthPosting(finalFormData);
      navigate('/growth');
    } else {
      postGrowthSave(finalFormData)
        .then((res) => {
          navigate('/growth');
        })
        .catch((err) => {
          alert(`서버 오류가 발생하였습니다. ${err}`);
        });

      // 타임리스 영상 세이브
      postTimeLapse(movieUrl);
    }
  };

  return (
    <>
      <MetaTag
        title="에코그램 성장일지로 내 반려식물을 관찰, 기록, 공유 하세요."
        description="나의 멋진 반려식물들을 관찰하고 기록하고 공유할 수 있습니다. 성장일지에 업로드된 이미지로 식물 성장 타임랩스 영상을 자동으로 생성해 줍니다. 전용 디바이스인 ‘플랜트그램'을 이용해 자동으로 내 식물의 사진 촬영 및 온도/습도 정보를 기록할 수 있습니다. 성장일지 포스팅으로 다른 사람과 성장일지를 공유할 수 있어요."
        keywords="반려식물, 성장일지, 플랜트그램, 식물 SNS, 타임랩스, 에코그램, 식물재배기, 온도/습도, 재배 환경 정보"
      />
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ padding: '10px', borderBottom: '1px solid #F8F9FA' }}
        >
          <Box sx={{ width: getUser() ? '48px' : '58px' }}></Box>
          <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
          </Box>
          <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
            {getUser() ? (
              <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img>
            ) : (
              <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>
            )}
          </Box>
        </Stack>

        <Container sx={{ padding: '24px 24px 50px' }}>
          <FormControl sx={{ width: '100%' }}>
            <TitleBar navigate={navigate} />

            <Box sx={{ marginTop: '25px' }}>
              <AreaBox apiData={isEdit ? editData : apiData} />
            </Box>

            <InputTitleItem title={formData.postTitle || ''} handleChange={handleInputChange} />

            <DateSelectItem formData={formData} setFormData={setFormData} />

            <ThumbnailImgItem
              formData={formData}
              setFormData={setFormData}
              setIsImageFile={setIsImageFile}
              handleChange={handleInputChange}
            />

            <RadioImageSelectItem
              isEdit={isEdit}
              moveUrl={apiData.plantGrowthVideos}
              editMoveUrl={editData.movieUrl}
              setMovieUrl={setMovieUrl}
              setFileStorageId={setFileStorageId}
              handleChange={handleInputChange}
            />

            <Stack direction="column" style={{ minHeight: 300, height: '100%' }}>
              <ItemText>포스팅 본문</ItemText>
              <ReactQuill
                key={key}
                className="custom-editor"
                ref={quillRef}
                onChange={handleQuillChange}
                theme="snow"
                defaultValue={editerContents}
              />
            </Stack>
          </FormControl>
          <Stack direction="column" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            <CustomButton variant="contained" type="submit" onClick={handleSave}>
              성장일지 포스팅 올리기
            </CustomButton>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default GrowthPostingCreate;
