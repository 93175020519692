import { createContext, useState } from 'react';

export interface IUpdateProfileContext {
    profileForUpdate: IProfile | undefined;
    setProfileForUpdate: (profileForUpdate: IProfile) => void;
}

const defaultContext: IUpdateProfileContext = {
    profileForUpdate: undefined,
    setProfileForUpdate: () => { }
};

const UpdateProfileContext = createContext(defaultContext);

interface Props {
    children: JSX.Element | Array<JSX.Element>;
}

const UpdateProfileContextProvider = ({ children }: Props) => {
    const [profileForUpdate, setProfileForUpdate] = useState<IProfile | undefined>(undefined);

    return (
        <UpdateProfileContext.Provider
            value={{
                profileForUpdate,
                setProfileForUpdate,
            }}>
            {children}
        </UpdateProfileContext.Provider>
    );
};
export { UpdateProfileContextProvider, UpdateProfileContext };