import * as React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Box } from '@mui/material';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 30,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: 'white',
    border: '1px solid #eaeaf0',
    zIndex: 1,
    color: '#2AC1BC',
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    }),
}));

function ColorlibStepIcon(props: StepIconProps) {
    const { completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <img src="/image/care_step1.png" style={{ width: '31px' }} alt="logo"></img>,
        2: <img src="/image/care_step2.png" style={{ width: '31px' }} alt="logo"></img>,
        3: <img src="/image/care_step3.png" style={{ width: '31px' }} alt="logo"></img>,
        4: <img src="/image/care_step4.png" style={{ width: '31px' }} alt="logo"></img>,
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const steps = [
    { label: 'STEP 1', content: '클리닝' },
    { label: 'STEP 2', content: '물 보충' },
    { label: 'STEP 3', content: '영양 공급' },
    { label: 'STEP 4', content: '모종 교체' }
];

export default function SteperCareService() {
    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel connector={<ColorlibConnector />}>
                {steps.map((step, index: number) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            <Box sx={{ color: '#2AC1BC', fontWeigt: 'bold', fontSize: '12px' }}>{step.label}</Box>
                            <Box sx={{ color: 'black', fontSize: '13px' }}>{step.content}</Box>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}