import { useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Paper,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import { uploadFile, intelligentUploadFile } from '../../Services/AwsS3';
import { createDiagnosis } from '../../Services/Diagnosis';
import PictureGuideDialog from '../../Components/DiagnosisImageGuide/PictureGuideDialog';
import CropGuideDialog from '../../Components/DiagnosisImageGuide/CropGuideDialog';
import CircularProgress from '@mui/material/CircularProgress';
import './index.css';
import MetaTag from '../../Components/MetaTag/MetaTag';

interface CustomizedState {
    plantName: string
}

function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number,
) {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90,
            },
            aspect,
            mediaWidth,
            mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const DiagnosisPhoto = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as CustomizedState
    const { plantName } = state;
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const [crop, setCrop] = useState<Crop>()
    const [imgName, setImgName] = useState<string>('');
    const [imgSrc, setImgSrc] = useState<string>('');
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [pictureGuideOpen, setPictureGuideOpen] = useState<boolean>(false);
    const [cropGuideOpen, setCropGuideOpen] = useState<boolean>(false);
    const imgRef = useRef<HTMLImageElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem('hiddenPictureGuideDialog') !== "1") {
            setPictureGuideOpen(true);
        }
    }, []);

    const handleCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const fileUrl = e.target.files[0];
            const reader = new FileReader()
            setImgName(fileUrl.name)
            reader.readAsDataURL(fileUrl);
            reader.onloadend = (event: any) => {
                if (localStorage.getItem('hiddenCropGuideDialog') !== "1") {
                    setCropGuideOpen(true);
                }
                setImgSrc(reader.result ? reader.result.toString() : '')
            };
        }
    };

    const dataURItoBlob = (dataURI: string) => {
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    const getGuid = () => {
        const _s4 = () => {
            return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
        }
        return _s4() + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + _s4() + _s4();
    }

    const clickSaveButton = async (event: React.MouseEvent<HTMLElement>) => {
        if (!previewCanvasRef.current) return alert('진단 이미지가 없습니다.');

        setIsLoading(true);

        const blob = dataURItoBlob(previewCanvasRef.current.toDataURL("image/jpeg", 0.8));

        var img = document.createElement('img');
        img.src = previewCanvasRef.current.toDataURL("image/jpeg", 0.8);

        const formData = new FormData();
        formData.append("multipartFile", blob, getGuid() + imgName);

        try {
            const intelligentUploadres = await intelligentUploadFile(formData);
            const res = await uploadFile(formData);
            const uploadedImageUrl = res.data;
            const diagnosisParam = {
                imageUrl: uploadedImageUrl,
                plantName: plantName
            }
            const createdDiagnosis = await createDiagnosis(diagnosisParam);

            setIsLoading(false);
            navigate(`/diagnosis/result/${createdDiagnosis.id}`);
        } catch (err: any) {
            setIsLoading(false);
            if (err.response.status === 401 || err.response.status === 403) {
                alert("로그인이 필요합니다.");
                navigate('/login');
            } else {
                return alert(`서버 에러가 발생했습니다.`);
            }
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, 16 / 9))
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1,
                    0,
                )
            }
        },
        100,
        [completedCrop, 1, 0],
    )

    return <>
        <MetaTag
            title="수경재배기에서 키우는 식물의 질병 진단"
            description="키우는 수경재배식 식물이 아프거나 시들때 이미지로 검색해서 물어보세요. 에코그램이 바로 답변해드립니다."
            keywords="식물재배기, 수경재배기, 스마트팜, 수경재배,  식물영양제, 식물LED등, 식물LED, 순환펌프, 통풍관, 온도제어, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등"
        />
        {isLoading ? <>
            {previewCanvasRef.current && <Box sx={{ textAlign: 'center' }}>
                <img src={previewCanvasRef.current.toDataURL("image/jpg")} alt="test" style={{ maxHeight: '50vh', maxWidth: '100%' }} />
            </Box>}
            <Box sx={{ padding: '24px', textAlign: 'center', marginBottom: '100px' }}>
                <Box sx={{ marginTop: '17px', marginBottom: '26px', fontSize: '17px' }}>
                    식물을 진단하고 있습니다.
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            </Box>
            <Button
                variant="contained"
                fullWidth
                onClick={() => {
                    window.location.href = '/diagnosis'
                }}
                sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
            >
                중단하기
        </Button>

        </> : <>
            {!Boolean(imgSrc) ? <>
                <Container sx={{ padding: '24px' }}>
                    <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
                        <img src="/image/dignosis_alert.png" style={{ width: '74px' }} alt="diagnosis"></img>
                        <Box sx={{ fontSize: '20px', marginY: '25px', fontWeight: 'bold' }}>정확한 진단을 위해<br /><span style={{ color: '#2AC1BC', textDecorationLine: 'underline' }} onClick={() => setPictureGuideOpen(true)}>사진 촬영 가이드</span>를 꼭 확인해 주세요</Box>
                        <Button variant="contained" sx={{ padding: '8px 16px 6px 16px', borderRadius: 10, width: '206px', height: '44px', fontSize: '15px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }} onClick={() => setPictureGuideOpen(true)}>가이드 확인하기</Button>
                    </Box>

                    <Paper elevation={24} sx={{ padding: '24px', textAlign: 'center', boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 5%), 0px 16px 24px 2px rgb(0 0 0 / 5%), 0px 6px 30px 5px rgb(0 0 0 / 5%)' }}>
                        <Button component="label" sx={{ display: 'block', ":hover": { backgroundColor: 'white' }, transition: 'none', animation: 'none', color: 'white' }}>
                            <Box>
                                <img src="/image/diagnosis_photo_cir.png" style={{ width: '68px' }} alt="diagnosis"></img>
                            </Box>
                            <Box sx={{ fontSize: '16px', color: '#2AC1BC' }}>사진 촬영하기</Box>
                            <input hidden accept="image/*" type="file" capture="environment" onChange={handleCapture} />
                        </Button>
                    </Paper>
                    <br />
                    <Paper elevation={24} sx={{ padding: '24px', textAlign: 'center', boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 5%), 0px 16px 24px 2px rgb(0 0 0 / 5%), 0px 6px 30px 5px rgb(0 0 0 / 5%)' }}>
                        <Button component="label" sx={{ display: 'block', ":hover": { backgroundColor: 'white' }, transition: 'none', animation: 'none', color: 'white' }}>
                            <Box>
                                <img src="/image/diagnosis_album_cir.png" style={{ width: '68px' }} alt="diagnosis"></img>
                            </Box>
                            <Box sx={{ fontSize: '16px', color: '#2AC1BC' }}>앨범에서 불러오기</Box>
                            <input hidden accept="image/*" type="file" onChange={handleCapture} capture={undefined} />
                        </Button>
                    </Paper>
                </Container>

            </>
                : (<>
                    <Box sx={{ minHeight: '100vh' }}>
                        <Box sx={{ maxHeight: '40vh', textAlign: 'center' }}>
                            <ReactCrop
                                crop={crop}
                                onChange={(_, percentCrop) => setCrop(percentCrop)}
                                onComplete={(c) => setCompletedCrop(c)}
                                aspect={undefined}
                                maxWidth={1500}
                                maxHeight={1500}
                                minWidth={30}
                                minHeight={30}
                                style={{}}
                            >
                                <img
                                    ref={imgRef}
                                    alt="Crop me"
                                    src={imgSrc}
                                    style={{ transform: `scale(1) rotate(0deg)` }}
                                    onLoad={onImageLoad}
                                />
                            </ReactCrop>
                        </Box>
                        <Box sx={{ textAlign: 'center', padding: '40px' }}>
                            <Box sx={{ fontSize: '17px', lineHeight: '28px' }}>
                                진단하려는 식물의 잎을<br />
                            낱장으로 온전히 구분되도록 영역을<br />
                            선택해 주세요
                            </Box>
                            <Button variant="contained" sx={{ marginTop: '25px', padding: '8px 16px 6px 16px', borderRadius: 10, width: '206px', height: '44px', fontSize: '15px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }} onClick={() => setCropGuideOpen(true)}>크롭 가이드 확인하기</Button>
                        </Box>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={clickSaveButton}
                            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
                        >
                            제출하고 진단하기
                            </Button>
                    </Box>
                </>
                )}
            <Box>
                {completedCrop && Boolean(completedCrop) && (
                    <canvas
                        ref={previewCanvasRef}
                        className="canvasClass"
                        style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            width: completedCrop.width,
                            height: completedCrop.height,
                            display: 'none'
                        }}
                    />
                )}
            </Box>
            <PictureGuideDialog pictureGuideOpen={pictureGuideOpen} setPictureGuideOpen={setPictureGuideOpen} />
            <CropGuideDialog cropGuideOpen={cropGuideOpen} setCropGuideOpen={setCropGuideOpen} />
        </>
        }

    </>
}

export default DiagnosisPhoto;