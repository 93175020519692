import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MetaTag from '../../Components/MetaTag/MetaTag';
import { Box, Container, Stack } from '@mui/material';
import { getUser } from '../../Services/Token';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SpeakerIcon from '@mui/icons-material/Speaker';
import { getGrowthPostingList, getMyGrowthJournalList } from '../../Services/GrowthPosting';
import { ComCodeContext } from '../../Context/ComCode';
import { getLikeAdd, getLikeDel, getLikeList } from '../../Services/LikeState';

export const GrowthList = () => {
  const navigate = useNavigate();
  const { comCodeList } = useContext(ComCodeContext);
  const [growthList, setGrowthList] = useState<Array<IGrowthList>>([]);
  const [myGrowthList, setMyGrowthList] = useState<Array<IDiaryItemDetail>>([]);
  const [likeList, setLikeList] = useState<Array<IlikeInfo>>([]);

  useEffect(() => {
    getGrowthList();
    if (getUser()) {
      getLikeInfoList();
      getMyGrowthList();
    }
  }, []);

  const getGrowthList = () => {
    getGrowthPostingList(true)
      .then((res: Array<IGrowthList>) => {
        if (!res) return;
        setGrowthList(res ?? []);
      })
      .catch((error) => {
        console.error('server error', error);
      });
  };

  const getMyGrowthList = () => {
    getMyGrowthJournalList()
      .then((res: Array<IDiaryItemDetail>) => {
        if (!res) return;

        const result = res.sort((a, b) => b.personalPlantGrowthJournalId - a.personalPlantGrowthJournalId) ?? [];
        setMyGrowthList(result);
      })
      .catch((error) => {
        console.error('server error', error);
      });
  };
  const getLikeInfoList = () => {
    getLikeList()
      .then((res: Array<IlikeInfo>) => {
        if (!res) return;
        setLikeList(res ?? []);
      })
      .catch((error) => {
        console.error('server error', error);
      });
  };
  const handleAddLike = (growthJournalPostsId: number) => {
    getLikeAdd(growthJournalPostsId).then((res) => {
      getLikeInfoList();
      getGrowthList();
    });
  };

  const handleDelLike = (growthJournalPostsId: number) => {
    getLikeDel(growthJournalPostsId).then((res) => {
      getLikeInfoList();
      getGrowthList();
    });
  };

  return (
    <>
      <MetaTag
        title="에코그램 성장일지로 내 반려식물을 관찰, 기록, 공유 하세요."
        description="나의 멋진 반려식물들을 관찰하고 기록하고 공유할 수 있습니다. 성장일지에 업로드된 이미지로 식물 성장 타임랩스 영상을 자동으로 생성해 줍니다. 전용 디바이스인 ‘플랜트그램'을 이용해 자동으로 내 식물의 사진 촬영 및 온도/습도 정보를 기록할 수 있습니다. 성장일지 포스팅으로 다른 사람과 성장일지를 공유할 수 있어요."
        keywords="반려식물, 성장일지, 플랜트그램, 식물 SNS, 타임랩스, 에코그램, 식물재배기, 온도/습도, 재배 환경 정보"
      />
      <Box>
        <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
          <Box sx={{ width: getUser() ? '48px' : '58px' }}>
            <Box onClick={() => navigate(-1)} sx={{ padding: '10px 0 0 10px', cursor: 'pointer' }}>
              <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
            </Box>
          </Box>
          <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
          </Box>
          <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
            {getUser() ? (
              <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img>
            ) : (
              <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>
            )}
          </Box>
        </Stack>

        <Box
          sx={{
            backgroundImage: 'url(/image/header_img_01.jpg)',
            backgroundSize: 'cover',
          }}
        >
          <Box
            sx={{
              color: 'white',
              fontWeight: 'bold',
              padding: '50px 0 0 30px',
            }}
          >
            <Box
              sx={{
                fontSize: '26px',
                paddingBottom: '42px',
                lineHeight: '38px',
              }}
            >
              <Box>식물성장일지</Box>
              <Box sx={{ fontSize: '17px', fontWeight: 300 }}>멋진 반려식물들을 관찰하고 기록하고 공유하세요</Box>
            </Box>
          </Box>
        </Box>
        <Container sx={{ padding: '24px' }}>
          <Box>
            <Box
              sx={{
                margin: '0 0 16px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>성장일지 커뮤니티</div>
              <div
                onClick={() => navigate('/growth/posting')}
                style={{
                  color: '#2AC1BC',
                  fontSize: '13px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                더보기
                <NavigateNextIcon style={{ fontSize: '14px' }} />
              </div>
            </Box>
            <Box>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  width: '100%',
                  display: 'flex',
                  overflow: 'auto',
                  whiteSpace: 'nowrap',
                }}
              >
                {growthList.length !== 0 ? (
                  growthList.map((listItem: IGrowthList, index: number) => (
                    <div
                      key={index}
                      style={{
                        position: 'relative',
                        width: '147px',
                      }}
                    >
                      <Box
                        onClick={() => navigate(`/growth/posting/${listItem.growthJournalPostsId}`)}
                        sx={{
                          width: '147px',
                          height: '150px',
                          backgroundImage: `url(${listItem.postThumbnailUrl ? listItem.postThumbnailUrl : ``})`,
                          backgroundSize: 'cover',
                          display: 'inline-block',
                          position: 'relative',
                          cursor: 'pointer',
                        }}
                      ></Box>
                      <Box
                        sx={{
                          fontSize: '16px',
                        }}
                      >
                        <Box
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                          }}
                        >
                          {listItem.postTitle}
                        </Box>
                        <Box
                          sx={{
                            fontSize: '12px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                          }}
                        >
                          {listItem.postContent}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Box
                            sx={{
                              // marginTop: "15px",
                              fontSize: '12px',
                              fontWeight: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <VisibilityIcon /> <span style={{ margin: '0 10px 0 5px' }}>{listItem.viewCount}</span>
                          </Box>
                          <Box
                            sx={{
                              // marginTop: "15px",
                              fontSize: '12px',
                              fontWeight: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {likeList.some((like: IlikeInfo) => like.postId === listItem.growthJournalPostsId) ? (
                              <FavoriteIcon
                                onClick={() => {
                                  handleDelLike(listItem.growthJournalPostsId);
                                }}
                                style={{ cursor: 'pointer', color: '#e64c3c' }}
                              />
                            ) : (
                              <FavoriteBorderIcon
                                onClick={() => {
                                  if (!getUser()) {
                                    alert('로그인이 필요합니다.');
                                    navigate('/login');
                                  } else {
                                    handleAddLike(listItem.growthJournalPostsId);
                                  }
                                }}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                            <span style={{ margin: '0 5px' }}>{listItem.likeCount}</span>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      padding: '20px',
                      width: '100%',
                      backgroundColor: '#dadada',
                      borderRadius: '5px',
                      fontSize: '12px',
                    }}
                  >
                    포스팅이 없습니다. <br />
                    나만의 성장일지를 포스팅 해주세요.
                  </div>
                )}
              </Stack>
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                margin: '4em 0 16px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>내 성장일지</div>
              <div
                onClick={() => {
                  if (!getUser()) {
                    alert('로그인이 필요합니다');
                    navigate('/login');
                  } else {
                    navigate('/diary');
                  }
                }}
                style={{
                  color: '#2AC1BC',
                  fontSize: '13px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                더보기
                <NavigateNextIcon style={{ fontSize: '14px' }} />
              </div>
            </Box>
            <Box sx={{ marginBottom: '16px' }}>
              {getUser() ? (
                myGrowthList.length > 0 ? (
                  myGrowthList.map((item: IDiaryItemDetail) => (
                    <Stack
                      direction="row"
                      onClick={() => navigate(`/diary/${item.personalPlantGrowthJournalId}`)}
                      sx={{ margin: '10px 0', cursor: 'pointer' }}
                    >
                      <Box
                        sx={{
                          width: '120px',
                          height: '120px',
                          marginRight: '16px',
                        }}
                      >
                        <img
                          src={item.thumbnailUrl}
                          alt=""
                          loading="lazy"
                          style={{
                            width: '100%',
                            height: '120px',
                            objectFit: 'cover',
                          }}
                        />
                      </Box>
                      <Box sx={{ width: '230px' }}>
                        <Box
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '17px',
                            marginY: '10px',
                            color: 'black',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                          }}
                        >
                          {item.journalName}
                        </Box>
                        <Box
                          sx={{
                            fontSize: '13px',
                            lineHeight: '18px',
                          }}
                        >
                          {item.startDate} ~ {item.endDate ? item.endDate : item.journalStatusNm}
                        </Box>
                        <Box
                          sx={{
                            fontSize: '13px',
                            lineHeight: '18px',
                          }}
                        >
                          {item.plantType}({item.cultivationMethodNm}) | 사진(
                          {item.imageCount})
                        </Box>

                        <Box
                          sx={{
                            fontSize: '13px',
                            lineHeight: '18px',
                            marginTop: '9px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <SpeakerIcon
                            sx={
                              item.deviceStatus === 'CNCTD'
                                ? { color: '#4ECB71' }
                                : item.deviceStatus === 'DSCNCT'
                                ? { color: '#FC0808' }
                                : item.deviceStatus === 'UNKWN'
                                ? { color: '#C1C1C1' }
                                : { color: '#C1C1C1' }
                            }
                          />
                          <span>{comCodeList.find((el: IComCode) => el.cmCd === item.deviceStatus)?.cmName || ''}</span>
                        </Box>
                      </Box>
                    </Stack>
                  ))
                ) : (
                  <div
                    style={{
                      padding: '20px',
                      backgroundColor: '#dadada',
                      borderRadius: '5px',
                      fontSize: '12px',
                    }}
                  >
                    생성된 성장일지가 없습니다. <br />
                    (플랜트그램을 연결하려면 먼저 성장일지를 생성해 주세요.)
                  </div>
                )
              ) : (
                <div
                  style={{
                    padding: '20px',
                    backgroundColor: '#dadada',
                    borderRadius: '5px',
                    fontSize: '12px',
                  }}
                >
                  생성된 성장일지가 없습니다. <br />
                  (플랜트그램을 연결하려면 먼저 성장일지를 생성해 주세요.)
                </div>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};
