import { useState, useContext } from 'react';
import { MemberContext, IMemberContext } from '../../../Context/Member';
import { useNavigate } from 'react-router-dom'
import { checkUserId, checkPassword } from '../../../Utils/Validation';
import { checkExistUserId } from '../../../Services/Auth';
import {
    Box,
    Container,
    Button,
    Stack
} from '@mui/material';
import TextInput from '../../../Components/TextInput/TextInput';
import PasswordInput from '../../../Components/TextInput/PasswordInput';
import TermsOfUseDialog from '../../../Components/Dialog/TermsOfUseDialog';
import PersonalDataDialog from '../../../Components/Dialog/PersonalDataDialog';

interface State {
    userId: string;
    password: string;
    showPassword: boolean;
    doubleCheckPassword: string;
}

const SignUpFirst = () => {
    const navigate = useNavigate();
    const { setSignUpModel } = useContext<IMemberContext>(MemberContext);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [values, setValues] = useState<State>({
        userId: '',
        password: '',
        showPassword: false,
        doubleCheckPassword: '',
    });
    const [termsOfUseOpen, setTermsOfUseOpen] = useState<boolean>(false);
    const [personalDataOpen, setPersonalDataOpen] = useState<boolean>(false);

    const [errorDoubleCheckPassword, setErrorDoubleCheckPassword] = useState<string>('');

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (prop === 'doubleCheckPassword' || prop === 'password') {
            setErrorMessage(undefined);
        }
        if (prop === 'doubleCheckPassword') {
            event.target.value === values.password ? setErrorDoubleCheckPassword('') : setErrorDoubleCheckPassword('동일하지 않습니다.')
        }
        if (prop === 'password') {
            event.target.value === values.doubleCheckPassword ? setErrorDoubleCheckPassword('') : setErrorDoubleCheckPassword('동일하지 않습니다.')
        }
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const ClickNextButton = async () => {
        const resultValidationCheckUserId = checkUserId(values.userId);
        if (resultValidationCheckUserId !== 'Success') {
            setErrorMessage(resultValidationCheckUserId);
            return;
        }

        const resultValidationCheckPassword = checkPassword(values.password);
        if (resultValidationCheckPassword !== 'Success') {
            setErrorMessage(resultValidationCheckPassword);
            return;
        }

        const isExistUserId = await checkExistUserId(values.userId);
        if (isExistUserId.operationResult === "0") {
            setSignUpModel({ userId: values.userId, password: values.password });
            navigate('/signup-second');
        } else {
            setErrorMessage("이미 등록된 ID가 존재합니다.");
        }
    };

    return <>
        <Container sx={{ padding: '24px', height: 'calc(100vh - 121px)' }}>
            <Stack direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
                <Box>
                    <Box>
                        <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>회원가입 <span style={{ color: '#2AC1BC' }}>(1/2)</span></Box>
                        <Box sx={{ fontSize: '13px', marginTop: '12px' }}>에코그램의 차별화된 서비스를 만나보세요</Box>
                    </Box>
                    <Box sx={{ marginTop: '40px' }}>
                        <TextInput
                            id="id-textInput"
                            label="계정 ID"
                            placeholder="계정 ID를 입력해 주세요"
                            value={values.userId}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('userId')(e)}
                        />
                    </Box>
                    <Box sx={{ marginTop: '25px' }}>
                        <PasswordInput
                            id="password-textInput"
                            label="비밀번호"
                            placeholder="비밀번호"
                            value={values.password}
                            showPassword={values.showPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('password')(e)}
                            handleClickShowPassword={() => handleClickShowPassword()}
                        />
                    </Box>
                    <Box sx={{ marginTop: '25px' }}>
                        <PasswordInput
                            id="check-password-textInput"
                            label="비밀번호 확인"
                            placeholder="비밀번호 확인"
                            value={values.doubleCheckPassword}
                            showPassword={values.showPassword}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('doubleCheckPassword')(e)}
                            handleClickShowPassword={() => handleClickShowPassword()}
                        />
                    </Box>
                    <Box sx={{ color: '#B3B4B9', marginTop: '5px', fontSize: '13px' }}>비밀번호는 8자리 이상에 대문자, 소문자, 숫자, 특수문자가 각각 1개 이상 포함되어야 합니다</Box>
                    {
                        values.doubleCheckPassword === '' ? <></> :
                            errorDoubleCheckPassword !== ''
                                ? <Box sx={{ color: 'rgb(211, 47, 47)', marginTop: '25px', fontSize: '13px' }}>{errorDoubleCheckPassword}</Box>
                                : !(errorMessage && errorMessage !== "") && <Box sx={{ color: '#2AC1BC', marginTop: '25px', fontSize: '13px' }}>비밀번호가 일치합니다.</Box>
                    }
                    {errorMessage && <Box sx={{ color: 'rgb(211, 47, 47)', marginTop: '25px', fontSize: '13px', whiteSpace: 'pre-wrap' }}>{errorMessage}</Box>}
                </Box>
                <Box sx={{ marginTop: '25px', color: '#B3B4B9', textAlign: 'center', fontSize: '15px' }}>
                    <Box>다음버튼을 누름으로 <span style={{ color: 'black', textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => setTermsOfUseOpen(true)}>서비스 이용약관</span>과</Box>
                    <Box><span style={{ color: 'black', textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => setPersonalDataOpen(true)}>개인정보 처리방침</span>에 동의하게 됩니다.</Box>
                </Box>
            </Stack>
        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={ClickNextButton}
            sx={{ padding: '8px 16px 6px 16px', position: 'absolute', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
            disabled={values.password !== values.doubleCheckPassword || values.password === ''}
        >
            다음
        </Button>
        <TermsOfUseDialog termsOfUseOpen={termsOfUseOpen} setTermsOfUseOpen={setTermsOfUseOpen} />
        <PersonalDataDialog personalDataOpen={personalDataOpen} setPersonalDataOpen={setPersonalDataOpen} />
    </>
}


export default SignUpFirst;


