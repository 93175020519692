import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
    Box,
    Container,
    Stack,
    Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CarouselProductInfo from '../../Components/Carousel/CarouselProductInfo';
import {
    EG5000,
    EG3000,
    EG2000
} from '../../Utils/ProductName';
import { getUser } from '../../Services/Token';
import { ReactComponent as ProductPremium } from '../../Svg/product_premium.svg';
import { ReactComponent as ProductUnique } from '../../Svg/product_unique.svg';
import { ReactComponent as ProductCompact } from '../../Svg/product_compact.svg';
import { kakaoChatStart } from '../../Components/KakaoChat/KakaoChat';
import MetaTag from '../../Components/MetaTag/MetaTag';

const ProductInfoDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [productname, setProductname] = useState<string>('');

    useEffect(() => {
        location.pathname.includes(EG5000) ? setProductname(EG5000) :
            location.pathname.includes(EG3000) ? setProductname(EG3000) :
                location.pathname.includes(EG2000) ? setProductname(EG2000) :
                    navigate('/');
    }, [])

    return <>
        {productname === EG5000 ?
            <MetaTag
                title="대량 수확 가능한 양문형 에코그램 식물재배기"
                description="마트, 카페, 레스토랑, 기관, 학교 등 대량재배가 필요한 실내 어느곳에도 에코그램 양문형 식물재배기를 설치하여 사용할 수 있습니다."
                keywords="식물재배기, 수경재배기, 스마트팜, 수경재배, 수경재배모종, 식물영양제, 수경식물영양제, 모종구독, 식물LED등, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유러피안샐러드, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등, 대량재배, 대량수확"
            /> :
            productname === EG3000 ?
                <MetaTag
                    title="실내에서 풍족한 수확이 가능한 에코그램 식물재배기"
                    description="가정, 기관, 유치원 등에서 샐러드 구독이 가능한 에코그램 식물재배기입니다. 365일 신선한 채소 섭취를 원하신다면 단문형 에코그램 식물재배기를 설치하여 사용할 수 있습니다."
                    keywords="식물재배기, 수경재배기, 스마트팜, 수경재배, 수경재배모종, 식물영양제, 수경식물영양제, 모종구독, 식물LED등, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유러피안샐러드, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등, 샐러드, 샐러드구독, 교육용식물재배기"
                /> :
                productname === EG2000 ?
                    <MetaTag
                        title="누구나 신선한 채소를 직접 기르고 수확하는 에코그램 식물재배기"
                        description="가정, 기관, 유치원 등에서 샐러드 구독이 가능한 에코그램 식물재배기입니다. 신선한 채소 섭취를 원하신다면 가정용 에코그램 식물재배기를 설치하여 사용할 수 있습니다."
                        keywords="식물재배기, 수경재배기, 스마트팜, 수경재배, 수경재배모종, 식물영양제, 수경식물영양제, 모종구독, 식물LED등, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유러피안샐러드, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등, 샐러드, 샐러드구독, 교육용식물재배기"
                    /> :
                    <></>
        }
        <Box>
            <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
                <Box sx={{ width: getUser() ? '48px' : '58px' }}>
                    <Box onClick={() => navigate('/product')} sx={{ padding: '10px 0 0 10px', cursor: 'pointer' }}>
                        <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
                    </Box>
                </Box>
                <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                    <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
                </Box>
                <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
                    {getUser() ?
                        <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img> :
                        <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>}
                </Box>
            </Stack>

            {productname && <CarouselProductInfo productname={productname} />}

            <Container sx={{ padding: '24px' }}>
                <Box sx={{ paddingTop: '6px', textAlign: 'center' }}>
                    <Box sx={{ color: '#2AC1BC', fontWeight: 'bold', fontSize: '25px' }}>
                        {productname === EG5000 ? <ProductPremium width="100px" /> :
                            productname === EG3000 ? <ProductUnique width="100px" />
                                : productname === EG2000 ? <ProductCompact width="100px" /> :
                                    <></>
                        }
                    </Box>
                    {productname === EG5000 ? <Box sx={{ color: '#B3B4B9', fontSize: '16px', lineHeight: '25px', marginTop: '25px' }}>실내에서 대량 수확할 수 있는<br />양문형 식물재배기(B2B용)</Box> :
                        productname === EG3000 ? <Box sx={{ color: '#B3B4B9', fontSize: '16px', lineHeight: '25px', marginTop: '25px' }}>풍족한 수확에 적합한 식물재배기</Box> :
                            productname === EG2000 ? <Box sx={{ color: '#B3B4B9', fontSize: '16px', lineHeight: '25px', marginTop: '25px' }}>테이블위의 작은 텃밭</Box> :
                                <></>
                    }

                </Box>
                <Box sx={{ marginTop: '40px', borderBottom: '1px solid #EBECEE', paddingBottom: '24px' }}>
                    {productname === EG5000 ? <>
                        <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '16px' }}>{'<제품설명>'}</Box>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>학교, 카페, 식당, 마트에서 활용</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>수경재배 방식의 자동센서 시스템</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>엽채류 4종 96구, 새싹인삼 1000구 중 택1</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>식물전용 LED, 산소수용해시스템</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>1300mm * 630mm * 2050mm / 261kg</Box></Stack>
                        <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '16px', marginTop: '40px' }}>{'<에코그램 식물재배기 EG-5000 풀패키지>'}</Box>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>에코그램 식물재배기 본체</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>엽채류 모종 98구 / 새싹인삼 1000구 중 선택1</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>엽채류 식물 영양액 세트 (A제, B제)</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>새싹인삼 영양액 세트 (A제, B제, 미생물)</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>포트 + 모종 재배판</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>에코매니저 관리서비스 1회 Free<br />(*정기 관리서비스 선택 옵션 : 월 2회 방문 케어서비스(양액공급/클리닝), 2개월에 1회 모종 교체)</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginTop: '25px', alignItems: 'start' }}><Box sx={{ color: '#B3B4B9' }}>※</Box><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>모종 제공: 엽채류와 새싹인삼 중 선택한 1종류의 모종만 제공</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ alignItems: 'start' }}><Box sx={{ color: '#B3B4B9' }}>※</Box><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>모종은 2달에 한 번 교체 하실 수 있음.</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ alignItems: 'start' }}><Box sx={{ color: '#B3B4B9' }}>※</Box><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>선택한 모종에 땨라 영양액과 포트가 제공 됨.</Box></Stack>
                    </> : productname === EG3000 ? <>
                        <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '16px' }}>{'<제품설명>'}</Box>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>집, 카페, 학교 등에 설치하여 재배</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>수경재배 방식의 자동센서 시스템</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>엽채류 4종, 80구 재배</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>식물전용 LED, 자동순환 Fan</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>공기냉각 시스템, 양액 순환 펌프, 터치 패널</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>850mm * 360mm * 1800mm / 38kg</Box></Stack>
                    </> : productname === EG2000 ? <>
                        <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '16px' }}>{'<제품설명>'}</Box>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>가정에서 식탁, 테이블 위에서 재배</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>수경재배 방식의 자동센서 시스템</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>엽채류 3종, 19구 재배</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>식물전용 LED, 자동순환 Fan, 터치 패널</Box></Stack>
                        <Stack direction="row" alignItems="center" sx={{ marginY: 1, alignItems: 'start' }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px', fontSize: '13px' }}>500mm * 270mm * 340mm / 5.1kg</Box></Stack>
                    </> : <></>
                    }
                </Box>
                <Box sx={{ textAlign: 'center', marginTop: '40px', marginBottom: '16px' }}>
                    <img src="/image/logo_header.png" style={{ width: '97px' }} alt="logo"></img>
                    <Box sx={{ color: '#B3B4B9', fontSize: '14px', marginTop: '16px' }}>궁금한 점이 있으신가요?</Box>
                    <br />
                    <Button onClick={kakaoChatStart} variant="contained" sx={{ padding: '8px 16px 6px 16px', marginTop: '24px', borderRadius: 10, width: '212px', height: '44px', fontSize: '15px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}>채팅으로 문의</Button>
                </Box>
            </Container>
        </Box>
    </>
}

export default ProductInfoDetail