import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

interface IProps {
    closeModal: Function;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    minHeight: '80vh',
    maxHeight: '80vh',
    overflow: 'scroll'
};

const UserAgreements = ({ closeModal }: IProps) => {
    return <Box sx={style}>
        <Box style={{ textAlign: 'center', marginBottom: 30 }}>
            <Box style={{ position: 'absolute', top: 20 }}>
                <IconButton onClick={() => closeModal()}>
                    <CloseIcon style={{ fontSize: 30 }} />
                </IconButton>
            </Box>
            <Box style={{ fontSize: 20, fontWeight: 'bold' }}>이용약관</Box>
        </Box>

        <Box>
            <Box>계정정보 관리 및 고객정보 관리를 위해 필요한 정보  입니다.</Box>
            <Box>제 1조 - [목적]</Box>
            <Box>&emsp;1조 ~~~~~</Box>
            <Box>제 2조 - [용어의 정의]</Box>
            <Box>&emsp;용어는~~~</Box>
            <Box>제 3조 - [서비스 제공자 정보 등의 제공]</Box>
            <Box>&emsp;서비스 제공자 정보제공</Box>
            <Box>제 4조 - [약관의 효력 및 변경]</Box>
            <Box>&emsp;본 약관의 </Box>
            <Box>제 5조 - [이용계약의 체결 및 적용]</Box>
            <Box>&emsp;이용계약</Box>
            <Box>조 6조 - [약관 외 준칙]</Box>
        </Box>
    </Box >
}
export default UserAgreements;