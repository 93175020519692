import api from "./Api";

// 좋아요 리스트
export const getLikeList = () => {
  return api
    .get("/growthJournal/post/like/info")
    .then((res) => {
      console.log("postLikeList", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("postTimeLapse err", err);
    });
};

// 좋아요 활성화
export const getLikeAdd = (growthJournalPostsId: number) => {
  return api
    .get(`/growthJournal/post/like/${growthJournalPostsId}/add`)
    .then((res) => {
      console.log("getLikeAdd", res);
      return res.data;
    })
    .catch((err) => {
      console.log("postTimeLapse err", err);
    });
};

// 좋아요 비활성화
export const getLikeDel = (growthJournalPostsId: number) => {
  return api
    .get(`/growthJournal/post/like/${growthJournalPostsId}/delete`)
    .then((res) => {
      console.log("getLikeLDel", res);
      return res.data;
    })
    .catch((err) => {
      console.log("postTimeLapse err", err);
    });
};
