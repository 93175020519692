import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const FileDownLink = styled(Button)`
  margin-left: auto;
  padding: 0 3px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #000;
  border-radius: 0;
  color: #000;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.2;
`;
