import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { checkPassword } from '../../Utils/Validation';
import { requestLogin } from '../../Services/Auth';
import {
    Box,
    Container,
    Button,
    CircularProgress,
    Modal
} from '@mui/material';
import TextInput from '../../Components/TextInput/TextInput';
import PasswordInput from '../../Components/TextInput/PasswordInput';
import InfoIcon from '@mui/icons-material/Info';
import AuthCodeErrorModal from '../../Components/Modal/AuthCodeErrorModal/AuthCodeErrorModal';

interface IValues {
    userId: string;
    password: string;
    showPassword: boolean;
}

const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [values, setValues] = useState<IValues>({
        userId: '',
        password: '',
        showPassword: false,
    });
    const [authCodeErrorOpenModal, setAuthCodeErrorOpenModal] = useState<boolean>(false);

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleChange = (prop: keyof IValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const ClickLoginButton = async () => {
        const resultValidationCheckPassword = checkPassword(values.password);
        if (resultValidationCheckPassword !== 'Success') {
            setErrorMessage(resultValidationCheckPassword);
            return;
        }

        setErrorMessage("");
        setLoading(true);

        try {
            await requestLogin(values.userId, values.password);
            navigate('/');
            window.location.reload();
        } catch (error: any) {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            setLoading(false);
            setErrorMessage(resMessage);
            setAuthCodeErrorOpenModal(true);
        }
    };

    const getErrorMessage = () => {
        if (errorMessage === 'Bad credentials') {
            return "계정 ID와 패스워드를 확인해 주세요";
        }
        if (errorMessage === 'Network Error') {
            return "서버에 문제가 발생했습니다.";
        }
        return errorMessage;
    }

    return <>
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>로그인</Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>환영합니다</Box>
            </Box>
            <Box sx={{ marginTop: '40px' }}>
                <TextInput
                    id="id-textInput"
                    label="계정 ID"
                    placeholder="계정 ID를 입력해 주세요"
                    value={values.userId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('userId')(e)}
                />
            </Box>
            <Button
                sx={{
                    paddingX: 0,
                    paddingY: '10px',
                    color: '#2AC1BC',
                    textDecoration: 'underline',
                    fontSize: '12px',
                    lineHeight: '20px'
                }}
                onClick={() => navigate('/find-member')}>
                <InfoIcon sx={{ fontSize: '18px', marginRight: '5px' }} />계정 ID를 잊으셨나요?
            </Button>

            <Box sx={{ marginTop: '25px' }}>
                <PasswordInput
                    id="password-textInput"
                    label="비밀번호"
                    placeholder="비밀번호"
                    value={values.password}
                    showPassword={values.showPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('password')(e)}
                    handleClickShowPassword={() => handleClickShowPassword()}
                />
            </Box>
            <Button
                sx={{
                    paddingX: 0,
                    paddingY: '10px',
                    color: '#2AC1BC',
                    textDecoration: 'underline',
                    fontSize: '12px',
                    lineHeight: '20px'
                }}
                onClick={() => navigate('/find-member')}>
                <InfoIcon sx={{ fontSize: '18px', marginRight: '5px' }} />비밀번호를 잊으셨나요?
            </Button>
            {errorMessage && <Box sx={{ color: 'rgb(211, 47, 47)', marginTop: '25px', fontSize: '13px', whiteSpace: 'pre-wrap' }}>{errorMessage}</Box>}

            <Box sx={{ marginTop: '23px' }}>
                <Link to='/signup-first' style={{ textDecoration: 'none', fontSize: '12px' }}>
                    <Box sx={{ textAlign: 'center', border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 2, padding: '16px', color: '#B3B4B9' }}>아직 계정이 없으신가요?
                    <Box style={{ textDecoration: 'underline', color: 'black', display: 'inline-block', paddingLeft: 5 }}>지금 가입하세요</Box>
                    </Box>
                </Link>
            </Box>

        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={ClickLoginButton}
            disabled={loading}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            {loading ?
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box> :
                '로그인'
            }
        </Button>

        <Modal
            open={authCodeErrorOpenModal}
            onClose={() => setAuthCodeErrorOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <AuthCodeErrorModal closeModal={() => setAuthCodeErrorOpenModal(false)} message={getErrorMessage()} />
            </>
        </Modal>
    </>
}

export default Login;