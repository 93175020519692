import api from './Api'

export const getNews = () => {
    return api.get("/news").then((res) => {
        return res.data;
    }).catch((error) => {
        console.error('error', error);
    });
}

export const getNewsById = (id: string): Promise<INewsDetail> => {
    return api.get(`/news/${id}`).then((res) => {
        return res.data;
    }).catch((error) => {
        console.error('error', error);
    });
}