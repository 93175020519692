import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { deleteMember } from '../../../Services/Member';
import { removeUser } from '../../../Services/Token';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    Box,
    Container,
    Button
} from '@mui/material';
import PasswordInput from '../../../Components/TextInput/PasswordInput';
import InfoIcon from '@mui/icons-material/Info';
import TextInput from '../../../Components/TextInput/TextInput';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getUser } from '../../../Services/Token';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2AC1BC',
        },
    },
});

interface State {
    password: string;
    showPassword: boolean;
}

const DeleteMember = () => {
    const navigate = useNavigate();
    const user = getUser();
    const [errorPassword, setErrorPassword] = useState<string>('');
    const [checked, setChecked] = useState<boolean>(false);
    const [values, setValues] = useState<State>({
        password: '',
        showPassword: false,
    });

    const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const clickNextButton = (event: React.MouseEvent<HTMLElement>) => {
        if (!user) return alert('선택된 user 정보가 없습니다.');
        if (!checked) return alert('동의를 체크하셔야 탈퇴가 가능합니다.')

        deleteMember({ userId: user.userId, password: values.password })
            .then((res: IDeleteMember) => {
                removeUser();
                navigate('/success-delete-member');
            }).catch((error) => {
                console.error('error', error);
                setErrorPassword('비밀번호를 확인해주세요.')
            })
    }

    return <>
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>탈퇴 확인</Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>정말로 탈퇴하시겠습니까?<br />탈퇴 후에는 되도릴 수 없습니다.</Box>
            </Box>

            <br /><br />
            {user &&
                <TextInput
                    id="id-textInput"
                    label="계정 ID"
                    placeholder="계정 ID를 입력해 주세요"
                    value={user?.userId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { }}
                />
            }
            <br /><br />
            <PasswordInput
                id="password-textInput"
                label="비밀번호"
                placeholder="비밀번호"
                value={values.password}
                showPassword={values.showPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('password')(e)}
                handleClickShowPassword={() => handleClickShowPassword()}
            />
            <Button
                sx={{
                    paddingX: 0,
                    paddingY: 1,
                    color: '#2AC1BC',
                    textDecoration: 'underline',
                    fontSize: '12px'
                }}
                onClick={() => navigate('/find-member')}>
                <InfoIcon sx={{ fontSize: '18px', marginRight: '5px' }} />비밀번호를 잊으셨나요?
            </Button>
            <br /><br />
            <Box sx={{ border: '1px solid #EBECEE', padding: '15px', borderRadius: 1, fontSize: '13px', lineHeight: '30px', color: '#B3B4B9' }}>
                1. 입력한 모든 정보는 사라집니다.<br />
                    2. 이래도 탈퇴하시려구요?
                </Box>
            <br />
            <ThemeProvider theme={theme}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChangeCheckBox}
                            inputProps={{ 'aria-label': 'controlled' }}
                            sx={{ color: '#EBECEE' }}
                        />}
                    label={<Box sx={{ fontSize: '15px' }}>탈퇴에 따른 모든 주의사항을 숙지하였음</Box>}
                />
            </ThemeProvider>
            <br /><br />
            {errorPassword !== '' && <Box>{errorPassword}</Box>}
        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={clickNextButton}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#B3B4B9', maxWidth: '420px' }}
        >
            탈퇴하기
        </Button>
    </>
}

export default DeleteMember;