import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Stack } from '@mui/material';
import { getUser } from '../../Services/Token';
import MetaTag from '../../Components/MetaTag/MetaTag';
import { getMyDiaryMainList, getMyGrowthPostingList } from '../../Services/Diary';
import DiaryItem from './components/DiaryItem';
import useLoadMore from '../../Hooks/useLoadMore';
import PostItem from './components/PostItem';

const GrowthDiary = () => {
  const navigate = useNavigate();
  const [diaryList, setDiaryList] = useState<any>();
  const [postList, setPostList] = useState<any>();
  const {
    visibleItemLength: diaryItemLengths,
    handleLoadMore: handleLoadMoreDiary,
    canLoadMore: canLoadMoreDiary,
  } = useLoadMore(3, 3, diaryList?.length);

  const {
    visibleItemLength: postItemLengths,
    handleLoadMore: handleLoadMorePost,
    canLoadMore: canLoadMorePost,
  } = useLoadMore(4, 4, postList?.length);

  useEffect(() => {
    if (!getUser()) {
      alert('로그인이 필요합니다 ');
      navigate('/login');
    }

    getMyDiaryMainList()
      .then((res) => {
        const result = res.sort((a, b) => b.personalPlantGrowthJournalId - a.personalPlantGrowthJournalId) ?? [];
        setDiaryList(result);
      })
      .catch(() => {
        setDiaryList([]);
      });

    getMyGrowthPostingList()
      .then((res) => {
        setPostList(res);
      })
      .catch(() => {
        setPostList([]);
      });
  }, []);

  return (
    <Box sx={{ minHeight: 'calc(100vh - 245px)}' }}>
      <MetaTag
        title="에코그램 성장일지로 내 반려식물을 관찰, 기록, 공유 하세요."
        description="나의 멋진 반려식물들을 관찰하고 기록하고 공유할 수 있습니다. 성장일지에 업로드된 이미지로 식물 성장 타임랩스 영상을 자동으로 생성해 줍니다. 전용 디바이스인 ‘플랜트그램'을 이용해 자동으로 내 식물의 사진 촬영 및 온도/습도 정보를 기록할 수 있습니다. 성장일지 포스팅으로 다른 사람과 성장일지를 공유할 수 있어요."
        keywords="반려식물, 성장일지, 플랜트그램, 식물 SNS, 타임랩스, 에코그램, 식물재배기, 온도/습도, 재배 환경 정보"
      />
      <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px', borderBottom: '1px solid #F8F9FA' }}>
        <Box sx={{ width: getUser() ? '48px' : '58px' }}></Box>
        <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
          <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
        </Box>
        <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
          {getUser() ? (
            <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img>
          ) : (
            <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>
          )}
        </Box>
      </Stack>

      <Box>
        <Box
          sx={{
            backgroundImage: 'url(/image/header_img_01.jpg)',
            backgroundSize: 'cover',
          }}
        >
          <Box
            sx={{
              color: 'white',
              fontWeight: 'bold',
              padding: '50px 0 0 30px',
            }}
          >
            <Box
              sx={{
                fontSize: '26px',
                paddingBottom: '42px',
                lineHeight: '38px',
              }}
            >
              <Box>내 식물 성장일지</Box>
              <Box sx={{ fontSize: '17px', fontWeight: 300 }}>나만의 멋진 식물들을 자랑해보세요.</Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Container sx={{ padding: '24px' }}>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                fontSize: '18px',
                fontWeight: '700',
                color: '#000000',
                textAlign: 'left',
              }}
            >
              내 성장일지 ( {diaryList?.length ?? 0} )
            </Box>
            <Box
              onClick={() => navigate('/new-diary')}
              sx={{
                cursor: 'pointer',
                background: '#2AC1BC',
                width: '140px',
                heigth: '30px',
                color: '#ffffff',
                fontWeight: 700,
                fontSize: '14px',
                padding: '10px 0',
                textAlign: 'center',
              }}
            >
              + 성장일지 생성
            </Box>
          </Box>
          {getUser() && diaryList?.length > 0 ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {diaryList?.slice(0, diaryItemLengths).map((item: IDiaryItem) => (
                <Box sx={{ cursor: 'pointer' }} onClick={() => navigate(`/diary/${item.personalPlantGrowthJournalId}`)}>
                  <DiaryItem key={item.personalPlantGrowthJournalId} item={item} />
                </Box>
              ))}
              {canLoadMoreDiary && (
                <Box
                  onClick={handleLoadMoreDiary}
                  sx={{
                    background: '#2AC1BC',
                    width: '120px',
                    heigth: '30px',
                    color: '#ffffff',
                    fontWeight: 700,
                    fontSize: '14px',
                    padding: '10px 0',
                    textAlign: 'center',
                    margin: '40px auto 0',
                  }}
                >
                  더 불러오기
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                background: '#F2F2F2',
                color: '#000000',
                textAlign: 'center',
                padding: '25px 0',
                borderRadius: '14px',
                mt: 4,
              }}
              onClick={() => navigate('/new-diary')}
            >
              <img
                src="/image/new_folder_icon.png"
                style={{
                  width: '49px',
                  height: '49px',
                  display: 'inline-block',
                }}
                alt="no Diary"
              />
              <Box sx={{ mt: 1, fontSize: '14px', fontWeight: '400' }}>생성된 성장일지가 없습니다.</Box>
              <Box sx={{ fontSize: '14px', fontWeight: '400' }}>식물의 성장을 기록하기 위해</Box>
              <Box sx={{ fontSize: '14px', fontWeight: '400' }}>먼저 성장일지를 생성해 주세요.</Box>
            </Box>
          )}
        </Box>
        <Box sx={{ mt: 8, pb: 4 }}>
          <Box
            sx={{
              fontSize: '18px',
              fontWeight: '700',
              color: '#000000',
              textAlign: 'left',
              mb: 2,
            }}
          >
            내 성장일지 포스팅 ( {postList?.length ?? 0} )
          </Box>

          {postList?.length > 0 ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {postList?.slice(0, postItemLengths).map((listItem: IGrowthList, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      cursor: 'pointer',
                      flex: '0 0 calc(50% - 10px)',
                      width: 'calc(50% - 10px)',
                    }}
                    onClick={() => navigate(`/growth/posting/${listItem.growthJournalPostsId}`)}
                  >
                    <PostItem listItem={listItem} />
                  </Box>
                ))}
              </Box>

              {canLoadMorePost && (
                <Box
                  onClick={handleLoadMorePost}
                  sx={{
                    background: '#2AC1BC',
                    width: '120px',
                    heigth: '30px',
                    color: '#ffffff',
                    fontWeight: 700,
                    fontSize: '14px',
                    padding: '10px 0',
                    textAlign: 'center',
                    margin: '40px auto 0',
                  }}
                >
                  더 불러오기
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                background: '#F2F2F2',
                color: '#000000',
                textAlign: 'center',
                padding: '25px 0',
                borderRadius: '14px',
                mt: 4,
              }}
            >
              <img
                src="/image/post_add_icon.png"
                style={{
                  width: '60px',
                  height: '60px',
                  display: 'inline-block',
                }}
                alt="no Diary"
              />
              <Box sx={{ mt: 1, fontSize: '14px', fontWeight: '400' }}>성장일지에서</Box>
              <Box sx={{ fontSize: '14px', fontWeight: '400' }}>모두에게 공유되는</Box>
              <Box sx={{ fontSize: '14px', fontWeight: '400' }}>성장일지 포스팅을</Box>
              <Box sx={{ fontSize: '14px', fontWeight: '400' }}>생성할 수 있습니다.</Box>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default GrowthDiary;
