import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Button,
    Modal
} from '@mui/material';
import PhoneInput from '../../Components/TextInput/PhoneInput';
import TextInput from '../../Components/TextInput/TextInput';
import InfoIcon from '@mui/icons-material/Info';
import AuthCodeInput from '../../Components/TextInput/AuthCodeInput';
import { checkAuthCode, createPhoneAuth } from '../../Services/PhoneAuth';
import { requestResetPassword, checkExistUserIdAndPhoneNumber } from '../../Services/Auth';
import AuthCodeErrorModal from '../../Components/Modal/AuthCodeErrorModal/AuthCodeErrorModal';

interface IState {
    userId: string,
    phoneNumber: string,
    securityNumber: string,
}

const ResetPassword = () => {
    const navigate = useNavigate();
    const [values, setValues] = useState<IState>({
        userId: '',
        phoneNumber: '',
        securityNumber: '',
    });
    const [isPhoneError, setIsPhoneError] = useState<boolean>(false);
    const [startTimerAuthCode, setStartTimerAuthCode] = useState<boolean>(false);
    const [phoneAuthId, setPhoneAuthId] = useState<string>('');
    const [authCodeResponse, setAuthCodeResponse] = useState<ICheckAuthCodeResponse>({ result: false, message: '' });
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [minutes, setMinutes] = useState<number>(3);
    const [seconds, setSeconds] = useState<number>(0);
    const [authCodeErrorOpenModal, setAuthCodeErrorOpenModal] = useState<boolean>(false);
    const [completeAuthCode, setCompleteAuthCode] = useState<boolean>(false);
    const [isDisablePhoneNumberInput, setIsDisablePhoneNumberInput] = useState<boolean>(false);
    const [isShowAuthCodeInput, setIsShowAuthCodeInput] = useState<boolean>(false);
    const [isDisableResetPassword, setIsDisableResetPassword] = useState<boolean>(true);

    useEffect(() => {
        if (!startTimerAuthCode) {
            setMinutes(3);
            setSeconds(0);
            return;
        }

        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds, startTimerAuthCode]);

    const handleChange = (prop: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const checkPhonenumber = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        var regExp = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/
        setIsPhoneError(!regExp.test(e.target.value));
    }

    const clickSendAuthCode = async () => {
        const isExistUserIdAndPhoneNumber = await checkExistUserIdAndPhoneNumber(values.userId, values.phoneNumber);
        if (isExistUserIdAndPhoneNumber.operationResult === "0") {
            setAuthCodeErrorOpenModal(true);
            setErrorMessage("휴대전화번호를 확인해 주세요.");
            return;
        }
        createPhoneAuth({ phoneNumber: values.phoneNumber }).then((res: IPhoneAuthCreateResponse) => setPhoneAuthId(res.phoneAuthId));
        setCompleteAuthCode(false);
        setMinutes(3);
        setSeconds(0);
        setAuthCodeResponse({ result: false, message: '' });
        setStartTimerAuthCode(true);
        setIsShowAuthCodeInput(true);
        setIsDisableResetPassword(true);
        setIsDisablePhoneNumberInput(true);
    }

    const clickCheckAuthCodeButton = () => {
        checkAuthCode({ id: phoneAuthId, authCode: values.securityNumber }).then((res: ICheckAuthCodeResponse) => {
            if (res.result) {
                setStartTimerAuthCode(false);
                setAuthCodeResponse(res);
                setCompleteAuthCode(true);
                setIsDisableResetPassword(false);
            } else {
                setAuthCodeErrorOpenModal(true);
                setErrorMessage(res.message);
                setAuthCodeResponse(res);
            }
        });
    }

    const clickNewPasswordButton = async () => {
        const resResetPassword = await requestResetPassword(values.userId, values.phoneNumber, phoneAuthId);
        if (resResetPassword.operationResult === "1") {
            navigate('/result-reset-password');
        } else {
            alert('서버 에러 발생!' + resResetPassword.operationName);
        }
    }

    return <>
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>비밀번호 재설정</Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>휴대전화 인증을 통해 계정 비밀번호를 재설정 할 수 있습니다</Box>
            </Box>
            <Box sx={{ marginTop: '40px' }}>
                <TextInput
                    id="security-textInput"
                    label="계정 ID"
                    placeholder="계정 ID를 입력해 주세요"
                    value={values.userId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('userId')(e)}
                />
            </Box>

            <Button sx={{ textDecoration: 'underline', color: '#2AC1BC', fontSize: '12px', paddingLeft: 0 }}>
                <InfoIcon sx={{ fontSize: '18px', marginRight: '5px' }} />계정 ID를 잊으셨나요?</Button>

            <Box sx={{ marginTop: '25px' }}>
                <PhoneInput
                    id="id-textInput"
                    label="휴대전화번호"
                    placeholder="휴대전화번호를 입력해 주세요"
                    value={values.phoneNumber}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('phoneNumber')(e)}
                    checkPhonenumber={(e: React.FocusEvent<HTMLInputElement, Element>) => checkPhonenumber(e)}
                    clickSendAuthCode={clickSendAuthCode}
                    error={isPhoneError}
                    disabledPhoneInput={isDisablePhoneNumberInput}
                />
            </Box>
            {isShowAuthCodeInput && <>
                <Box sx={{ marginTop: '25px' }}>
                    <AuthCodeInput
                        id="security-textInput"
                        label="인증번호"
                        placeholder="휴대전화로 전송된 인증번호"
                        value={values.securityNumber}
                        expiredTime={`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
                        clickCheckAuthCodeButton={clickCheckAuthCodeButton}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('securityNumber')(e)}
                        disabledAuthCode={completeAuthCode || (minutes === 0 && seconds === 0)}
                    />
                    <Box sx={{ color: '#2AC1BC', fontSize: '12px', marginTop: '10px' }}>{authCodeResponse.result && authCodeResponse.message}</Box>
                </Box>

                {!authCodeResponse.result && <>
                    <Box sx={{ color: '#B3B4B9', fontSize: '12px', marginTop: '10px' }}>
                        <Box>인증번호는 카카오톡 또는 문자메시지를 확인해 보세요</Box>
                        <Box>인증번호를 받지 못하셨나요?</Box>
                    </Box>
                    <Button
                        sx={{ textDecoration: 'underline', color: '#2AC1BC', fontSize: '12px', paddingLeft: 0, marginTop: '2px' }}
                        disabled={!(minutes === 0 && seconds === 0)}
                        onClick={() => {
                            setIsShowAuthCodeInput(false);
                            setIsDisablePhoneNumberInput(false);
                            setValues({ ...values, securityNumber: '' });
                        }}
                    >인증번호 재전송 하기</Button>
                </>}
            </>}
        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={clickNewPasswordButton}
            disabled={isDisableResetPassword}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            비밀번호 재설정
        </Button>

        <Modal
            open={authCodeErrorOpenModal}
            onClose={() => setAuthCodeErrorOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                <AuthCodeErrorModal closeModal={() => setAuthCodeErrorOpenModal(false)} message={errorMessage} />
            </>
        </Modal>
    </>


}

export default ResetPassword;