import React, { useContext, useEffect, useState } from 'react';
import MetaTag from '../../Components/MetaTag/MetaTag';
import { Box, Container, Stack, listItemSecondaryActionClasses } from '@mui/material';
import { getUser } from '../../Services/Token';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SpeakerIcon from '@mui/icons-material/Speaker';
import { getGrowthPostingList, getPostingPopularList } from '../../Services/GrowthPosting';
import { getLikeAdd, getLikeDel, getLikeList } from '../../Services/LikeState';
import { ComCodeContext } from '../../Context/ComCode';

const GrowthPosting = () => {
  const { comCodeList } = useContext(ComCodeContext);
  const posting_number = location.pathname.split('/')[3];
  const navigate = useNavigate();
  let isFirst: boolean;
  const [popularList, setPopularList] = useState<Array<IGrowthList>>([]);
  const [growthList, setGrowthList] = useState<Array<IGrowthList>>([]);
  const [likeList, setLikeList] = useState<Array<IlikeInfo>>([]);

  // let

  useEffect(() => {
    const storedPaths = JSON.parse(localStorage.getItem('PostingVisited') || '[]');
    isFirst = true;
    if (posting_number && !storedPaths.includes(posting_number)) {
      storedPaths.push(posting_number);
      localStorage.setItem('PostingVisited', JSON.stringify(storedPaths));
    } else {
      isFirst = false;
    }
    if (getUser()) {
      getLikeInfoList();
    }
    getPopularList();
    getGrowthList(isFirst);
  }, []);

  const getPopularList = () => {
    getPostingPopularList().then((res) => {
      if (!res) return;

      setPopularList(res);
    });
  };
  const getGrowthList = (isFirst: boolean) => {
    getGrowthPostingList(isFirst)
      .then((res: Array<IGrowthList>) => {
        if (!res) return;
        setGrowthList(res);
      })
      .catch((error) => {
        console.error('server error', error);
      });
  };

  const getLikeInfoList = () => {
    getLikeList()
      .then((res: Array<IlikeInfo>) => {
        if (!res) return;
        setLikeList(res);
      })
      .catch((error) => {
        console.error('server error', error);
      });
  };
  const handleAddLike = (growthJournalPostsId: number) => {
    getLikeAdd(growthJournalPostsId).then((res) => {
      getLikeInfoList();
      getPopularList();
      getGrowthList(false);
    });
  };
  const handleDelLike = (growthJournalPostsId: number) => {
    getLikeDel(growthJournalPostsId).then((res) => {
      getLikeInfoList();
      getPopularList();
      getGrowthList(false);
    });
  };

  return (
    <>
      <MetaTag
        title="에코그램 성장일지로 내 반려식물을 관찰, 기록, 공유 하세요."
        description="나의 멋진 반려식물들을 관찰하고 기록하고 공유할 수 있습니다. 성장일지에 업로드된 이미지로 식물 성장 타임랩스 영상을 자동으로 생성해 줍니다. 전용 디바이스인 ‘플랜트그램'을 이용해 자동으로 내 식물의 사진 촬영 및 온도/습도 정보를 기록할 수 있습니다. 성장일지 포스팅으로 다른 사람과 성장일지를 공유할 수 있어요."
        keywords="반려식물, 성장일지, 플랜트그램, 식물 SNS, 타임랩스, 에코그램, 식물재배기, 온도/습도, 재배 환경 정보"
      />
      <Box>
        <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
          <Box sx={{ width: getUser() ? '48px' : '58px' }}>
            <Box onClick={() => navigate(-1)} sx={{ padding: '10px 0 0 10px', cursor: 'pointer' }}>
              <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
            </Box>
          </Box>
          <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
          </Box>
          <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
            {getUser() ? (
              <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img>
            ) : (
              <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>
            )}
          </Box>
        </Stack>

        <Box>
          <Box
            sx={{
              backgroundImage: 'url(/image/header_img_01.jpg)',
              backgroundSize: 'cover',
            }}
          >
            <Box
              sx={{
                color: 'white',
                fontWeight: 'bold',
                padding: '50px 0 0 30px',
              }}
            >
              <Box
                sx={{
                  fontSize: '26px',
                  paddingBottom: '42px',
                  lineHeight: '38px',
                }}
              >
                <Box>성장일지 커뮤니티</Box>
                <Box sx={{ fontSize: '17px', fontWeight: 300 }}>나만의 멋진 반려식물들을 자랑해보세요.</Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Container sx={{ padding: '24px' }}>
          <Box>
            <Box
              sx={{
                margin: '0 0 16px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>인기 성장일지</div>
            </Box>
            <Box sx={{ overflow: 'auto', whiteSpace: 'nowrap', display: 'flex' }}>
              {popularList.length > 0 ? (
                popularList.map((listItem: IGrowthList, index: number) => (
                  <Stack direction="row" spacing={1} sx={{ width: '100%', marginRight: 1 }}>
                    <div
                      key={index}
                      style={{
                        position: 'relative',
                        width: '147px',
                      }}
                    >
                      <Box
                        onClick={() => navigate(`/growth/posting/${listItem.growthJournalPostsId}`)}
                        sx={{
                          width: '147px',
                          height: '135px',
                          backgroundSize: 'cover',
                          display: 'inline-block',
                          borderRadius: 2,
                          position: 'relative',
                          cursor: 'pointer',
                        }}
                      >
                        {listItem.postThumbnailUrl ? (
                          <img src={listItem.postThumbnailUrl} alt="" style={{ width: '147px', height: '150px' }} />
                        ) : (
                          <div style={{ width: '147px', height: '150px' }}>이미지가 없습니다.</div>
                        )}
                      </Box>
                      <Box
                        sx={{
                          fontSize: '16px',
                        }}
                      >
                        <Box
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                          }}
                        >
                          {listItem.postTitle}
                        </Box>
                        <Box
                          sx={{
                            fontSize: '12px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            wordBreak: 'break-all',
                          }}
                        >
                          {listItem.postContent}
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Box
                            sx={{
                              marginTop: '15px',
                              fontSize: '12px',
                              fontWeight: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <VisibilityIcon /> <span style={{ margin: '0 10px 0 5px' }}>{listItem.viewCount}</span>
                          </Box>
                          <Box
                            sx={{
                              marginTop: '15px',
                              fontSize: '12px',
                              fontWeight: 'normal',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {likeList.some((like: IlikeInfo) => like.postId === listItem.growthJournalPostsId) ? (
                              <FavoriteIcon
                                onClick={() => {
                                  handleDelLike(listItem.growthJournalPostsId);
                                }}
                                style={{
                                  cursor: 'pointer',
                                  color: '#e64c3c',
                                }}
                              />
                            ) : (
                              <FavoriteBorderIcon
                                onClick={() => {
                                  if (!getUser()) {
                                    alert('로그인이 필요합니다.');
                                    navigate('/login');
                                  } else {
                                    handleAddLike(listItem.growthJournalPostsId);
                                  }
                                }}
                                style={{ cursor: 'pointer' }}
                              />
                            )}
                            <span style={{ margin: '0 5px' }}>{listItem.likeCount}</span>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </Stack>
                ))
              ) : (
                <div
                  style={{
                    fontSize: '12px',
                    background: '#dadada',
                    padding: 20,
                    borderRadius: '5px',
                  }}
                >
                  인기 성장 일지가 없습니다.
                </div>
              )}
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                margin: '4em 0 16px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>성장일지</div>
            </Box>
            {growthList.length !== 0 ? (
              growthList.map((listItem: IGrowthList, index: number) => (
                <Box
                  key={index}
                  onClick={(e: any) => {
                    if (e.target.nodeName === 'DIV') {
                      navigate(`/growth/posting/${listItem.growthJournalPostsId}`);
                    }
                  }}
                  sx={{
                    margin: '20px 0',
                    padding: '10px 15px',
                    border: '1px solid #B3B3B380',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    zIndex: -1,
                  }}
                >
                  <div style={{ margin: '0 0 10px 0', fontWeight: 500 }}>{listItem.postTitle}</div>
                  <Stack direction="row">
                    <Box
                      sx={{
                        marginRight: '10px',
                        width: '110px',
                        height: '110px',
                        backgroundImage: `url(${listItem.postThumbnailUrl ? listItem.postThumbnailUrl : ``})`,
                        backgroundSize: 'cover',
                        display: 'inline-block',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                    ></Box>
                    <Box>
                      <Box
                        sx={{
                          fontSize: '13px',
                          lineHeight: '18px',
                        }}
                      >
                        {listItem.postStartDate} ~{listItem.postEndDate}
                      </Box>
                      <Box
                        sx={{
                          fontSize: '13px',
                          lineHeight: '18px',
                        }}
                      >
                        {listItem.plantType}({listItem.cultivationMethodNm}) | 사진(
                        {listItem.imageCount})
                      </Box>

                      <Box
                        sx={{
                          fontSize: '13px',
                          lineHeight: '18px',
                          marginTop: '9px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <SpeakerIcon
                          sx={
                            listItem.deviceStatus === 'CNCTD'
                              ? { color: '#4ECB71' }
                              : listItem.deviceStatus === 'DSCNCT'
                              ? { color: '#FC0808' }
                              : listItem.deviceStatus === 'UNKWN'
                              ? { color: '#C1C1C1' }
                              : { color: '#C1C1C1' }
                          }
                        />
                        <span>
                          {comCodeList.some((el: IComCode) => el.cmCd === listItem.deviceStatus)
                            ? listItem.deviceStatusNm
                            : ''}
                        </span>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Box
                          sx={{
                            marginTop: '15px',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <VisibilityIcon /> <span style={{ margin: '0 10px 0 5px' }}>{listItem.viewCount}</span>
                        </Box>
                        <Box
                          sx={{
                            marginTop: '15px',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {likeList.some((like: IlikeInfo) => like.postId === listItem.growthJournalPostsId) ? (
                            <FavoriteIcon
                              onClick={() => {
                                if (!getUser()) {
                                  alert('로그인이 필요합니다.');
                                  navigate('/login');
                                } else {
                                  handleDelLike(listItem.growthJournalPostsId);
                                }
                              }}
                              style={{ cursor: 'pointer', color: '#e64c3c' }}
                            />
                          ) : (
                            <FavoriteBorderIcon
                              onClick={() => {
                                if (!getUser()) {
                                  alert('로그인이 필요합니다.');
                                  navigate('/login');
                                } else {
                                  handleAddLike(listItem.growthJournalPostsId);
                                }
                              }}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                          <span style={{ margin: '0 5px' }}>{listItem.likeCount}</span>
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              ))
            ) : (
              <div
                style={{
                  fontSize: '12px',
                  background: '#dadada',
                  padding: 20,
                  borderRadius: '5px',
                }}
              >
                포스팅이 없습니다. <br />
                나만의 성장일지를 포스팅 해주세요.
              </div>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default GrowthPosting;
