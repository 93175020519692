import { HelmetProvider } from 'react-helmet-async';
import { MemberContextProvider } from './Context/Member';
import { UpdateProfileContextProvider } from './Context/UpdateProfile';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Router from './routes';
import KakaoChat from './Components/KakaoChat/KakaoChat';
import { ComCodeContextProvider } from './Context/ComCode';

const App = () => {
  return (
    <HelmetProvider>
      <MemberContextProvider>
        <UpdateProfileContextProvider>
          <ComCodeContextProvider>
            <KakaoChat />
            <ScrollToTop />
            <Router />
          </ComCodeContextProvider>
        </UpdateProfileContextProvider>
      </MemberContextProvider>
    </HelmetProvider>
  );
};

export default App;
