import { createContext, useState, useEffect } from 'react';
import { getComCodeList } from '../../Services/Diary';

const defaultContext: { comCodeList: IComCode[] } = {
  comCodeList: [],
};

const ComCodeContext = createContext(defaultContext);

interface Props {
  children: JSX.Element | Array<JSX.Element>;
}

const ComCodeContextProvider = ({ children }: Props) => {
  const [comCodeList, setComCodeList] = useState<Array<IComCode>>(defaultContext.comCodeList);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getComCodeList();
        setComCodeList(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return <ComCodeContext.Provider value={{ comCodeList }}>{children}</ComCodeContext.Provider>;
};

export { ComCodeContextProvider, ComCodeContext };
