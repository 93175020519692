import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import {
    Box,
    Container,
    Stack,
    Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SteperCareService from '../../Components/Steper/SteperCareService';
import { getUser } from '../../Services/Token';
import { kakaoChatStart } from '../../Components/KakaoChat/KakaoChat';
import MetaTag from '../../Components/MetaTag/MetaTag';

const imageUrlList = [
    '/image/care1_cleaning.JPG',
    '/image/care2_watering.JPG',
    '/image/care3_nutrication.JPG',
    '/image/care4_changing_sapling.jpg',
]

const CareService = () => {
    const navigate = useNavigate();
    const [selectedImageUrl, setSelectedImageUrl] = useState<number>(0);

    return <>
        <MetaTag
            title="국내 최초 식물 재배기 관리 에코매니저 케어서비스"
            description="어렵고 번거로운 관리를 해소하기 위해 에코매니저 케어서비스를 제공합니다. (월 2회 방문, 2개월마다 모종교체 서비스)"
            keywords="식물재배기, 수경재배기, 스마트팜, 수경재배, 수경재배모종, 식물영양제, 수경식물영양제, 모종구독, 식물LED등, 실내재배기, 가정용식물재배기, 채소재배기, 화분자동급수기, 유럽채소, 쌈채소, 엽채류, LED수경재배,식물재배등, 샐러드, 샐러드구독, 교육용식물재배기"
        />
        <Box>
            <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
                <Box sx={{ width: getUser() ? '48px' : '58px' }}>
                    <Box onClick={() => navigate(-1)} sx={{ padding: '10px 0 0 10px', cursor: 'pointer' }}>
                        <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
                    </Box>
                </Box>
                <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                    <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
                </Box>
                <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
                    {getUser() ?
                        <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img> :
                        <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>}
                </Box>
            </Stack>

            <Box sx={{ backgroundImage: 'url(/image/care_banner_top.png)', backgroundSize: 'cover' }}>
                <Box sx={{ color: 'white', fontWeight: 'bold', padding: '50px 0 0 30px' }}>
                    <Box sx={{ color: '#2AC1BC', fontSize: '20px', marginBottom: '11px' }}>Care</Box>
                    <Box sx={{ fontSize: '26px', paddingBottom: '42px', lineHeight: '38px' }}>
                        <Box>에코매니저</Box>
                        <Box>케어 서비스</Box>
                    </Box>
                </Box>
            </Box>


            <Container sx={{ padding: '24px' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ color: '#2AC1BC', fontWeight: 'bold', fontSize: '25px' }}>
                        <img src="/image/care_care.png" style={{ width: '252px' }} alt="logo"></img>
                    </Box>
                    <Box sx={{ fontWeight: 'bold', fontSize: '17px', marginTop: '40px', }}>당신의 식물재배기는 관리받고 있습니까?</Box>
                    <Box sx={{ color: '#B3B4B9', fontSize: '15px', lineHeight: '22px', marginTop: '16px' }}>지속적으로 양질의 채소를 수확하기 위해서는<br />전문적인 관리가 필수입니다.</Box>
                    <Box sx={{ color: '#B3B4B9', fontSize: '15px', lineHeight: '22px', marginTop: '16px' }}>학교, 카페, 식당, 마트에서 식물재배기를<br />사용중이신가요?</Box>
                    <Box sx={{ color: '#B3B4B9', fontSize: '15px', lineHeight: '22px', marginTop: '16px' }}>이제, 복잡하고 어려운 관리는 에코그램의<br />에코매니저에게 맡겨 보세요.</Box>

                </Box>
                <Box sx={{ border: '1px solid #EBECEE', padding: 3, borderRadius: 3, marginTop: '30px' }}>
                    <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginBottom: 2 }}>에코매니저 케어 서비스</Box>
                    <Box sx={{ fontSize: '13px', lineHeight: '22px' }}>
                        <Stack direction="row" alignItems="flex-start" sx={{ marginY: 2 }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px', paddingTop: '3px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px' }}>월2회 에코 매니저 방문케어 서비스 제공</Box></Stack>
                        <Stack direction="row" alignItems="flex-start" sx={{ marginY: 2 }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px', paddingTop: '3px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px' }}>2개월 마다 모종교체 서비스<br />(케어서비스는 1년 단위로 갱신됩니다.)</Box></Stack>
                        <Stack direction="row" alignItems="flex-start" sx={{ marginY: 2 }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px', paddingTop: '3px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px' }}>에코그램 식물재배기 구매시, 최초 1회 에코 매니저 케어 서비스를 무료로 제공합니다.</Box></Stack>
                        <Stack direction="row" alignItems="flex-start" sx={{ marginY: 2, marginBottom: 0 }}><CheckCircleIcon sx={{ color: '#2AC1BC', fontSize: '16px', paddingTop: '3px' }} /><Box sx={{ color: '#B3B4B9', marginLeft: '6px' }}>타사 식물재배기를 이용하는 경우, 에코매니저 상담 후 케어서비스를 받으실 수 있습니다.</Box></Stack>
                    </Box>
                </Box>
                <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
                    <Box sx={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '24px' }}>에코매니저 케어 서비스 프로세스</Box>
                    <SteperCareService />
                </Box>
                <Box sx={{ marginTop: '80px' }}>
                    <Stack direction="row" spacing="8px">
                        <Box sx={{ width: '80%' }}>
                            <Box
                                sx={{
                                    borderRadius: 2,
                                    height: '100%',
                                    backgroundImage: `url(${imageUrlList[selectedImageUrl]})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }}
                            />
                        </Box>
                        <Box sx={{ width: '20%' }}>
                            <Box onClick={() => setSelectedImageUrl(0)}
                                sx={{
                                    borderRadius: 3,
                                    height: '66px',
                                    backgroundImage: `url(${imageUrlList[0]})`,
                                    backgroundSize: 'cover',
                                    marginBottom: '8px',
                                    border: selectedImageUrl === 0 ? '2px solid #2AC1BC' : '2px solid white'
                                }} />
                            <Box onClick={() => setSelectedImageUrl(1)}
                                sx={{
                                    borderRadius: 3,
                                    height: '66px',
                                    backgroundImage: `url(${imageUrlList[1]})`,
                                    backgroundSize: 'cover',
                                    marginBottom: '8px',
                                    border: selectedImageUrl === 1 ? '2px solid #2AC1BC' : '2px solid white'
                                }} />
                            <Box onClick={() => setSelectedImageUrl(2)}
                                sx={{
                                    borderRadius: 3,
                                    height: '66px',
                                    backgroundImage: `url(${imageUrlList[2]})`,
                                    backgroundSize: 'cover',
                                    marginBottom: '8px',
                                    border: selectedImageUrl === 2 ? '2px solid #2AC1BC' : '2px solid white'
                                }} />
                            <Box onClick={() => setSelectedImageUrl(3)}
                                sx={{
                                    borderRadius: 3,
                                    height: '66px',
                                    backgroundImage: `url(${imageUrlList[3]})`,
                                    backgroundSize: 'cover',
                                    border: selectedImageUrl === 3 ? '2px solid #2AC1BC' : '2px solid white'
                                }} />
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{ textAlign: 'center', marginTop: '40px', marginBottom: '20px', fontSize: '15px' }}>
                    <img src="/image/logo_header.png" style={{ width: '100px', padding: 4 }} alt="logo"></img>
                    <Box sx={{ color: 'black', marginTop: '24px' }}>에코매니저 케어 서비스 정책 (EG-5000 기준)</Box>
                    <Box sx={{ color: '#B3B4B9', marginTop: '12px', lineHeight: '22px' }}>월2회 정기관리<br />2개월의 1회 모종교체 포함<br />25만원/월<br />1년 마다 갱신</Box>
                    <Box sx={{ color: '#2AC1BC', marginTop: '30px', fontWeight: 'bold' }}>에코그램의 식물 전문가<br />에코매니저의 케어서비스를 받아보세요.</Box>
                    <Button onClick={kakaoChatStart} variant="contained" sx={{ padding: '8px 16px 6px 16px', borderRadius: 10, marginTop: '23px', width: '212px', paddingY: 1, fontSize: '15px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }}>관리서비스 문의하기</Button>
                </Box>
            </Container>
        </Box>
    </>
}

export default CareService