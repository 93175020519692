import { useNavigate } from 'react-router-dom';
import {
    Box,
    Container,
    Button,
    Paper
} from '@mui/material';

const FindMember = () => {
    const navigate = useNavigate();
    const clickFindUserIdButton = () => {
        navigate('/find-userid');
    }
    return (
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>계정정보 찾기</Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>계정정보를 찾을 수 있습니다</Box>
            </Box>
            <br /><br />

            <Paper elevation={4} sx={{ padding: 3 }}>
                <Box sx={{ fontSize: '15px', fontWeight: 'bold', marginBottom: 1 }}>계정 ID 찾기</Box>
                <Box sx={{ fontSize: '12px', color: '#B3B4B9' }}>휴대전화 인증을 통해 계정 ID를 찾을 수 있습니다.</Box>
                <Box sx={{ textAlign: 'center', marginTop: 5 }}>
                    <Button variant="contained" sx={{ borderRadius: 10, paddingX: 8, paddingY: 1, fontSize: '14px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }} onClick={clickFindUserIdButton}>계정 ID 찾기</Button>
                </Box>
            </Paper>
            <br />
            <Paper elevation={4} sx={{ padding: 3 }}>
                <Box sx={{ fontSize: '15px', fontWeight: 'bold', marginBottom: 1 }}>계정 비밀번호 재설정하기</Box>
                <Box sx={{ fontSize: '12px', color: '#B3B4B9' }}>휴대전화 인증을 통해 계정 비밀번호를 재설정 할 수 있습니다.</Box>
                <Box sx={{ textAlign: 'center', marginTop: 5 }}>
                    <Button variant="contained" sx={{ borderRadius: 10, paddingX: 4, paddingY: 1, fontSize: '14px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' } }} onClick={() => navigate('/reset-password')}>계정 비밀번호 재설정</Button>
                </Box>
            </Paper>
        </Container>
    )
}

export default FindMember;