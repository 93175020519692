import {
    AppBar,
    Box,
    IconButton,
    Paper, Stack,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {getUser} from "../../Services/Token";
import {useNavigate} from "react-router-dom";


interface IProps {
    setPersonalDataOpen: Function
    headerHide?: boolean
}

const Privacy = ({ setPersonalDataOpen, headerHide }: IProps) => {
    const navigate = useNavigate();

    return (
        <>
            <AppBar sx={{ position: 'relative', backgroundColor: 'white', boxShadow: 'none', marginBottom: '60px', display: `${headerHide ? 'none': 'flex'}` }}>
                <Toolbar sx={{ justifyContent: 'end', position: 'absolute', right: 0 }}>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => setPersonalDataOpen(false)}
                        aria-label="close"
                        sx={{ color: 'black' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Stack direction="row" justifyContent="space-between" sx={{ padding: '10px' }}>
                <Box sx={{ width: getUser() ? '48px' : '58px' }}>
                    <Box onClick={() => navigate(-1)} sx={{ padding: '10px 0 0 10px', cursor: 'pointer' }}>
                        <img src="/image/back_black.png" style={{ width: '7px', height: '14px' }} alt="logo"></img>
                    </Box>
                </Box>
                <Box onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                    <img src="/image/logo_header.png" style={{ width: '97px', height: '30px' }} alt="logo"></img>
                </Box>
                <Box onClick={() => navigate('/profile')} sx={{ paddingX: '10px', paddingTop: '2px', cursor: 'pointer' }}>
                    {getUser() ?
                        <img src="/image/account_icon.png" style={{ width: '28px', height: '28px' }} alt="logo"></img> :
                        <Box sx={{ color: '#707070', fontSize: '14px', paddingTop: '8px' }}>로그인</Box>}
                </Box>
            </Stack>
            <Box sx={{ padding: { xs: '24px', sm: '40px' }, overflowY: 'scroll', border: '1px solid #dedede' }}>
                <Box sx={{ textAlign: 'center' }}>개인정보처리방침</Box>
                <Box sx={{ textAlign: 'right', fontSize: '13px' }}>Ver. 1.0</Box>
                <Box sx={{ textAlign: 'right', fontSize: '13px' }}>2022.10.25</Box>
                <br />
                <Box sx={{ fontSize: '13px' }}>
                    <Box>{`< (주) 클랫폼 >('https://ecogram.kr'이하 '에코그램')은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.`}</Box>
                    <Box>○ 이 개인정보처리방침은 2022년 11월 1부터 적용됩니다.</Box>
                    <Box sx={{ fontWeight: 'bold' }}>제1조(개인정보의 처리 목적)</Box>
                    <Box>{`< (주) 클랫폼 >`}('https://ecogram.kr'이하 '에코그램')<span style={{ fontWeight: 'bold' }}>은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</span></Box>
                    <Box sx={{ marginLeft: '20px' }}>1. 홈페이지 회원가입 및 관리</Box>
                    <Box sx={{ marginLeft: '20px' }}>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>2. 회원의 문의 및 상담요청 처리</Box>
                    <Box sx={{ marginLeft: '20px' }}>회원의 신원 확인, 문의/상담 사항 확인, 문의 답변 및 상담 응대를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>3. 재화 또는 서비스 제공</Box>
                    <Box sx={{ marginLeft: '20px' }}>서비스 제공, 콘텐츠 제공, 맞춤서비스 제공, 본인인증을 목적으로 개인정보를 처리합니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>4. 마케팅 및 광고에의 활용</Box>
                    <Box sx={{ marginLeft: '20px' }}>신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</Box>

                    <Box sx={{ fontWeight: 'bold' }}>제2조(개인정보의 처리 및 보유 기간)</Box>
                    <Box>1. {`< (주) 클랫폼 >`}은 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보를 얻은 시점으로부터 서비스 목적이 달성될 때까지 이용자의 개인 정보를 보유·이용하게 됩니다. 단, 회원탈퇴 요청과 개인정보 동의 철회를 요청한 경우 해당 개인정보를 지체없이 파기합니다. 단, 관계법령에 준수하여 보존할 필요가 있을 경우 일정 기간 동안 회원정보를 보관합니다.</Box>
                    <Box>2. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        1. {`<서비스 이용관련기록(접속IP정보, 접속기기정보)>`}
                        <Box>{`<서비스 이용관련기록(접속IP정보, 접속기기정보)>`}와 관련한 개인정보는 수집.이용에 관한 동의일로부터{`<6개월>`}까지 위 이용목적을 위하여 보유.이용됩니다.</Box>
                        <Box>보유근거 :서비스 이용기록 보관</Box>
                        <Box>관련법령 : 통신비밀보호법(3개월), 표시·광고에 관한 기록 전자상거래 등에서의 소비자보호에 관한 법률(6개월)</Box>
                    </Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        2. {`<소비자의 불만 또는 분쟁처리에 관한 기록>`}
                        <Box>{`<소비자의 불만 또는 분쟁처리에 관한 기록)>`}와 관련한 개인정보는 수집.이용에 관한 동의일로부터{`<3년>`}까지 위 이용목적을 위하여 보유.이용됩니다.</Box>
                        <Box>보유근거 : 회원(고객) 문의 답변 및 상담, 제품 소개</Box>
                        <Box>관련법령 : 전자상거래 등에서의 소비자보호에 관한 법률</Box>
                    </Box>

                    <Box sx={{ fontWeight: 'bold' }}>제3조(처리하는 개인정보의 항목)</Box>
                    <Box>1. {`< (주) 클랫폼 >`}은 다음의 개인정보 항목을 처리하고 있습니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>1.{`< 홈페이지 회원가입 및 관리 >`}</Box>
                        <Box>필수항목 : 이름, 휴대전화번호, 비밀번호, 로그인ID, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보</Box>
                        <Box>선택항목 : 사용중인 기기의 종류와 장소</Box>
                    </Box>

                    <Box sx={{ fontWeight: 'bold' }}>제4조(만 14세 미만 아동의 개인정보 처리에 관한 사항)</Box>
                    <Box>1. {`< (주) 클랫폼 >의 < 에코그램 >`} 서비스는 만 14세 미만의 아동에 대한 개인정보를 수집하지 않습니다.</Box>
                    <Box>2. 만 14세 미만의 아동은 회원으로 가입해서는 안되며, 이를 어기는 것은 {`< 에코그램 >`} 서비스의 이용 약관에 위배됩니다.</Box>
                    <Box>3. 이미 가입을 완료한 경우에도 회원의 나이가 만 14세 미만으로 확인되는 경우, 그 즉시 해당 회원의 계정을 정지 및 삭제할 수 있습니다.</Box>

                    <Box sx={{ fontWeight: 'bold' }}>제5조(개인정보의 제3자 제공에 관한 사항)</Box>
                    <Box>1. {`< (주) 클랫폼 >`}은 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</Box>
                    <Box>2. {`< (주) 클랫폼 >`}은 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>1. {`< 비즈엠(BizM) >`}</Box>
                        <Box>개인정보를 제공받는 자 : (주)써머스플랫폼 스윗트래커</Box>
                        <Box>제공받는 자의 개인정보 이용목적 : 휴대전화번호 인증</Box>
                        <Box>제공받는 자의 보유.이용기간: 인증번호 발송 후 24시간</Box>
                    </Box>

                    <Box sx={{ fontWeight: 'bold' }}>제6조(개인정보의 파기절차 및 파기방법)</Box>
                    <Box>1. {`< (주) 클랫폼 >`}은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</Box>
                    <Box>2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>1. 법령 근거 : 해당되는 개인정보 항목 없음</Box>
                        <Box>2. 보존하는 개인정보 항목 : 해당되는 개인정보 항목 없음</Box>
                    </Box>
                    <Box>3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>1. 파기절차</Box>
                        <Box>{`< (주) 클랫폼 >`}은 파기 사유가 발생한 개인정보를 선정하고, {`< (주) 클랫폼 >`} 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</Box>
                        <Box>2. 파기방법</Box>
                        <Box>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</Box>
                        <Box>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다</Box>
                    </Box>

                    <Box sx={{ fontWeight: 'bold' }}>제7조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</Box>
                    <Box>1. 정보주체는 (주) 클랫폼에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</Box>
                    <Box>2. 제1항에 따른 권리 행사는(주) 클랫폼에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주) 클랫폼은 이에 대해 지체 없이 조치하겠습니다.</Box>
                    <Box>3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</Box>
                    <Box>4. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</Box>
                    <Box>5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</Box>
                    <Box>6. (주) 클랫폼은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</Box>

                    <Box sx={{ fontWeight: 'bold' }}>제8조(개인정보의 안전성 확보조치에 관한 사항)</Box>
                    <Box>{`< (주) 클랫폼 >`}<span style={{ fontWeight: 'bold' }}>은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</span></Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>1. 개인정보 취급 직원의 최소화 및 교육</Box>
                        <Box>개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.</Box>
                        <Box>2. 내부관리계획의 수립 및 시행</Box>
                        <Box>개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</Box>
                        <Box>3. 해킹 등에 대비한 기술적 대책</Box>
                        <Box>{`<(주) 클랫폼>`}('에코그램')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</Box>
                        <Box>4. 개인정보의 암호화</Box>
                        <Box>이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</Box>
                        <Box>5. 접속기록의 보관 및 위변조 방지</Box>
                        <Box>개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.</Box>
                        <Box>또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.</Box>
                        <Box>6. 개인정보에 대한 접근 제한</Box>
                        <Box>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</Box>
                        <Box>7. 문서보안을 위한 잠금장치 사용</Box>
                        <Box>개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.</Box>
                        <Box>8. 비인가자에 대한 출입 통제</Box>
                        <Box>개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</Box>
                    </Box>

                    <Box sx={{ fontWeight: 'bold' }}>제9조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</Box>
                    <Box>1. (주) 클랫폼 은 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</Box>
                    <Box>2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</Box>
                        <Box>{`나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.`}</Box>
                        <Box>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</Box>
                    </Box>

                    <Box sx={{ fontWeight: 'bold' }}>제10조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)</Box>
                    <Box>1. {`<개인정보처리자>`}는 서비스 이용과정에서 정보주체에게 최적화된 맞춤형 서비스 및 혜택, 온라인 맞춤형 광고 등을 제공하기 위하여 행태정보를 수집·이용하고 있습니다.</Box>
                    <Box>2. {`<개인정보처리자>`}는 다음과 같이 행태정보를 수집합니다.</Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>수집하는 행태정보의 항목</TableCell>
                                    <TableCell>행태정보 수집 방법</TableCell>
                                    <TableCell>행태정보 수집 목적</TableCell>
                                    <TableCell>보유·이용기간 및 이후 정보처리 방법</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'1'}>
                                    <TableCell component="th" scope="row">이용자의 웹사이트 방문이력, 웹사이트 내 서비스 이용이력</TableCell>
                                    <TableCell>이용자의 웹사이트 방문 및 서비스 실행시 자동 수집</TableCell>
                                    <TableCell>웹사이트 고객경험 제고, 서비스 오류 추적, 이용자의 관심사항 분석 및 맞춤 홍보</TableCell>
                                    <TableCell>회원자격을 유지하는 기간 내 보유하며 탈퇴시 즉시 삭제</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box>{`<온라인 맞춤형 광고 등을 위해 제3자(온라인 광고사업자 등)가 이용자의 행태정보를 수집·처리할수 있도록 허용한 경우>`}</Box>
                    <Box>3. {`<개인정보처리자>`}는 다음과 같이 온라인 맞춤형 광고 사업자가 행태정보를 수집·처리하도록 허용하고 있습니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>- 행태정보를 수집 및 처리하려는 광고 사업자 : 네이버, 구글, 메타</Box>
                        <Box>- 행태정보 수집 방법 : 이용자가 당사 웹사이트를 방문하거나 앱을 실행할 때 자동 수집 및 전송</Box>
                        <Box>- 수집·처리되는 행태정보 항목 : 이용자의 웹/앱 방문이력, 검색이력, 구매이력</Box>
                        <Box>- 보유·이용기간 : 24개월(네이버), 26개월(구글), 37개월(메타)</Box>
                    </Box>
                    <Box>4. {`<개인정보처리자>`}는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력·병력, 기타 사회활동 경력 등 개인의 권리·이익이나 사생활을 뚜렷하게 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.</Box>
                    <Box>5. {`<개인정보처리자>`}는 만 14세 미만임을 알고 있는 아동이나 만14세 미만의 아동을 주 이용자로 하는 온라인 서비스로부터 맞춤형 광고 목적의 행태정보를 수집하지 않고, 만 14세 미만임을 알고 있는 아동에게는 맞춤형 광고를 제공하지 않습니다.</Box>
                    <Box>6. {`<개인정보처리자>`}는 모바일 앱에서 온라인 맞춤형 광고를 위하여 광고식별자를 수집·이용합니다. 정보주체는 모바일 단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단·허용할 수 있습니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>‣ 스마트폰의 광고식별자 차단/허용</Box>
                        <Box>(1) (안드로이드) ① 설정 → ② 개인정보보호 → ③ 광고 → ③ 광고 ID 재설정 또는 광고ID 삭제</Box>
                        <Box>(2) (아이폰) ① 설정 → ② 개인정보보호 → ③ 추적 → ④ 앱이 추적을 요청하도록 허용 끔</Box>
                        <Box>※ 모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.</Box>
                    </Box>
                    <Box>7. 정보주체는 웹브라우저의 쿠키 설정 변경 등을 통해 온라인 맞춤형 광고를 일괄적으로 차단·허용할 수 있습니다. 다만, 쿠키 설정 변경은 웹사이트 자동로그인 등 일부 서비스의 이용에 영향을 미칠 수 있습니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>‣ 웹브라우저를 통한 맞춤형 광고 차단/허용</Box>
                        <Box>(1) 인터넷 익스플로러(Windows 10용 Internet Explorer 11)</Box>
                        <Box>- Internet Explorer에서 도구 버튼을 선택한 다음 인터넷 옵션을 선택</Box>
                        <Box>- 개인 정보 탭을 선택하고 설정에서 고급을 선택한 다음 쿠키의 차단 또는 허용을 선택</Box>
                        <Box>(2) Microsoft Edge</Box>
                        <Box>- Edge에서 오른쪽 상단 ‘…’ 표시를 클릭한 후, 설정을 클릭합니다.</Box>
                        <Box>- 설정 페이지 좌측의 ‘개인정보, 검색 및 서비스’를 클릭 후 「추적방지」 섹션에서 ‘추적방지’ 여부 및 수준을 선택합니다.</Box>
                        <Box>- ‘InPrivate를 검색할 때 항상 ""엄격"" 추적 방지 사용’ 여부를 선택합니다.</Box>
                        <Box>- 아래 「개인정보」 섹션에서 ‘추적 안함 요청보내기’ 여부를 선택합니다.</Box>
                        <Box>(3) 크롬 브라우저</Box>
                        <Box>- Chrome에서 오른쪽 상단 ‘⋮’ 표시(chrome 맞춤설정 및 제어)를 클릭한 후, 설정 표시를 클릭합니다.</Box>
                        <Box>- 설정 페이지 하단에 ‘고급 설정 표시’를 클릭하고 「개인정보」 섹션에서 콘텐츠 설정을 클릭합니다.</Box>
                        <Box>- 쿠키 섹션에서 ‘타사 쿠키 및 사이트 데이터 차단’의 체크박스를 선택합니다.</Box>
                    </Box>
                    <Box>8. 정보주체는 아래의 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등을 문의할 수 있습니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>‣ 개인정보 보호 담당부서</Box>
                        <Box>부서명 : 운영팀</Box>
                        <Box>담당자 : 정대광</Box>
                        <Box>연락처 : {`<1599-7701>, <mkt01@clatform.co.kr>, <02-6455-9069>`}</Box>
                    </Box>

                    <Box sx={{ fontWeight: 'bold' }}>제11조(추가적인 이용·제공 판단기준)</Box>
                    <Box>{`< (주) 클랫폼 >`}은 ｢개인정보 보호법｣ 제15조제3항 및 제17조제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다. 이에 따라 {`< (주) 클랫폼 >`}이 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.</Box>
                    <Box sx={{ marginLeft: '20px' }}>
                        <Box>▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부</Box>
                        <Box>▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부</Box>
                        <Box>▶ 개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부</Box>
                        <Box>▶ 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Privacy