import React, { Dispatch, SetStateAction } from "react";
import { Box, Stack, alpha } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ItemText } from "../styled";
import moment from "moment";

interface Props {
  formData: IPostSaveGrowth;
  setFormData: Dispatch<SetStateAction<IPostSaveGrowth>>;
}

const DateSelectItem = ({ formData, setFormData }: Props) => {
  const date_format_start = formData.postStartDate.split(" ")[0];
  const find_end_date = formData.postEndDate ?? "";
  const date_format_end = find_end_date.split(" ")[0];

  return (
    <Box>
      <Stack direction="column" sx={{ mt: 2, mb: 2 }}>
        <ItemText>포스팅 작성할 기간 설정</ItemText>
        <Stack direction="row" alignItems="center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              className="postingDatePicker"
              format="YYYY.MM.DD"
              value={moment(date_format_start)}
              onChange={(date: any) => {
                setFormData({
                  ...formData,
                  postStartDate: `${date.$y}-${date.$M + 1}-${date.$D}`,
                });
              }}
              sx={{
                backgroundColor: "#EEEEEE",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  outline: "none",
                },
                "& .MuiInputBase-input": {
                  borderRadius: "4px",
                  position: "relative",
                  fontSize: "14px",
                  width: "114px",
                  height: "48px",
                  padding: "0px",
                  textAlign: "center",
                  "&:focus": {
                    boxShadow: `${alpha("#2AC1BC", 0.25)} 0 0 0 0.2rem`,
                    borderColor: "#2AC1BC",
                  },
                },
              }}
            />
            <span style={{ margin: "0 5px" }}>~</span>
            <DesktopDatePicker
              className="postingDatePicker"
              format="YYYY.MM.DD"
              value={moment(date_format_end)}
              onChange={(date: any) => {
                setFormData({
                  ...formData,
                  postEndDate: `${date.$y}-${date.$M + 1}-${date.$D}`,
                });
              }}
              sx={{
                backgroundColor: "#EEEEEE",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  outline: "none",
                },
                "& .MuiInputBase-input": {
                  borderRadius: "4px",
                  position: "relative",
                  fontSize: "14px",
                  width: "114px",
                  height: "48px",
                  padding: "0px",
                  textAlign: "center",
                  "&:focus": {
                    boxShadow: `${alpha("#2AC1BC", 0.25)} 0 0 0 0.2rem`,
                    borderColor: "#2AC1BC",
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Stack>
        <div>
          <ItemText>※ 식물 성장일지 기간 내에서만 선택 가능 합니다.</ItemText>
          <ItemText>
            ※ 해당 기간에 입력된 이미지, 재배환경 정보, 성장일지 기록이 자동으로
            포함됩니다.
          </ItemText>
        </div>
      </Stack>
    </Box>
  );
};

export default DateSelectItem;
