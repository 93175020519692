import { Helmet } from 'react-helmet-async';

interface IProps {
    title?: string;
    description?: string;
    keywords?: string;
}

const MetaTag = ({ title = '에코그램 식물재배기 플랫폼', description = '에코그램과 함께라면 누구나 쉽고 편한 식물생활을 할 수 있어요. 반려식물의 영양상태, 성장부진, 병진단을 알아보세요.', keywords = '' }: IProps) => {
    return (
        <Helmet>
            <title>{title}</title>

            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="title" content={title} />
            {/* 
            <meta property="og:type" content="website" />
            <meta property="og:title" content={props.title} />
            <meta property="og:site_name" content={props.title} />
            <meta property="og:description" content={props.description} />
            <meta property="og:image" content={props.imgsrc} />
            <meta property="og:url" content={props.url} />

            <meta name="twitter:title" content={props.title} />
            <meta name="twitter:description" content={props.description} />
            <meta name="twitter:image" content={props.imgsrc} /> */}

            <link rel="canonical" href="https://ecogram.kr" />
        </Helmet>
    );
};

export default MetaTag;