import { useState } from 'react';
import {
    InputLabel,
    FormControl,
    InputAdornment,
    Button,
    Stack,
    OutlinedInput
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2AC1BC',
        },
    },
});

interface IProps {
    label: string;
    placeholder: string;
    id: string;
    value: string;
    onChange: Function;
    clickCheckAuthCodeButton: Function;
    expiredTime: string;
    disabledAuthCode: boolean;
}

const AuthCodeInput = ({ label, placeholder, id, value, onChange, clickCheckAuthCodeButton, expiredTime, disabledAuthCode }: IProps) => {
    const [isDisableButton, setIsDisableButton] = useState<boolean>(true);

    const checkAuthcode = (value: string) => {
        setIsDisableButton(value.length !== 6);
    }

    return (
        <FormControl variant="standard" sx={{ width: '100%' }}>
            {label !== '' && <InputLabel shrink htmlFor="bootstrap-input" sx={{ fontSize: '13px', color: 'black', transform: 'none', "&.Mui-focused": { color: "#2AC1BC" } }}>
                {label}
            </InputLabel>}
            <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginTop: '26px' }}>
                <ThemeProvider theme={theme} >
                    <OutlinedInput
                        id={id}
                        value={value}
                        onChange={(e) => {
                            checkAuthcode(e.currentTarget.value);
                            onChange(e)
                        }}
                        endAdornment={<InputAdornment position="end" sx={{ color: '#2AC1BC' }}>{<>{!disabledAuthCode && expiredTime}</>}</InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        type="number"
                        color={"primary"}
                        placeholder={placeholder}
                        disabled={disabledAuthCode}
                        sx={{
                            height: '48px', width: '100%',
                            borderColor: '#2AC1BC',
                            fontSize: '13px'
                        }} />
                </ThemeProvider>
                <Button
                    variant="contained"
                    sx={{ padding: '8px 16px 6px 16px', width: '150px', minWidth: '110px', backgroundColor: '#2AC1BC', ":hover": { backgroundColor: '#2AC1BC' }, fontSize: '13px' }}
                    onClick={() => clickCheckAuthCodeButton()}
                    disabled={isDisableButton || disabledAuthCode}>
                    인증번호 확인</Button>
            </Stack>
        </FormControl >
    )
}

export default AuthCodeInput;