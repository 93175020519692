import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { checkPassword } from '../../../Utils/Validation';
import { changePassword } from '../../../Services/Member';
import {
    Box,
    Container,
    Button
} from '@mui/material';
import PasswordInput from '../../../Components/TextInput/PasswordInput';
import { getUser } from '../../../Services/Token';

interface State {
    password: string;
    newPassword: string;
    doubleCheckNewPassword: string;
    showPassword: boolean;
    showNewPassword: boolean;
}

const UpdatePassword = () => {
    const navigate = useNavigate();
    const user = getUser();
    const [errorNewPassword, setErrorNewPassword] = useState<string | undefined>(undefined);
    const [errorDoubleCheckNewPassword, setErrorDoubleCheckNewPassword] = useState<string | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [values, setValues] = useState<State>({
        password: '',
        newPassword: '',
        doubleCheckNewPassword: '',
        showPassword: false,
        showNewPassword: false
    });

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (prop === 'doubleCheckNewPassword' || prop === 'newPassword') {
            setErrorDoubleCheckNewPassword(undefined);
            setErrorNewPassword(undefined);
        }
        if (prop === 'doubleCheckNewPassword') {
            event.target.value === values.newPassword ? setErrorDoubleCheckNewPassword('') : setErrorDoubleCheckNewPassword('동일하지 않습니다.')
        }
        if (prop === 'newPassword') {
            event.target.value === values.doubleCheckNewPassword ? setErrorDoubleCheckNewPassword('') : setErrorDoubleCheckNewPassword('동일하지 않습니다.')
        }
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowNewPassword = () => {
        setValues({
            ...values,
            showNewPassword: !values.showNewPassword,
        });
    };

    const clickNextButton = (event: React.MouseEvent<HTMLElement>) => {
        if (!user) return alert('userId 가 없습니다.');

        const resultValidationCheckPassword = checkPassword(values.password);
        if (resultValidationCheckPassword !== 'Success') {
            setErrorMessage(resultValidationCheckPassword);
            return;
        }

        const resultValidationCheckNewPassword = checkPassword(values.newPassword);
        if (resultValidationCheckNewPassword !== 'Success') {
            setErrorNewPassword(resultValidationCheckNewPassword);
            return;
        }

        changePassword({
            userId: user.userId,
            password: values.password,
            newPassword: values.newPassword
        }).then((res: IChangePassword) => {
            navigate('/success-profile');
        }).catch((error) => {
            setErrorNewPassword('기존 비밀번호를 확인해주세요.')
            console.error("error", error);
        })
    }

    return <>
        <Container sx={{ padding: '24px' }}>
            <Box>
                <Box sx={{ fontSize: '22px', fontWeight: 'bold' }}>비밀번호 변경</Box>
                <Box sx={{ fontSize: '13px', marginTop: '12px' }}>비밀번호 변경</Box>
            </Box>
            <br /><br />
            <PasswordInput
                id="password-textInput"
                label="기존 비밀번호"
                placeholder="기존 비밀번호를 입력해 주세요"
                value={values.password}
                showPassword={values.showPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('password')(e)}
                handleClickShowPassword={() => handleClickShowPassword()}
            />
            {errorMessage !== '' && <Box style={{ color: 'rgb(211, 47, 47)' }}>{errorMessage}</Box>}
            <br /><br />

            <PasswordInput
                id="password-textInput"
                label="새 비밀번호"
                placeholder="새 비밀번호를 입력해 주세요"
                value={values.newPassword}
                showPassword={values.showNewPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('newPassword')(e)}
                handleClickShowPassword={() => handleClickShowNewPassword()}
            />
            <br /><br />
            <PasswordInput
                id="check-password-textInput"
                label="새 비밀번호 확인"
                placeholder="새 비밀번호를 입력해 주세요"
                value={values.doubleCheckNewPassword}
                showPassword={values.showNewPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('doubleCheckNewPassword')(e)}
                handleClickShowPassword={() => handleClickShowNewPassword()}
            />
            <Box sx={{ color: '#B3B4B9', marginTop: '5px', fontSize: '13px' }}>비밀번호는 8자리 이상에 대문자, 소문자, 숫자, 특수문자가 각각 1개 이상 포함되어야 합니다</Box>
            {
                values.doubleCheckNewPassword === '' ? <></> :
                    errorDoubleCheckNewPassword !== ''
                        ? <Box sx={{ color: 'rgb(211, 47, 47)', marginTop: '25px', fontSize: '13px' }}>{errorDoubleCheckNewPassword}</Box>
                        : !(errorNewPassword && errorNewPassword !== "") && <Box sx={{ color: '#2AC1BC', marginTop: '25px', fontSize: '13px' }}>비밀번호가 일치합니다.</Box>
            }
            {errorNewPassword && <Box sx={{ color: 'rgb(211, 47, 47)', marginTop: '25px', fontSize: '13px', whiteSpace: 'pre-wrap' }}>{errorNewPassword}</Box>}
        </Container>
        <Button
            variant="contained"
            fullWidth
            onClick={clickNextButton}
            sx={{ padding: '8px 16px 6px 16px', position: 'fixed', bottom: '0', height: '60px', borderRadius: 0, fontSize: '16px', backgroundColor: '#2AC1BC', maxWidth: '420px', ":hover": { backgroundColor: '#2AC1BC' } }}
        >
            비밀번호 수정하기
        </Button>
    </>
}

export default UpdatePassword;